import React, {Component} from 'react';
import Header from '../parts/header/header';
import FooterSection from '../parts/footer/footersection';
import Banner from '../parts/header/banner';
import swal from 'sweetalert2';


export class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  state = {
    name:'',
    email:'',
    message:'',
  };

  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
  }

  handleSubmit = event => {
    event.preventDefault();
    var retype = 'btn-success';
    var formData = new FormData()
    formData.append('name',  this.state.name)
    formData.append('email', this.state.email)
    formData.append('message', this.state.message)

    fetch(this.props.apiurl + '/api/contactform', {
        method: 'POST',
        credentials: 'include',
        body: formData
      })
      .then((response) => {
        if(!response.ok) {
          retype = 'error';
          return response.json();
        }
        else{
          retype = 'success';
          return response.json();
        } 
      })
      .then((responseData) => {
        swal.fire({icon: retype,
          text: responseData.message,
          timer: 5000,
          showConfirmButton: false,
          showCancelButton: false,
          showCloseButton: true,
          closeButtonCaption: 'Kapat'
        });
         document.getElementById("send").disabled=true;
       })
      .catch((error) => {
        swal.fire({
          icon: 'error',
          text:  'Bir hata oluştu. ' + error,
          timer: 7000,
          showConfirmButton: false,
          showCancelButton: false,
          showCloseButton: true,
          closeButtonCaption: 'Kapat'
        });
      });
  };

    render() {
        document.title="Mezatvar - İletişim"
        return (
          <div className="App">
          <Banner placement='0' slug={this.props.slug} apiurl={this.props.apiurl}/>
          <Header q={this.props.q} slug={this.props.slug}apiurl={this.props.apiurl}/>
          <div className="container bg-white shadow-lg mb-4 mt-4 pt-3 rounded">
                <div className="row text-center">
                <div className="col-sm-5">
                    <div className="contact-detail-box">
                      <i className="fa fa-th fa-2x text-colored"></i>
                      <h4>Şirket Bilgileri</h4>
                      <div className="text-left ml-5"><b>Ünvan:</b> Fada Bilişim ve Mühendislik A.Ş<br/>
                      <b>Vergi Dairesi:</b> Doğanbey Büyük Mükellefler Vergi Dairesi<br/>
                      <b>Vergi No:</b> 6740455007<br/>
                      <b>Mersis No:</b> 1012932921041474<br/>
                      <b>Ticaret Sicil No:</b> 398648
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-4">
                    <div className="contact-detail-box">
                      <i className="fa fa-map-marker fa-2x text-colored"></i>
                      <h4>Adresimiz</h4>

                      <address>
                      Beytepe Mahallesi 1796.Cadde. No:14/1 <br/>
                      Bilkent 06200, 06210 <br/>
                      Çankaya/Ankara

                    </address>
                    </div>
                  </div>

                  <div className="col-sm-3">
                    <div className="contact-detail-box">
                      <i className="fa fa-phone fa-2x text-colored"></i>
                      <h4>iletişim</h4>
                      0850 840 44 94<br/>
                      <a href="mailto:info@mezatvar.com.tr" className="text-muted">info@mezatvar.com.tr</a>
                    </div>
                  </div>

                </div>


                <div className="row text-left justify-content-center">

                  <div className="col-sm-6">
                      <form onSubmit={this.handleSubmit}>
                      <div className="form-group">
                        <label htmlFor="name2">Ad Soyad</label>
                        <input type="text" className="form-control" id="name2" name="name" placeholder="Adınız Soyadınız" onChange={this.handleChange} required/>
                        <div className="error d-none" id="err-name">Lütfen adınızı soyadınızı yazın</div>
                      </div>

                      <div className="form-group">
                        <label htmlFor="email2">E-posta adresiniz</label>
                        <input className="form-control" id="email2" name="email" type="text" placeholder="E-posta adresiniz" onChange={this.handleChange}  required/>
                        <div className="error d-none" id="err-emailvld">Geçersiz E-posta adresi</div> 
                      </div>

                      <div className="form-group">
                        <label htmlFor="message2">Mesajınız</label>
                        <textarea className="form-control" id="message2" name="message" rows="5" placeholder="Mesaj içeriği" onChange={this.handleChange} required></textarea>

                        <div className="error d-none" id="err-message">Lütfen bir mesaj içeriği yazın</div>
                      </div>

                      <div className="row">            
                        <div className="col-12 text-right">
                          <div id="ajaxsuccess" className="text-success">E-posta başarılı şekilde gönderildi</div>
                          <div className="error" id="err-timedout">Sunucu ile bağlantı zamanaşımına uğradı</div>
                          <div className="error" id="err-state"></div>
                          <button type="submit" className="btn btn-blue btn-rounded" id="send">Gönder</button>
                        </div> 
                      </div>
                      </form>
                  </div>
                </div>
                    
              </div>
              <Banner placement='2' slug={this.props.slug} showModal={true} apiurl={this.props.apiurl}/>
              <FooterSection apiurl={this.props.apiurl}/>
        </div>
        )
    }
}

export default ContactUs;