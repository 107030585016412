import React, { Component } from 'react';
import './vurgular.css';
export class Vurgular extends Component {
    render() {
        const BottomBtnLabel = "";//Daha fazla bilgi
        return (
            <section className="container pt-3 mb-3">
                <div className="row pt-5 mt-30">
                    <div className="col-lg-4 col-sm-6 mb-30 pb-5">
                        <button className="card vurgu h-100">
                            <div className="box-shadow bg-white rounded-circle mx-auto text-center altgolge"><i className="fa fa-shield-alt fa-3x head-icon"></i></div>
                            <div className="card-body text-center">
                                <h3 className="card-title pt-1">Güvenli Alışveriş</h3>
                                <p className="card-text text-md">Sitemizin tüm güvenlik önlemleri alınmıştır. Güvenle alışveriş yapabilirsiniz</p><span className="text-sm text-uppercase font-weight-bold">{BottomBtnLabel}&nbsp;<i className="fe-icon-arrow-right"></i></span>
                            </div>
                        </button>
                    </div>
                    <div className="col-lg-4 col-sm-6 mb-30 pb-5">
                        <button className="card vurgu h-100" >
                            <div className="box-shadow bg-white rounded-circle mx-auto text-center altgolge"><i className="fa fa-credit-card fa-3x head-icon"></i></div>
                            <div className="card-body text-center">
                                <h3 className="card-title pt-1">Kredi Kartı ile ödeme</h3>
                                <p className="card-text text-md">Tüm banka ve kredi kartları ile hızlı ödeme imkanı.</p><span className="text-sm text-uppercase font-weight-bold">{BottomBtnLabel}&nbsp;<i className="fe-icon-arrow-right"></i></span>
                            </div>
                        </button>
                    </div>
                    <div className="col-lg-4 col-sm-6 mb-30 pb-5">
                        <button className="card vurgu h-100" >
                            <div className="box-shadow bg-white rounded-circle mx-auto text-center altgolge"><i className="fa fa-shipping-fast fa-3x head-icon"></i></div>
                            <div className="card-body text-center">
                                <h3 className="card-title pt-1">Sipariş Takibi</h3>
                                <p className="card-text text-md">Paketinizin hangi noktada olduğunu anlık olarak takip edin</p><span className="text-sm text-uppercase font-weight-bold">{BottomBtnLabel}&nbsp;<i className="fe-icon-arrow-right"></i></span>
                            </div>
                        </button>
                    </div>
                    <div className="col-lg-4 col-sm-6 mb-30 pb-5">
                        <button className="card vurgu h-100" >
                            <div className="box-shadow bg-white rounded-circle mx-auto text-center altgolge"><i className="fa fa-star-half-alt fa-3x head-icon"></i></div>
                            <div className="card-body text-center">
                                <h3 className="card-title pt-1">Satıcı Puanı</h3>
                                <p className="card-text text-md">Satıcı puanlandırma sistemi ile kaliteli hizmet garanti altında</p><span className="text-sm text-uppercase font-weight-bold">{BottomBtnLabel}&nbsp;<i className="fe-icon-arrow-right"></i></span>
                            </div>
                        </button>
                    </div>
                    <div className="col-lg-4 col-sm-6 mb-30 pb-5">
                        <button className="card vurgu h-100" >
                            <div className="box-shadow bg-white rounded-circle mx-auto text-center altgolge"><i className="fa fa-address-book fa-3x head-icon"></i></div>
                            <div className="card-body text-center">
                                <h3 className="card-title pt-1">Hızlı Alışveriş</h3>
                                <p className="card-text text-md">Profilinize adreslerinizi girin. Tek tıkla siparişiniz kapınıza gelsin</p><span className="text-sm text-uppercase font-weight-bold">{BottomBtnLabel}&nbsp;<i className="fe-icon-arrow-right"></i></span>
                            </div>
                        </button>
                    </div>
                    <div className="col-lg-4 col-sm-6 mb-30 pb-5">
                        <button className="card vurgu h-100" >
                            <div className="box-shadow bg-white rounded-circle mx-auto text-center altgolge"><i className="fa fa-tags fa-3x head-icon"></i></div>
                            <div className="card-body text-center">
                                <h3 className="card-title pt-1">Rekabetçi Fiyatlar</h3>
                                <p className="card-text text-md">Yüzlerce satıcı - binlerce ürün - en uygun fiyatlar</p><span className="text-sm text-uppercase font-weight-bold">{BottomBtnLabel}&nbsp;<i className="fe-icon-arrow-right"></i></span>
                            </div>
                        </button>
                    </div>
                </div>
            </section>
        )
    }
}
export default Vurgular;