import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import Button from "react-bootstrap/Button";

export class ModalForm extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }
  render() {

    return (
      <Modal show={this.props.isOpen} onHide={this.props.closeModal} centered size={this.props.size ? this.props.size : "md"}>
        <ModalHeader closeButton>
          <ModalTitle>{this.props.title}</ModalTitle>
        </ModalHeader>
        <ModalBody className="modbody">
          {this.props.children}
        </ModalBody>

        <ModalFooter>
          {(this.props.saveButtonCaption !== "") ?
            <Button variant="primary" className="btn btn-blue d-print-none" onClick={this.props.handleSubmit}>
              {this.props.saveButtonCaption}
            </Button>
            : null}
          {this.props.closeButtonCaption !== "" ?
            <Button variant="primary" className="btn btn-danger d-print-none" onClick={this.props.closeModal}>
              {this.props.closeButtonCaption}
            </Button>
            : null}
        </ModalFooter>
      </Modal>
    )
  }
}
export default ModalForm;