import React, { Component } from 'react';
import Header from '../parts/header/header';
import FooterSection from '../parts/footer/footersection';
import Banner from '../parts/header/banner';
import SideMenu from '../components/sidemenu/sidemenu';

import sanitizeHtml from 'sanitize-html';
import Swal from 'sweetalert2';

//import '../assets/css/contracts.css';

export class Contracts extends Component {

   state = { 
        url: this.props.apiurl + '/api/contracts',
        selectedTitle: this.props.activetab,
        menuItems: [],
        contract_data: [],
        isOpen: false,
        activeTitle: '',
    }
    
    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    }

    componentDidMount() {
        this.getData();
    }
    getData() {
        const apiUrl = this.state.selectedTitle ? this.state.url + "/" + this.state.selectedTitle :  this.state.url;
        fetch(apiUrl, {
          method: 'GET',
          headers: {
            'Accept': 'application/json'            
          },
          credentials: 'include'
        })
          .then(res => res.json())
          .then((data) => {
            data.contract_data && this.setState({ menuItems: data.contract_titles, contract_data: data.contract_data, selectedTitle: data.selected_title})
            data.contract_titles && data.contract_titles.filter(cont_title => cont_title.slug === this.props.activetab).map((ind) => {
                this.setState({activeTitle: ind.title});
            });
          })
    
          .catch(err => {
            Swal({
              title: "Hata",
              text: err,
              icon: "error",
              timer: 5000,
              showConfirmButton: false,
              showCancelButton: false,
              showCloseButton: true,
              closeButtonCaption: 'Kapat'
            });    
            //this.myToast("Teknik bir problemimiz var.<br/> Menü oluşturulamadı.<br/> Problem detayı:" + err);
          })
        
      }
    render() {
        document.title = "Mezatvar - Sözleşmeler";

        return (
            <div className="App">
            <Banner placement='0' slug={this.props.slug} apiurl={this.props.apiurl}/>
            <Header q={this.props.q} slug={this.props.slug} apiurl={this.props.apiurl}/>
            <div className="container bg-white shadow-lg mb-4 mt-4 pt-3 pb-3 rounded text-left">
            <nav aria-label="breadcrumb" className="main-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="/">Anasayfa</a></li>
                  <li className="breadcrumb-item"><a href="/sozlesmeler">Sözleşmeler</a></li>
                  <li  className="breadcrumb-item active" aria-current="page">{this.state.activeTitle}</li>
                </ol>
              </nav>
                <Banner placement='1' slug={this.props.slug} apiurl={this.props.apiurl}/>
                
                <div className="row">
                    <div className="col-md-4 d-none d-md-block">
                        <div className="row-md-12">
                        <SideMenu activetab={this.state.selectedTitle} mainDirectory={"sozlesmeler"} menuItems={this.state.menuItems}/>
                        </div>                    
                    </div>
                    <div className="col-md-8 card">
                    <div className="card-body tab-content sozlesme">
                    {this.state.contract_data && this.state.contract_data.map((contract) => {
                            return <div id={contract.slug}>
                            <h4>{contract.title}</h4>
                            <hr/>
                            
                            <div className='row-md-12'>
                                
                                <div dangerouslySetInnerHTML={{__html: sanitizeHtml(contract.contract_content)}}>

                                </div>
                            </div>
                            
                        </div>
                        } ) 
                    }
                    </div>
                    
                    </div>
                </div>
               
        </div>
        <Banner placement='2' slug={this.props.slug} showModal={true} apiurl={this.props.apiurl}/>
        <FooterSection apiurl={this.props.apiurl} />
        </div>
        )
    }
}

export default Contracts;