import React, { Component } from 'react';
import Result from './result';

class SearchResults extends Component {
    render() {
        return (
            <div className="searchsugg text-left bg-light shadow-lg">
                {this.props.results && this.props.results.map(result => <Result result = {result}/>)}
            </div>
            )
    }
}

export default SearchResults;