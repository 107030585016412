import React, {Component} from 'react';
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import swal from 'sweetalert2';

export class ChatBox extends Component {
      state = {
        errorDesc: '',
        livemessage: '',
        live_chat_content:'',
        messages: [],
        lastid: 0,
        changed: false,
        url: this.props.apiurl + '/api/livechat/' + this.props.live_session_id,
        err: '',
    }
      componentDidMount() {
        this.tick();
        window.Echo.channel('livesession')
        .listen('NewMessage',(e) => {
          this.tick();
        })   
        
      }

      tick() {
        fetch(this.state.url + '/' + this.state.lastid, {
          method: 'GET',
          credentials: 'include'
        })
        .then(res => res.json())
        .then((data) => {
            this.setState({live_chat_content: data});
            const element = document.getElementById("livechatcontent");
            element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest"});
          })
        .catch(err => {
           this.setState({errorDesc: err});
        })
      }

    
      formatDate = (dateString) => {
        const options = { hour: '2-digit', minute: '2-digit', second: '2-digit' }
        return new Date(dateString).toLocaleDateString(undefined, options)
      }
      
      static propTypes = {
        cookies: instanceOf(Cookies).isRequired
      };
      
      handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
      }

      handleSubmit = event => {
        event.preventDefault();
        var retype = 'btn-green';
          const { cookies } = this.props;
          var formData = new FormData()

        formData.append('livemessage',  this.state.livemessage)
        formData.append('live_session_id',  this.props.live_session_id)
        fetch(this.props.apiurl + '/api/addLiveMessage', {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + cookies.get('access-token') ,
            },
            credentials: 'include',
            body: formData
          })
          .then((response) => {
            if(!response.ok) {
              if (response.status === 401) {
                return response.json();
              } else {
                throw new Error(response.statusText)
              }
            }
            else{
              retype = 'btn-green';
              this.setState({livemessage:''});
              return response.json();
            } 
          })
          .then((responseData) => {
            if (responseData.message==='Unauthenticated.') {
              swal.fire({
                icon: 'error',
                text:  'Mesaj gönderebilmek için üye girişi yapmanız gerekiyor',
                timer: 7000,
                showConfirmButton: false,
                showCancelButton: false,
                showCloseButton: true,
                closeButtonCaption: 'Kapat'
              });
            }    
          })
    
          .catch((error) => {
            swal.fire({
              icon: 'error',
              text:  'Bir hata oluştu. ' + error,
              timer: 7000,
              showConfirmButton: false,
              showCancelButton: false,
              showCloseButton: true,
              closeButtonCaption: 'Kapat'
            });
          });
      };

      
    render(){
        
        return(
                    <div className="chatbox chatbox-default rounded h-100 d-inline-block">
                        <div className="chatbox-heading">
                            <div className="chatbox-title">
                                <h4>Canlı Sohbet</h4>
                            </div>
                            <div className="chatbox-widgets">
                                <span className="divider"></span>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                        <div id="chat col-12">
                            <div>
                            <div className="chatbox-body chat-widget">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <p className="text-center text-muted smalltext">{this.props.live_session_start}</p>
                                    </div>
                                </div>
                                <div id="livechatcontent" className="col-12 justify-content-center">
                                {this.state.live_chat_content && this.state.live_chat_content.reverse().map((mes, index)=> (
                                  <div key={"chat" + index} className={index % 2 === 0 ? "rounded p-2 m-1 alert alert-warning text-left" : "rounded p-2 m-1 alert alert-primary text-right"}>
                                      <div className="smalltext">
                                        <strong>{mes.user_title} :</strong>
                                        {mes.message}
                                      </div>
                                      <div className="text-align-right smalltext">
                                        {this.formatDate(mes.created_at)}
                                      </div>  
                                  </div>
                                ))}
                            </div>
                            </div>
                            <div className="chatbox-footer align-bottom">
                                    <form onSubmit={this.handleSubmit}>
                                    <div className="form-group d-flex flex-inline-block">
                                        <input type="text" autoComplete="off" name="livemessage" id="livemessage" value={this.state.livemessage} onChange={this.handleChange} className="form-control" placeholder="Mesajınızı yazın..." required/>

                                        <button type="submit" className="btn btn-blue btn-sm float-right m-1"><i class="fas fa-paper-plane" aria-hidden="true"></i></button>
                                    </div>
                                    </form>
                            </div>
                        </div>
                    </div>
            </div>
            )
    }
}
    export default withCookies(ChatBox);