import React, { Component } from 'react';
import Header from '../parts/header/header';
import FooterSection from '../parts/footer/footersection';
import Banner from '../parts/header/banner';
import ProductList from '../parts/productlist';
import LiveStream from '../parts/livestream';
import Carousel from '../parts/carousel';
import Video from '../parts/video';
import Vurgular from '../parts/calltoaction/vurgular';


export class Mainpage extends Component {
  constructor(props) {
    super(props);
    this.expImg = React.createRef();
    this.state = {
      errorDesc:'',
      url: this.props.apiurl + '/api/live_session/index',
      changed: false,
      live_settings: [],
    }
    this.listenToChannels = this.listenToChannels.bind(this);
  }

  componentDidMount() {
    this.listenToChannels();
    this.getSitelive_settingsData();
  }

  listenToChannels = () => {
    window.Echo.channel('livesession')
      .listen('LiveSessionStarted', (e) => {
        this.getSitelive_settingsData();
      })
      .listen('LiveSessionStopped', (e) => {
        this.getSitelive_settingsData();
      })
  }
  getSitelive_settingsData() {
    fetch(this.state.url, {
      method: 'GET',
      credentials: 'include'
    })
      .then(res => res.json())
      .then((data) => {
        this.setState({ live_settings: data, changed: true })
      })

      .catch(err => {
        this.setState({errorDesc: err});

        //this.myToast("Teknik bir problemimiz var.<br/> Menü oluşturulamadı.<br/> Problem detayı:" + err);
      })
  }

  render() {
    var strTitle = "Mezatvar - Al ya da Sat, Cesur Olan Kazanır";
    document.title = strTitle;
    return (
      <div className="App">
        <Banner placement='0' slug={this.props.slug} showModal={true} apiurl={this.props.apiurl}/>
        <Header q={this.props.q} slug={this.props.slug} apiurl={this.props.apiurl} />
        <div id="LiveStreamContainer" className="container mb-1">
          {this.state.changed && this.state.live_settings && this.state.live_settings.map((setting, index) =>(
            setting.live_active ? <LiveStream key={"live" + index} liveURL={setting.live_broadcast_url} live_session_id={setting.id} live_session_start={setting.live_session_started_at} apiurl={this.props.apiurl}></LiveStream> :
              null
              ))}
          <div id="MainVideoContainer" className='col-md-12 p-0 m-0 pt-2'>
            <Video apiurl={this.props.apiurl} slug={this.props.slug}/>
          </div>
          <div id="MainCarouselContainer" className='col-md-12 p-0 m-0 pt-2'>
            <Carousel apiurl={this.props.apiurl} slug={this.props.slug} />
          </div>
          <div id="ProdListContainer1" className="col-md-12 mt-3 mb-3">
            <ProductList key="pl1" handleCategoryChange={this.handleCategoryChange} className="col-md-4 col-lg-4 col-xl-3 p-3" productlistheader='Öne Çıkan Mezatlar' apiurl={this.props.apiurl} criteria={'/api/showcaseauctions'} q={this.props.q} />
          </div>
          <Banner placement='1' slug={this.props.slug} showModal={true} apiurl={this.props.apiurl}/>
          <div id="ProdListContainer2" className="col-md-12 mt-3 mb-3">
            <ProductList key="pl2" handleCategoryChange={this.handleCategoryChange} className="col-md-4 col-lg-4 col-xl-3 p-3" productlistheader='Tüm Mezatlar' apiurl={this.props.apiurl} criteria={'/api/auctions'} q={this.props.q}/>
          </div>
          <Banner placement='2' slug={this.props.slug} showModal={true} apiurl={this.props.apiurl}/>
          <div>

          </div>
        </div>
        <FooterSection apiurl={this.props.apiurl} />
      </div>
    )
  }
}

export default Mainpage;