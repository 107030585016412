import React, { Component } from 'react';
import Header from '../parts/header/header';
import FooterSection from '../parts/footer/footersection';
import Banner from '../parts/header/banner';

export class NoMatch extends Component {
    render() {
        document.title = "Mezatvar - 404 aradığınız sayfa bulunamadı"
        return (
            <div className="App">
                <Banner placement='0' slug={this.props.slug} apiurl={this.props.apiurl}/>
                <Header  slug={this.props.slug} q={this.props.q} apiurl={this.props.apiurl} />
                <div className="page-404">
                    <div className="outer">
                        <div className="middle">
                            <div className="inner">

                                <div className="inner-circle"><a href="/"><i className="fa fa-home"></i><span>404</span></a></div>
                                <span className="inner-status">Bir sorunumuz var</span>
                                <span className="inner-detail">
                                    Aradığın sayfa bulunamadı
                                    <a href="/" className="btn btn-info mtl m-3"><i className="fa fa-home"></i>&nbsp;
                                        Anasayfa'ya dön
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <Banner placement='2' slug={this.props.slug} showModal={true} apiurl={this.props.apiurl}/>
                <FooterSection apiurl={this.props.apiurl} />
            </div>

        )
    }
}

export default NoMatch;