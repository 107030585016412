import React, {Component} from 'react';
import {instanceOf} from "prop-types";
import {withCookies, Cookies} from "react-cookie";
import Barcode from 'react-barcode';
import swal from 'sweetalert2';

export class CargoBarcode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorDesc: '',
            isLoading: false,
            token: '',
            username: '',
            passwd: '',
            delivery_info: '',
            cargo_result: '',
        }
    }

    componentDidMount() {
        this.getBarcodeData();

    }

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    getBarcodeData() {
        var retype = 'btn-green';

        this.setState({isLoading: true});

        const {cookies} = this.props;
        var formData = new FormData()

        formData.append('channel', this.props.channel)
        formData.append('id', this.props.id)
        fetch(this.props.apiurl + '/api/createShipment', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + cookies.get('access-token'),
            },
            credentials: 'include',
            body: formData
        })
            .then((response) => {
                if (!response.ok) {
                    retype = 'bg-danger';
                    return response.json();
                } else {
                    retype = 'btn-green';
                    this.setState({cargo_result: ''});
                    return response.json();
                }
            })
            .then((responseData) => {
                if (responseData.message === 'Unauthenticated.') {
                    swal.fire({
                        icon: 'error',
                        text: 'Etiket için üye girişi yapmanız gerekiyor',
                        timer: 7000,
                        showConfirmButton: false,
                        showCancelButton: false,
                        showCloseButton: true,
                        closeButtonCaption: 'Kapat'
                    });
                } else {
                    // console.log("cargo_result: ");
                    // console.log(responseData.cargo_result);
                    // console.log("delivery_info: ");
                    // console.log(responseData.delivery_info);

                    console.log("responseData: ");
                    console.log(responseData);

                    this.setState({cargo_result: responseData.cargo_result, delivery_info: responseData.delivery_info})
                }
            })

            .catch((error) => {
                swal.fire({
                    icon: 'error',
                    text: 'Bir hata oluştu. ' + error,
                    timer: 7000,
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                    closeButtonCaption: 'Kapat'
                });
            });
    }


    render() {
        document.title = "Mezatvar - Kargo Barkodu"
        return (
            <div className="App">
                <div className='table table-border col-12'>
                    <div className="row border">
                        <div className="col-4"><strong>Gönderici:</strong></div>
                        <div className="col-4">{this.state.cargo_result.seller_contact_name}</div>
                    </div>
                    <div className="row border"><strong>Alıcı Bilgileri</strong></div>
                    <div className="row border">
                        <div className="col-12">
                            <div className="row">
                                <div className="col-4"><strong>İsim:</strong></div>
                                <div className="col-8"> {this.state.delivery_info.delivery_contact_name}</div>
                            </div>
                            <div className="row">
                                <div className="col-4"><strong>Telefon:</strong></div>
                                <div className="col-8"> {this.state.delivery_info.delivery_contact_phone}</div>
                            </div>
                            <div className="row">
                                <div className="col-4"><strong>Adres:</strong></div>
                                <div
                                    className="col-8"> {this.state.delivery_info.delivery_address} {this.state.delivery_info.delivery_town} / {this.state.delivery_info.delivery_city}</div>
                            </div>
                        </div>
                    </div>
                    <div className="row border"><strong>STANDART KARGO</strong></div>
                    {/*{this.state.cargo_result.order_item_track_no && <div className="row border"><strong>Entegrasyon*/}
                    {/*    No: {this.state.cargo_result.order_item_track_no} </strong></div>}*/}
                    {/*{this.state.cargo_result.order_item_track_no &&*/}
                    {/*    <div className="row border"><Barcode value={this.state.cargo_result.order_item_track_no}*/}
                    {/*                                         displayValue={false}/></div>}*/}
                    {this.state.cargo_result.order_item_barcode_no &&
                        <div className="row border text-lg"><strong>Barkod
                            No: {this.state.cargo_result.order_item_barcode_no} </strong></div>}
                    {this.state.cargo_result.order_item_barcode_no &&
                        <div className="row border"><Barcode value={this.state.cargo_result.order_item_barcode_no}
                                                             displayValue={false}/></div>}
                </div>

            </div>
        )
    }
}

export default withCookies(CargoBarcode);