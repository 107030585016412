import React, { Component } from 'react';
import Header from '../parts/header/header';
import FooterSection from '../parts/footer/footersection';
import Banner from '../parts/header/banner';
import swal from 'sweetalert2';

export class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  state = {
    password: '',
    password_confirmation: ''
  };

  /*componentDidMount() {
   
  }*/

  togglePasswordVisibility = () => {
    this.state.passwordShown ? this.setState({ passwordShown: false }) : this.setState({ passwordShown: true })
  }

  togglePassword2Visibility = () => {
    this.state.password2Shown ? this.setState({ password2Shown: false }) : this.setState({ password2Shown: true })
  }


  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
  }

  handleSubmit = event => {
    event.preventDefault();
    var formData = new FormData()
    var retype = 'bg-success';
    formData.append('email', this.props.email)
    formData.append('token', this.props.token)
    formData.append('password', this.state.password)
    formData.append('password_confirmation', this.state.password_confirmation)

    fetch(this.props.apiurl + '/api/reset-password', {
      method: 'POST',
      credentials: 'include',
      body: formData
    })
      .then((response) => {
        if (!response.ok) {
          retype = 'error';
          return response.json();
        }
        else {
          retype = 'success';
          return response.json();
        }
      })
      .then((responseData) => {
        swal.fire({
          icon: retype,
          text:  responseData.message,
          timer: 5000,
          showConfirmButton: false,
          showCancelButton: false,
          showCloseButton: true,
          closeButtonCaption: 'Kapat'
        });
        if (retype === 'success') {
          window.location = '/girisyap';
        }
      })

      .catch((error) => {
        swal.fire({
          icon: 'error',
          text:  'Bir hata oluştu. ' + error,
          timer: 7000,
          showConfirmButton: false,
          showCancelButton: false,
          showCloseButton: true,
          closeButtonCaption: 'Kapat'
        });
      });
  };


  render() {
    document.title = "Mezatvar - Şifre Değiştir"
    return (
      <div className="App">
        <Banner placement='0' slug={this.props.slug} apiurl={this.props.apiurl} />
        <Header slug={this.props.slug} q={this.props.q} apiurl={this.props.apiurl} />
        <div className="container  p-3">
          <div className="row justify-content-center text-left">
            <div className="col-sm-4 card p-3">
              <h3>Yeni Şifrenizi Belirleyin</h3>

              <form className="card" onSubmit={this.handleSubmit}>
                <div className="card-body">

                  <div className="form-group">
                    <label className="sr-only">Yeni Şifreniz</label>
                    <div className="input-group">
                      <input type={this.state.passwordShown ? "text" : "password"} id="reg_userpassword" name="password" className="form-control" placeholder="Şifre" onChange={this.handleChange} required />
                      <div className="input-group-append">
                        <button className="btn btn-outline-secondary" type="button" id="button-append1" onClick={this.togglePasswordVisibility}>
                          <i className="fa fa-eye" aria-hidden="true"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="sr-only">Yeni Şifreniz (Tekrar)</label>
                    <div className="input-group">
                      <input type={this.state.password2Shown ? "text" : "password"} id="reg_userpassword2" name="password_confirmation" className="form-control" placeholder="Şifre (Tekrar)" onChange={this.handleChange} required />
                      <div className="input-group-append">
                        <button className="btn btn-outline-secondary" type="button" id="button-append1" onClick={this.togglePassword2Visibility}>
                          <i className="fa fa-eye" aria-hidden="true"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <button className="btn btn-blue float-right" type="submit">Yeni Şifremi Kaydet</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Banner placement='2' slug={this.props.slug} showModal={true} apiurl={this.props.apiurl}/>
        <FooterSection apiurl={this.props.apiurl}/>
      </div>
    )
  }
}

export default ResetPassword;