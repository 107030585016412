import React, { Component } from 'react';
import MetaManager from '../components/metamanager/metamanager';
import { Tabs, Tab } from 'react-bootstrap';
import { urltemizle } from '../components/utils';
import '../assets/css/productdetail.css';
import MezatSayaci from '../components/counter/mezatsayaci';
//import Carousel from 'react-bootstrap/Carousel';
import ModalForm from '../components/modal/modalform';
//import Product from '../parts/product';
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import Slider from "../components/slider/Slider.js";
import Stars from '../components/stars';
import sanitizeHtml from 'sanitize-html';
import swal from 'sweetalert2';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Popover from 'react-bootstrap/Popover';
import Product01 from '../parts/product01';
import Stamp from '../components/stamp';

export class ProductDetailsChild extends Component {
    constructor(props) {
        super(props);
            this.state.pId = this.props.veri.id;
            this.state.pName = this.props.veri.name;
            this.state.pTitle = this.props.veri.title;
            this.state.pSlug = this.props.veri.slug;
            this.state.pDescription = this.props.veri.description;
            this.state.pCat1Title = this.props.veri.category_1_title;
            this.state.pCat1Slug = this.props.veri.category_1_slug;
            this.state.pCat2Title = this.props.veri.category_2_title;
            this.state.pCat2Slug = this.props.veri.category_2_slug;
            this.state.pCat3Title = this.props.veri.category_3_title;
            this.state.pCat3Slug = this.props.veri.category_3_slug;
            this.state.pEndTime = this.props.veri.end_time;
            this.state.pStartTime = this.props.veri.start_time;
            this.state.pStartedAt = this.props.veri.started_at;
            this.state.pClosedAt = this.props.veri.closed_at;
            this.props.caption!==null ? this.state.auctionStatus = this.props.caption : this.state.auctionStatus = '';
            this.props.veri.opening_price !== null ? this.state.pOpeningPrice = this.props.veri.opening_price : this.state.pOpeningPrice = 0;
            this.props.veri.current_price !== null ? this.state.pCurrentPrice = this.props.veri.current_price : this.state.pCurrentPrice = this.state.pOpeningPrice;
            this.state.pBuyNowPrice = this.props.veri.buynow_price;
            this.state.pType = this.props.veri.type;
            this.state.pImages = this.props.veri.auction_image;
            this.state.pBids = this.props.veri.auction_bid;
            this.state.pSeller = this.props.veri.seller;
            this.state.pSellerRatings = this.props.veri.seller.userrating;
            this.state.pBidCount = this.props.veri.auction_bid.length;
            this.state.pAuctionQuestions = this.props.veri.auction_question;
            this.state.pShippingType = this.props.veri.shipping_type;
            this.state.pShippingPaymentBy = this.props.veri.shipping_payment_by;
            this.state.pCargoPrices = this.props.cargoPrices;
            this.state.pB =this.props.b;
        this.handleSubmitBid = this.handleSubmitBid.bind(this);
        this.handleBuyNow = this.handleBuyNow.bind(this);
        this.handleWish = this.handleWish.bind(this);
        this.handleQuestion = this.handleQuestion.bind(this);
        //this.handleRating = this.handleRating.bind(this);
        this.getBids = this.getBids.bind(this);
        this.listenToChannels = this.listenToChannels.bind(this);
    }
    state = {
        userWatchlist: this.props.userWatchlist,
        existsincart: this.props.existsincart,
        isLoading: false,
        isOpen: false,
        activeModal: null,
        activePay: null,
        customPay: null,
        auctionStatus: '',
        statusClassName: 'row ' + this.props.bgcolor + ' p-2 text-white shadow-lg',
        pNotification: '',
        pId: '',
        pName: '',
        pTitle: '',
        pSlug: '',
        pDescription: '',
        pCat1Title: '',
        pCat1Slug: '',
        pCat2Title: '',
        pCat2Slug: '',
        pCat3Title: '',
        pCat3Slug: '',
        pStartTime: '',
        pEndTime: '',
        pOpeningPrice: '',
        pCurrentPrice: '',
        pBuyNowPrice: '',
        pType: 0,
        pSeller: '',
        pImages: [],
        pBids: [],
        pBidCount: 0,
        pStartedAt: '',
        pClosedAt:'',
        pAuctionQuestions: [],
        pSellerRatings: [],
        ogimage: '',
        question:'',
        notificationMessage: '',
        pShippingType: 1,
        pShippingPaymentBy: 1,
        pCargoPrices: [],
        pB: false,
        newReply: "",
        activeTab: this.props.veri.description!=null ? 1 : 2,
    }

    renderTooltip = (rating) => (
        <Tooltip id="button-tooltip" {...rating}>
          Satıcı Puanı: <Stars rating={rating} allowChange={false}/>
        </Tooltip>
      );
    
    changeTab = (t) => this.setState({activeTab: t});
    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false, activeModal: null });
    handleSubmit = (name) => {
        this.setState({ isOpen: false });
    }
    formatDate = (dateString) => {
        const options = { hour: '2-digit', minute: '2-digit', second: '2-digit' }
        return new Date(dateString).toLocaleDateString(undefined, options)
      }

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    handleSubmitReply(qid) {
        this.setState({isOpen: false});
        const { cookies } = this.props;
        var retype = 'success';
        var formData = new FormData();
        formData.append('question_id', qid);
        formData.append('reply', this.state.newReply);
  
        fetch(this.props.apiurl + '/api/addReply', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + cookies.get('access-token'),
            },
            credentials: 'include',
            body: formData
        })
            .then((response) => {
                if (!response.ok) {
                    retype = 'error';
                    return response.json();
                }
                else {
                    retype = 'success';
                    return response.json();
                }
            })
            .then((responseData) => {
                if (responseData.message === 'Unauthenticated.') {
                    swal.fire({
                        icon: 'error',
                        text:  'Destek talebine yanıt vermek için üye girişi yapmalısınız',
                        timer: 7000,
                        showConfirmButton: false,
                        showCancelButton: false,
                        showCloseButton: true,
                        closeButtonCaption: 'Kapat'
                      });
                      this.setState({ isLoading: false, isAuthenticated:false })
                } else {
                    swal.fire({
                        icon: retype,
                        text:  responseData.message,
                        timer: 5000,
                        showConfirmButton: true,
                        confirmButtonText: '<i class="fa fa-thumbs-up"></i> Tamam!',
                        showCancelButton: false,
                        showCloseButton: true,
                        closeButtonCaption: 'Kapat'
                      });
                      this.setState({ 
                          isLoading: false, 
                          isAuthenticated:true,
                          pAuctionQuestions: responseData.questions,
                          })
                }
            })
  
            .catch((error) => {
                swal.fire({
                    icon: 'error',
                    text:  'Bir hata oluştu. ' + error,
                    timer: 7000,
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                    closeButtonCaption: 'Kapat'
                  });
            });
    }
    handleWish() {
        this.setState({ isOpen: false });
        let agent = navigator.userAgent;

        if (agent.toLocaleLowerCase().includes('mezatvar')==false) {
            let promise = !this.state.userWatchlist? Notification.requestPermission(): null;
        }
        
        var exactpage = this.state.userWatchlist ? "removefromWatchList" : "addtoWatchList";
        const { cookies } = this.props;
        var retype = 'bg-success';
        var retext = 'success';
        var formData = new FormData();
        formData.append('slug', this.state.pSlug);

        fetch(this.props.apiurl + '/api/' + exactpage, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + cookies.get('access-token'),
            },
            credentials: 'include',
            body: formData
        })
            .then((response) => {
                if (!response.ok) {
                    retype = 'bg-danger';
                    retext = 'error';
                    return response.json();
                }
                else {
                    retype = 'bg-success';
                    retext = 'success';
                    return response.json();
                }
            })
            .then((responseData) => {
                if (responseData.message === 'Unauthenticated.') {
                    swal.fire({
                        icon: retext,
                        title: retext==='error' ? 'Başarısız işlem...' : 'İşlem sonucu',
                        text: 'Ürünü takip listenize eklemek için üye girişi yapmalısınız',
                        timer: 5000,
                        showConfirmButton: false,
                        showCancelButton: false,
                        showCloseButton: true,
                        closeButtonCaption: 'Kapat'
                      });
                } else {
                    if (retype=='bg-success') {
                        this.setState({userWatchlist : !this.state.userWatchlist});
                    }

                    swal.fire({icon: retext,
                        text: responseData.message,
                        timer: 5000,
                        showConfirmButton: false,
                        showCancelButton: false,
                        showCloseButton: true,
                        closeButtonCaption: 'Kapat'
                        });
                   
                }
            })

            .catch((error) => {
                swal.fire({
                    icon: 'error',
                    text:  'Bir hata oluştu. ' + error,
                    timer: 7000,
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                    closeButtonCaption: 'Kapat'
                  });
            });
    }
    handleChangeTextArea = (event) => {
        if (event.target.value==="") {  
          this.setState({newReply: ""});
        } else {
          this.setState({newReply: event.target.value});
        }
      }
    handleQuestion() {
        if (this.state.question) {
        this.setState({ isOpen: false });
        const { cookies } = this.props;
        var retype = 'bg-success';
        var retext = 'success';
        var formData = new FormData();
        formData.append('slug', this.state.pSlug);
        formData.append('question', this.state.question)

        fetch(this.props.apiurl + '/api/addQuestion', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + cookies.get('access-token'),
            },
            credentials: 'include',
            body: formData
        })
            .then((response) => {
                if (!response.ok) {
                    retype = 'bg-danger';
                    retext = 'error';
                    return response.json();
                }
                else {
                    retype = 'bg-success';
                    retext = 'success';
                    return response.json();
                }
            })
            .then((responseData) => {
                if (responseData.message === 'Unauthenticated.') {
                     swal.fire({
                        icon: 'error',
                        text:  'Ürüne dair soru sorabilmek için üye girişi yapmalısınız',
                        timer: 7000,
                        showConfirmButton: false,
                        showCancelButton: false,
                        showCloseButton: true,
                        closeButtonCaption: 'Kapat'
                      });
                } else {
                    swal.fire({
                        icon: retext,
                        text:  responseData.message,
                        timer: 7000,
                        showConfirmButton: false,
                        showCancelButton: false,
                        showCloseButton: true,
                        closeButtonCaption: 'Kapat'
                      });
                }
            })

            .catch((error) => {
                swal.fire({
                    icon: 'error',
                    text:  'Bir hata oluştu. ' + error,
                    timer: 7000,
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                    closeButtonCaption: 'Kapat'
                  });
            });
        } else {
            swal.fire({
                icon: 'error',
                text:  'Soru içeriği boş bırakılamaz',
                timer: 7000,
                showConfirmButton: false,
                showCancelButton: false,
                showCloseButton: true,
                closeButtonCaption: 'Kapat'
              });
        }
    }

    handleBuyNow() {
        this.setState({ isOpen: false });
        const { cookies } = this.props;
        var retype = 'bg-success';
        var retext = 'success';
        var formData = new FormData();
        formData.append('slug', this.state.pSlug);

        fetch(this.props.apiurl + '/api/addtoCart', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + cookies.get('access-token'),
            },
            credentials: 'include',
            body: formData
        })
            .then((response) => {
                if (!response.ok) {
                    retype = 'bg-danger';
                    retext = 'error';
                    return response.json();
                }
                else {
                    retype = 'bg-success';
                    retext = 'success';
                    return response.json();
                }
            })
            .then((responseData) => {
                if (responseData.message === 'Unauthenticated.') {
                    swal.fire({
                        icon: 'error',
                        title: 'Başarısız işlem...',
                        text: 'Ürünü sepetinize eklemek için üye girişi yapmalısınız',
                        timer: 5000,
                        showConfirmButton: false,
                        showCancelButton: false,
                        showCloseButton: true,
                        closeButtonCaption: 'Kapat'
                      });
                } else {
                    swal.fire({
                        icon: retext,
                        title: retext==='error' ? 'Başarısız işlem...' : 'İşlem sonucu',
                        text: responseData.message,
                        timer: 5000,
                        showConfirmButton: false,
                        showCancelButton: false,
                        showCloseButton: true,
                        closeButtonCaption: 'Kapat'
                      });
                    if (responseData.message==='Ürün sepetinize eklendi') {
                        this.setState({existsincart: true});
                    }
                }
            })

            .catch((error) => {
                swal.fire({
                    title: "Hata",
                    text: error,
                    icon: "error",
                    timer: 5000,
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                    closeButtonCaption: 'Kapat'
                  });
            });
    }

    handleSubmitBid() {
        this.setState({ isOpen: false });
        let agent = navigator.userAgent;
        if (agent.toLocaleLowerCase().includes('mezatvar')==false) {
            let promise = !this.state.userWatchlist? Notification.requestPermission(): null;
        }
        const { cookies } = this.props;
        var retype = 'success';
        var formData = new FormData();
        formData.append('auction_id', this.state.pId);
        formData.append('bid_amount', this.state.activePay);
        formData.append('total_amount', parseInt(this.state.pCurrentPrice) + parseInt(this.state.activePay));

        fetch(this.props.apiurl + '/api/addBid', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + cookies.get('access-token'),
            },
            credentials: 'include',
            body: formData
        })
            .then((response) => {
                if (!response.ok) {
                    retype = 'error';
                    return response.json();
                }
                else {
                    retype = 'success';
                    return response.json();
                }
            })
            .then((responseData) => {
                if (responseData.message === 'Unauthenticated.') {
                    swal.fire({
                        icon: 'error',
                        title: 'Başarısız işlem...',
                        text: 'Pey verebilmek için üye girişi yapmalısınız',
                        timer: 5000,
                        showConfirmButton: false,
                        showCancelButton: false,
                        showCloseButton: true,
                        closeButtonCaption: 'Kapat'
                      });
                } else {
                    if (responseData.message==='Teklifiniz alındı') {
                        this.setState({statusClassName: "row bg-success p-2 text-white shadow-lg", auctionStatus: "Devam Eden Mezat - En yüksek teklif sizin"});
                    }
                    swal.fire({
                        icon: retype,
                        text: responseData.message,
                        timer: 5000,
                        showConfirmButton: false,
                        showCancelButton: false,
                        showCloseButton: true,
                        closeButtonCaption: 'Kapat'
                      });
                }
            })

            .catch((error) => {
                swal.fire({
                    icon: 'error',
                    text:  'Bir hata oluştu. ' + error,
                    timer: 7000,
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                    closeButtonCaption: 'Kapat'
                  });
            });
    }

    clickHandler(e, index) {
        e.preventDefault();
        if (e.target.name=='pey') {
            this.setState({ activePay: index })
        } else if (e.target.name=="ozelpey") {
            this.setState({ activePay: this.state.customPay })
        }
        this.setState({ isOpen: true, activeModal: index })
    }

    myChangeHandler = (event) => {
        event.target.value = event.target.value.replace(/\D/g, '');
        this.setState({ activeModal: 1, customPay: event.target.value.replace(/\D/g, '') });
    }

    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    }

    numberFormat = (value) => {
        if (value > 0) {
            return Intl.NumberFormat(['ban', 'id']).format(value)
        } else {
            return 0;
        }
    }

    listenToChannels = () => {
        window.Echo.channel('auction.' + this.state.pId)
            .listen('AuctionStarted', (e) => {
                this.setState({statusClassName: "row bg-dark p-2 text-white shadow-lg", notificationMessage: 'Mezat Başladı'});
                this.showNoti('Mezatvar - Mezat Başladı', this.state.pTitle + ' isimli mezat başladı', 'bg-primary');
            })
            .listen('AuctionClosed', (e) => {
                this.setState({statusClassName: "row bg-dark p-2 text-white shadow-lg", auctionStatus: ' Tamamlanmış Mezat', notificationMessage: 'Mezat Kapandı'});
                this.showNoti('Mezatvar - Mezat Kapandı', this.state.pTitle + ' isimli mezat kapandı', 'bg-danger');
            })
            .listen('NewBid', (e) => {
                this.setState({statusClassName: "row bg-warning p-2 text-white shadow-lg", auctionStatus: ' Devam Eden Mezat - Yeni bir pey verildi'});
                this.setState({ pCurrentPrice: e.bid.total_amount, pBidCount: e.bidCount, notificationMessage: 'Yeni bir pey verildi'});
                this.showNoti('Mezatvar - Bilgilendirme', 'Mezata yeni bir pey verildi: ' + this.state.pTitle + ' isimli mezata yeni bir pey verildi', 'bg-warning');
                this.getBids();
            })
    }
    showNoti(ntitle, nbody, toastStyle) {
            if (document.visibilityState === "hidden" && 'serviceWorker' in navigator) {
                if (Notification.permission === "granted") {
                    // If it's okay let's create a notification
                    var img2show = this.state.pImages.length>0 ? this.props.apiurl + this.state.pImages[0].public_url : "https:\\www.mezatvar.com.tr\assets\images\logo.png";
                    var notdata= '/mezat/' + this.state.pSlug;
                    navigator.serviceWorker.ready.then(function(registration) {
                            var n = registration.showNotification(ntitle, {
                                body: nbody,
                                image:  img2show,
                                icon: './assets/images/favicon.png',
                                badge: './assets/images/favicon.png',
                                data: notdata,
                                actions: [{action: "auction", title: "Mezata Git"}],
                                requireInteraction: true    
                            })
                            n.notificationclick = function(event) {
                                event.preventDefault(); // prevent the browser from focusing the Notification's tab
                                event.waitUntil(window.open(notdata));
                                event.notification.close();
                            }
                    });
                    
                    
                } else {
                    swal.fire({icon: toastStyle,
                        text: nbody,
                        timer: 5000,
                        showConfirmButton: false,
                        showCancelButton: false,
                        showCloseButton: true,
                        closeButtonCaption: 'Kapat'
                        });
                }
            } else {
                swal.fire({icon: toastStyle,
                    text: nbody,
                    timer: 5000,
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                    closeButtonCaption: 'Kapat'
                    });
            }
        }

    componentDidMount() {
        //alert(window.navigator.userAgent)

        this.listenToChannels();
        //document.getElementById('product-description').innerHTML = sanitizeHtml(this.state.pDescription);
        this.state.pImages && this.state.pImages.slice(0, 1).map((ogimg, index) => {
            this.setState({ ogimage: this.props.apiurl + ogimg.public_url });
        })

    }
    setUpdate(text, key) {
        const nextItem = this.state.items.map((item) => {
            if (item.key === key) {
                item.text = text;
            }
            return item;
        });
        this.setState({ items: nextItem });
    }
    getBids() {
    this.setState({ isLoading: true });
        const { cookies } = this.props;
        fetch(this.props.apiurl + '/api/bids/' + this.state.pId, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
        },
        credentials: 'include',
        })
        .then((response) => {
            if (!response.ok) {
            return response.json();
            } else {
            return response.json();
            }
        })
        .then((data) => {
            this.setState({ pBids: data, isLoading: false});
        })
        .catch(err => {
            this.setState({ err:true, errorDesc: err});
            //this.myToast("Teknik bir problemimiz var.<br/> Mezat detayı alınamadı oluşturulamadı.<br/> Problem detayı:" + err);
        })
    }
    render() {
        return (

            <div className="container">

                <MetaManager title={"Mezatvar - " + this.state.pName} description={this.state.pDescription}
                    sitename={"mezatvar.com.tr"} og_image={this.state.ogimage}
                    og_url={window.location} og_imageAlt={this.state.pName} />
                <div className={this.state.statusClassName}>
                    
                    <div className="col-md-12" id="product-detail-status">
                        <div className="row">
                            <div className="col-md-8 my-auto hugetext text-left h-100" id="auctionstatus">
                                <div className="row h-100">
                                <div className="col-md-12 justify-content-center align-self-center"> <i className="fas fa-gavel"></i> {this.state.auctionStatus}</div>

                                </div>
                                
                            </div>
                            <div className="col-md-4 my-auto hugetext h-100">
                                <div id="row">
                                    <div className="col-md-5 pt-3 my-auto mx-auto float-left" id="kalansure">{this.state.pNotification}</div>
                                    <div className="col-md-7 my-auto mx-auto float-right">{this.state.pClosedAt === null ? <MezatSayaci endtime={this.state.pEndTime} starttime={this.state.pStartTime} servertime={this.props.servertime} />:''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
                <div className="text-left pt-2">
                    <div className="small">
                        <ul className="breadcrumb">
                            <li><a href="/">Anasayfa</a></li>
                            <li><a href={"kategori/" + this.state.pCat1Slug}>{this.state.pCat1Title}</a></li>
                            <li><a href={"kategori/" + this.state.pCat1Slug + "/" + this.state.pCat2Slug}>{this.state.pCat2Title}</a></li>
                            <li><a href={"kategori/" + this.state.pCat1Slug + "/" + this.state.pCat2Slug + "/" + this.state.pCat3Slug}>{this.state.pCat3Title}</a></li>
                            <li>{this.state.pName}  </li>
                        </ul>
                    </div>

                </div>
                <div className="productdet-content product-wrap product-deatil shadow-lg">

                    <div className="row">
                    {this.state.existsincart? <div className="col-md-12 col-sm-12 col-xs-12 m-0 text-left normaltext"><div className="alert alert-warning" role="alert">Ürün sepetinizde<a href="/sepetim" className="btn btn-sm btn-green float-right">Sepetinize gitmek için tıklayın</a></div></div>: null}
                        <div className="col-md-6 col-sm-12 col-xs-12 m-0">
                            {this.state.pImages.length > 0 && <Slider imageList={this.state.pImages} containerClassName="container-slider rounded-lg" withThumbs={true} withDots={true} apiurl={this.props.apiurl} zoom={true}  withLinks={false}/>}
                            <button className="btn btn-green m-3" onClick={e => this.clickHandler(e, -5)}><i className="fas fa-search-plus"></i> Büyük Fotoğraf</button>
                        </div>

                        <div className="col-md-6 col-md-offset-1 col-sm-12 col-xs-12">
                        
                            <h5 className="name text-left">{this.state.pTitle}</h5>
                            <hr/>
                            <Stamp status="is-approved" statusText="Onaylandı"/>

                            <h6 className="name text-left">
                                {this.state.pName}
                                <hr />
                                <p className="small">Mezat No: <span className="float-right">{this.state.pId}</span></p>
                                <p className="small">Başlangıç: <span className="float-right">{this.state.pStartedAt ? this.formatDate(this.state.pStartedAt) + ' GMT+3': this.formatDate(this.state.pStartTime) + ' GMT+3'}</span></p>
                                <p className="small">Bitiş: <span className="float-right">{this.state.pClosedAt ? this.formatDate(this.state.pClosedAt) + ' GMT+3': this.formatDate(this.state.pEndTime) + ' GMT+3'}</span></p>
                                <hr />
                                <div className="text-left">
                                    <p>Açılış Fiyatı: {(this.state.pOpeningPrice !== '0' && this.state.pOpeningPrice !== null) ? this.numberFormat(this.state.pOpeningPrice) + ' ₺' : 'Yok'} </p>
                                    <p>Mevcut Fiyat: 
                                    
                                    <span className='hugetext'>                                        
                                        {(this.state.pCurrentPrice !== '0' && this.state.pCurrentPrice !== null) ? <span id='currentprice'> {this.numberFormat(this.state.pCurrentPrice)} ₺ 
                                        (<a href="#" name="teklifler" onClick={e => this.clickHandler(e, -2)}>
                                            {this.state.pBidCount} adet teklif</a>) </span>: 'Teklif Yok'}
                                    </span>
                                    </p>
                                </div>

                                <div className="row text-left col-md-12 h-100 pb-2">
                                        Pey ver:
                                </div>
                                <div className="row">
                                    <div className="pl-3 col-md-12 text-left h-100">
                                        <div className="row">
                                            <button name="pey" onClick={e => this.clickHandler(e, parseInt(this.state.pBuyNowPrice / 100) > 1 ? parseInt(this.state.pBuyNowPrice / 100) : 1)} className="btn btn-outline-success font-weight-bold m-1 col-md-2">{parseInt(this.state.pBuyNowPrice / 100) > 1 ? parseInt(this.state.pBuyNowPrice / 100) : 1} ₺</button>
                                            <button name="pey" onClick={e => this.clickHandler(e, parseInt(this.state.pBuyNowPrice / 50) > 1 ? parseInt(this.state.pBuyNowPrice / 50) : 5)} className="btn btn-outline-success font-weight-bold m-1 col-md-3">{parseInt(this.state.pBuyNowPrice) / 50 > 1 ? parseInt(this.state.pBuyNowPrice / 50) : 5} ₺</button>
                                            <button name="pey" onClick={e => this.clickHandler(e, parseInt(this.state.pBuyNowPrice / 25) > 1 ? parseInt(this.state.pBuyNowPrice / 25) : 10)} className="btn btn-outline-success font-weight-bold m-1 col-md-3">{parseInt(this.state.pBuyNowPrice) / 25 > 1 ? parseInt(this.state.pBuyNowPrice / 25) : 10} ₺</button>
                                            <button name="pey" onClick={e => this.clickHandler(e, parseInt(this.state.pBuyNowPrice / 10) > 1 ? parseInt(this.state.pBuyNowPrice / 10) : 20)} className="btn btn-outline-success font-weight-bold m-1 col-md-3">{parseInt(this.state.pBuyNowPrice) / 10 > 1 ? parseInt(this.state.pBuyNowPrice / 10) : 20} ₺</button>
                                        </div>
                                        <div className="row">
                                            <form name="ozelpey" onSubmit={e => this.clickHandler(e, 1)} className="row pl-3">
                                            <input type="number" min="0" id="custompay" onChange={this.myChangeHandler} className="form-control m-1 col-md-7" placeholder="Özel Pey Miktarı" required />
                                            <button  type="submit" className="btn btn-blue m-1 col-md-4">Pey Ver</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="pl-3">
                                        {(this.state.pBuyNowPrice>0 && this.state.pType===0) ? <button name="hemenal" onClick={e => this.clickHandler(e, -1)} className="btn btn-blue">Hemen Al ({this.numberFormat(this.state.pBuyNowPrice)} ₺)</button> : <span></span>}
                                    </div>
                                    <div className="pl-3">
                                        <button onClick={e => this.clickHandler(e, -3)} className={this.state.userWatchlist ? "btn btn-danger" :  "btn btn-outline-danger"} title={this.state.userWatchlist ? "Takipten Çık" :  "Takip Et"}><i className="fas fa-heart"></i> {this.state.userWatchlist ? "Takipten Çık" :  "Takip Et"}</button>
                                    </div>
                                </div>

                                <hr />
                                
                                <small><span className="pr-3">Satıcı: </span>
                                {this.state.pSellerRatings.length>0 ? <OverlayTrigger
                                    placement="right"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={this.renderTooltip((this.state.pSellerRatings.reduce((a,v) =>  a = a + v.rating , 0 ))/this.state.pSellerRatings.length)}
                                    >
                                        <a href="#" onClick={(e) => {e.preventDefault(); this.changeTab(4)}}>{this.state.pSeller.title}</a>
                                    </OverlayTrigger> : this.state.pSeller.title}
                                {(this.state.pClosedAt==null && this.state.pStartedAt!=null)  &&     <button name="saticiyasor" onClick={e => this.clickHandler(e, 0)} className="ml-5 btn btn-white btn-sm">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-activity"><path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path></svg> Satıcıya Sor 
                                </button>}</small>                                
                                {this.state.pSellerRatings.length>0 && <React.Fragment><Stars ratinggoster={true} allowChange={false} rating={(this.state.pSellerRatings.reduce((a,v) =>  a = a + v.rating , 0 ))/this.state.pSellerRatings.length}/><span className="pl-2 fa fa-2x"><h5>{this.state.pSellerRatings.length} değerlendirme</h5></span></React.Fragment>}                               
                                <hr/>
                                {this.state.pShippingType==1 &&
                                <div className="card p-2">
                                    <p className=""><i className="fa fa-truck"></i> {this.state.pShippingPaymentBy==1 ? "Alıcı Ödemeli Kargo" : "Kargo Bedava"}</p>
                                    {this.state.pShippingPaymentBy==1 && this.state.pCargoPrices && this.state.pCargoPrices.map((cargo_p) => {
                                            return <p className="small"> {cargo_p.cargo_channel_title + ' Kargo ile ' + this.numberFormat(cargo_p.cargo_total_price) + ' ₺ '} </p>
                                    })}
                                </div>}
                                {this.state.activeModal === 0 && this.state.isOpen ?
                                    <ModalForm
                                        activeModal={this.activeModal}
                                        closeModal={this.closeModal}
                                        isOpen={this.state.isOpen}
                                        onHide={this.closeModal}
                                        handleSubmit={this.handleQuestion}
                                        aria-labelledby="contained-modal-title-vcenter"
                                        title="Satıcıya Sor"
                                        saveButtonCaption="Gönder"
                                        closeButtonCaption='İptal'
                                    >
                                        <p><strong>Satıcı:</strong> {this.state.pSeller.title}</p>
                                        <p><strong>Ürün:</strong> {this.state.pName}</p>
                                        <p><textarea name="question" placeholder="Sorunuzu buraya yazın" className="form-control col-md-12" onChange={this.handleChange} required/></p>
                                    </ModalForm>
                                    :
                                    null
                                }

                                {this.state.activeModal > 0 && this.state.isOpen ?
                                    <ModalForm
                                        activeModal={this.state.activeModal}
                                        closeModal={this.closeModal}
                                        isOpen={this.state.isOpen}
                                        onHide={this.closeModal}
                                        handleSubmit={this.handleSubmitBid}
                                        aria-labelledby="contained-modal-title-vcenter"
                                        title="Pey Ver"
                                        saveButtonCaption="Evet"
                                        closeButtonCaption='Hayır'
                                    >


                                        <h6>{this.numberFormat(parseInt(this.state.activePay)) + " ₺ pey vermek istediğinize emin misiniz?"} </h6>
                                        <h6>Vereceğiniz pey sonucu teklifiniz <strong>{this.numberFormat(parseInt(this.state.pCurrentPrice) + parseInt(this.state.activePay))} ₺</strong> olacaktır</h6>
                                        {this.state.pShippingType==1 && <p className="mt-4">{this.state.pShippingPaymentBy==1 ? "Not: Kargo ücreti alıcıya aittir" : "Not: Kargo Bedava"}</p>}
                                    </ModalForm>
                                    :
                                    null
                                }

                                {this.state.activeModal === -1 && this.state.isOpen ?
                                    <ModalForm
                                        activeModal={this.state.activeModal}
                                        closeModal={this.closeModal}
                                        isOpen={this.state.isOpen}
                                        onHide={this.closeModal}
                                        handleSubmit={this.handleBuyNow}
                                        aria-labelledby="contained-modal-title-vcenter"
                                        title="Hemen Al"
                                        saveButtonCaption="Evet"
                                        closeButtonCaption='Hayır'
                                    >
                                        <h4>Ürünü <span className="text-success">{this.numberFormat(this.state.pBuyNowPrice)} TL</span> karşılığında hemen almak istediğinize emin misiniz?</h4>
                                        <p className="smalltext">Önemli uyarı: Mezat sonlanana kadar ödeme yapmadığınız takdirde, ürün sepetinizden silinecek ve mezatı kazanan üyemizin sepetine eklenecektir</p>

                                    </ModalForm>
                                    :
                                    null
                                }
                                {this.state.activeModal === -2 && this.state.isOpen ?
                                    <ModalForm
                                        activeModal={this.state.activeModal}
                                        closeModal={this.closeModal}
                                        isOpen={this.state.isOpen}
                                        onHide={this.closeModal}
                                        handleSubmit={this.handleBuyNow}
                                        aria-labelledby="contained-modal-title-vcenter"
                                        title="Verilen Teklifler"
                                        saveButtonCaption=""
                                        closeButtonCaption='Kapat'
                                        size="lg"
                                    >
                                        {this.state.pBids.length>0 &&
                                        <React.Fragment>
                                        <p>Bu mezata verilen teklifler aşağıdaki gibidir:</p>

                                        <table className="table thead-dark table-striped">
                                            <thead><tr><th>Sıra</th><th>Üye</th><th>Pey</th><th>Teklif</th><th>Teklif Zamanı</th></tr></thead>
                                            <tbody>
                                            {(this.state.pBids && !this.state.isLoading) && this.state.pBids.map((mbid, index) => {
                                            return <tr><td>{index+1}</td><td>{mbid.user_title}</td><td>{this.numberFormat(mbid.bid_amount) + ' ₺'}</td><td>{this.numberFormat(mbid.total_amount) + ' ₺'}</td><td>{this.formatDate(mbid.created_at)}</td></tr>
                                        })}</tbody>
                                        </table>
                                        </React.Fragment>
                                        }
                                        {this.state.pBids.length==0 &&
                                            <p className="text-center">Bu mezata verilmiş teklif bulunmamaktadır</p>
                                        }
                                    </ModalForm>
                                    :
                                    null
                                }
                                {this.state.activeModal === -3 && this.state.isOpen ?
                                    <ModalForm
                                        activeModal={this.state.activeModal}
                                        closeModal={this.closeModal}
                                        isOpen={this.state.isOpen}
                                        onHide={this.closeModal}
                                        handleSubmit={this.handleWish}
                                        aria-labelledby="contained-modal-title-vcenter"
                                        title="Takip Listesi"
                                        saveButtonCaption="Evet"
                                        closeButtonCaption='Hayır'
                                    >
                                       {!this.state.userWatchlist && <p>Mezatı takibe almak istediğinize emin misiniz?</p>}
                                       {this.state.userWatchlist && <p>Mezatı takipten çıkmak istediğinize emin misiniz?</p>}

                                    </ModalForm>
                                    :
                                    null
                                }

                                {this.state.activeModal === -4 && this.state.isOpen ?
                                    <ModalForm
                                        activeModal={this.state.activeModal}
                                        closeModal={this.closeModal}
                                        isOpen={this.state.isOpen}
                                        onHide={this.closeModal}
                                        handleSubmit={this.handleBuyNow}
                                        aria-labelledby="contained-modal-title-vcenter"
                                        title="Değerlendir ve Yorum Yap"
                                        saveButtonCaption="Evet"
                                        closeButtonCaption='Hayır'
                                    >
                                        <div className="col-12 mb-3">Puan: <span className="fa fa-2x"><Stars rating={2} fontsize={"fa-2x"} allowChange={true}/></span></div>
                                        <div className="col-12"><textarea className="form-control" placeholder="Değerlendirme ile birlikte yorumunuzu yazmak isterseniz buraya yazın"></textarea></div>
                                    </ModalForm>
                                    :
                                    null
                                }
                                {this.state.activeModal === -5 && this.state.isOpen ?
                                    <ModalForm
                                        activeModal={this.activeModal}
                                        closeModal={this.closeModal}
                                        isOpen={this.state.isOpen}
                                        onHide={this.closeModal}
                                        handleSubmit={this.handleQuestion}
                                        aria-labelledby="contained-modal-title-vcenter"
                                        title="Büyük Fotoğraf"
                                        saveButtonCaption=""
                                        closeButtonCaption='Kapat'
                                        size="xl"
                                    >
                                        <div className="d-flex justify-content-center">
                                        {this.state.pImages.length > 0 && <Slider imageList={this.state.pImages} frameClassName="container-fullwidth" containerClassName="container-fullwidth" withThumbs={true} withDots={true} apiurl={this.props.apiurl} zoom={false}  withLinks={false}/>}
                                        </div>
                                    </ModalForm>
                                    :
                                    null
                                }

                            </h6>

                        </div>
                        <div className='col-md-12 text-left small mt-2'>


                            <Tabs activeKey={this.state.activeTab} onSelect={this.changeTab} justify>
                                
                                {this.state.pDescription && <Tab eventKey="1" title="Ürün Açıklaması">
                                    <div id="product-description" className="tab-item-wrapper text-wrap p-2" dangerouslySetInnerHTML={{__html: sanitizeHtml(this.state.pDescription)}}>
                                        
                                    </div>
                                </Tab>}

                                <Tab eventKey="2" title="Özellikler">
                                    <div className="tab-item-wrapper p-2">
                                    <table className="table table-striped table-hover">
                                        <tbody>
                                        {this.props.veri.property_label_1 && this.props.veri.property_value_1 && <tr><td className="col-3"><strong>{this.props.veri.property_label_1}</strong></td><td className="col-1"><strong> : </strong></td><td className="col-8">{this.props.veri.property_value_1}</td></tr>}
                                        {this.props.veri.property_label_2 && this.props.veri.property_value_2 && <tr><td className="col-3"><strong>{this.props.veri.property_label_2}</strong></td><td className="col-1"><strong> : </strong></td><td className="col-8">{this.props.veri.property_value_2}</td></tr>}
                                        {this.props.veri.property_label_3 && this.props.veri.property_value_3 && <tr><td className="col-3"><strong>{this.props.veri.property_label_3}</strong></td><td className="col-1"><strong> : </strong></td><td className="col-8">{this.props.veri.property_value_3}</td></tr>}
                                        {this.props.veri.property_label_4 && this.props.veri.property_value_4 && <tr><td className="col-3"><strong>{this.props.veri.property_label_4}</strong></td><td className="col-1"><strong> : </strong></td><td className="col-8">{this.props.veri.property_value_4}</td></tr>}
                                        {this.props.veri.property_label_5 && this.props.veri.property_value_5 && <tr><td className="col-3"><strong>{this.props.veri.property_label_5}</strong></td><td className="col-1"><strong> : </strong></td><td className="col-8">{this.props.veri.property_value_5}</td></tr>}
                                        {this.props.veri.property_label_6 && this.props.veri.property_value_6 && <tr><td className="col-3"><strong>{this.props.veri.property_label_6}</strong></td><td className="col-1"><strong> : </strong></td><td className="col-8">{this.props.veri.property_value_6}</td></tr>}
                                        {this.props.veri.property_label_7 && this.props.veri.property_value_7 && <tr><td className="col-3"><strong>{this.props.veri.property_label_7}</strong></td><td className="col-1"><strong> : </strong></td><td className="col-8">{this.props.veri.property_value_7}</td></tr>}
                                        {this.props.veri.property_label_8 && this.props.veri.property_value_8 && <tr><td className="col-3"><strong>{this.props.veri.property_label_8}</strong></td><td className="col-1"><strong> : </strong></td><td className="col-8">{this.props.veri.property_value_8}</td></tr>}
                                        {this.props.veri.property_label_9 && this.props.veri.property_value_9 && <tr><td className="col-3"><strong>{this.props.veri.property_label_9}</strong></td><td className="col-1"><strong> : </strong></td><td className="col-8">{this.props.veri.property_value_9}</td></tr>}
                                        {this.props.veri.property_label_10 && this.props.veri.property_value_10 && <tr><td className="col-3"><strong>{this.props.veri.property_label_10}</strong></td><td className="col-1"><strong> : </strong></td><td className="col-8">{this.props.veri.property_value_10}</td></tr>}
                                        {this.props.veri.property_label_11 && this.props.veri.property_value_11 && <tr><td className="col-3"><strong>{this.props.veri.property_label_11}</strong></td><td className="col-1"><strong> : </strong></td><td className="col-8">{this.props.veri.property_value_11}</td></tr>}
                                        {this.props.veri.property_label_12 && this.props.veri.property_value_12 && <tr><td className="col-3"><strong>{this.props.veri.property_label_12}</strong></td><td className="col-1"><strong> : </strong></td><td className="col-8">{this.props.veri.property_value_12}</td></tr>}
                                        {this.props.veri.property_label_13 && this.props.veri.property_value_13 && <tr><td className="col-3"><strong>{this.props.veri.property_label_13}</strong></td><td className="col-1"><strong> : </strong></td><td className="col-8">{this.props.veri.property_value_13}</td></tr>}
                                        {this.props.veri.property_label_14 && this.props.veri.property_value_14 && <tr><td className="col-3"><strong>{this.props.veri.property_label_14}</strong></td><td className="col-1"><strong> : </strong></td><td className="col-8">{this.props.veri.property_value_14}</td></tr>}
                                        {this.props.veri.property_label_15 && this.props.veri.property_value_15  && <tr><td className="col-3"><strong>{this.props.veri.property_label_15}</strong></td><td className="col-1"><strong> : </strong></td><td className="col-8">{this.props.veri.property_value_15}</td></tr>}
                                        </tbody>
                                        </table>
                                    </div>
                                </Tab>

                                {this.state.pAuctionQuestions.length>0 && <Tab eventKey="3" title="Soru & Cevap">
                                    <div className="tab-item-wrapper p-2">
                                        {this.state.pAuctionQuestions && this.state.pAuctionQuestions.map((question, index) => {
                                            return (
                                                <div className="card-body">
                                                    
                                                    <div className="row">
                                                        <div className="col-md-12 alert alert-primary mb-4"   role="alert" dangerouslySetInnerHTML={{__html: sanitizeHtml('<p><strong>' +  question.question_from_name + ': </strong></p><p>' + question.question_body + '</p><p className=\'float-right\'>' + this.formatDate(question.created_at) + '</p>')}}>
                                                        </div>
                                                    </div>
                                                    {question.auction_answer && question.auction_answer.map((ansid) => {
                                                        return (
                                                            <div className="row">
                                                            <div className="col-md-1"></div>
                                                            <div className="col-md-11 alert alert-secondary mb-4"> <strong>{ansid.answer_from_name}: </strong> {ansid.answer_body} <span className='float-right'> {this.formatDate(ansid.created_at)}</span></div>
                                                            </div>
                                                    )})}
                                                {this.state.pB &&
                                                <div className="row  align-middle">
                                                      <div className="col-md-1"></div>
                                                      <div className="col-md-6  mb-4"><textarea name="newReply" className="form-input col-md-12" onChange={this.handleChangeTextArea} ></textarea></div>
                                                      <div className="col-md-2 float-right"><button className="btn btn-small btn-green" onClick={e => this.handleSubmitReply(question.id)}>Yanıtla</button></div>
                                                      <div className="col-md-3"></div>
                                                    </div>
                                                }
                                            </div>)
                                        })}
                                        </div>
                                </Tab>}

                                {this.state.pSellerRatings.length>0 && <Tab eventKey="4" title="Satıcı Yorumları">
                                    <div className="tab-item-wrapper">
                                        <br />

                                        <div className="chat-body no-padding profile-message">
                                            <ul>                                                  
                                                {this.state.pSellerRatings && this.state.pSellerRatings.map((rating) => {
                                                        return (
                                                    <li className="message pb-3">
                                                        <img src="./assets/images/avatar01.png" className="online rounded-circle p-1" alt={rating.rated_by_user_title} />
                                                        <span className="message-text">
                                                            <span className="username">
                                                            {rating.rated_by_user_title}
                                                            <span className="pull-right">
                                                               {rating.rating && <Stars rating={rating.rating} allowChange={false}/>}
                                                            </span>

                                                            <span className="badge">{rating.approved? 'Ürünü Onayladı': 'Ürünü onaylamadı'}</span>
                                                            </span>
                                                            <br/>
                                                            {rating.comment}
                                                            <ul className="list-inline font-xs">
                                                                <li className="pull-right">
                                                                <small className="text-muted pull-right ultra-light"> {this.formatDate(rating.created_at)} </small>
                                                                </li>
                                                            </ul>
                                                            </span>
                                                    </li>)})}
                                            </ul>
                                            <div className="d-flex justify-content-center m-3">
                                                <button className="btn btn-primary" onClick={e => this.clickHandler(e, -4)}>Değerlendir ve Yorum Yap</button>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </Tab>}

                            </Tabs>
                        </div>

                    </div>

        
                </div>
                <div id="ProdListContainer02" className="col-md-12 mt-3 mb-3">
                    <div className="row rounded shadow-lg bg-white rounded my-auto" id="productlistcontents02">
                        <div className="col-lg-12 text-left mt-2 mb-2"><h3>Aynı Kategoriden Seçtiklerimiz</h3></div>
                            {this.props.categoryAuctions && this.props.categoryAuctions.map((wa) => (
                                <div className="col-md-3 col-lg-3 col-xl-3 p-3">
                                <Product01 apiurl={this.props.apiurl} productid={wa.id} productname={wa.name} productslug={wa.slug} salelabel={wa.sale_label} badgetype={wa.badgetype} seller={wa.seller} currentprice={wa.current_price} openingprice={wa.opening_price} buynowprice={wa.buynow_price} images={wa.auction_image} endtime={wa.end_time} starttime={wa.start_time} servertime={this.props.servertime} closed_at={wa.closed_at} />
                                </div>
                            ))
                            }
                        </div>
                    </div>
                {this.props.watchListAuctions.length>0 && <div id="ProdListContainer03" className="col-md-12 mt-3 mb-3">
                        <div className="row rounded shadow-lg bg-white rounded my-auto" id="productlistcontents03" ref={this.myProductList}>
                            <div className="col-lg-12 text-left mt-2 mb-2"><h3>Takip Listeniz</h3></div>
                                {this.props.watchListAuctions.map((wa) => (
                                    <div className="col-md-3 col-lg-3 col-xl-3 p-3">
                                    <Product01 apiurl={this.props.apiurl} productid={wa.id} productname={wa.name} productslug={wa.slug} salelabel={wa.sale_label} badgetype={wa.badgetype} seller={wa.seller} currentprice={wa.current_price} openingprice={wa.opening_price} buynowprice={wa.buynow_price} images={wa.auction_image} endtime={wa.end_time} starttime={wa.start_time} servertime={this.props.servertime} closed_at={wa.closed_at} />
                                    </div>
                                ))
                                }
                        </div>
                </div>}
            </div>)
    }
}

export default withCookies(ProductDetailsChild);