import React, { Component } from 'react';
import MezatSayaci from '../components/counter/mezatsayaci';
import Stars from '../components/stars';
import { urltemizle } from '../components/utils';
import ModalForm from '../components/modal/modalform';
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import swal from 'sweetalert2';


class Product01 extends Component {
    constructor(props) {
        super(props);
        this.listenToChannels = this.listenToChannels.bind(this);
        this.handleSubmitBid = this.handleSubmitBid.bind(this);
        this.handleBuyNow = this.handleBuyNow.bind(this);
    }
    state = {
        isLoading: false,
        isOpen: false,
        activeModal: null,
        activePay: 0,
        started: false,
        ended: false,
        notificationMessage:'',
        statusClassName: "col-12 bg-green p-2 text-white rounded shadow-lg justify-content-between blink",
        pCurrentPrice: (this.props.currentprice !== null ? this.props.currentprice : this.props.openingprice),
    }
    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false, activeModal: null });
    handleSubmit = (name) => {
        this.setState({ isOpen: false });
    }
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    handleSubmitBid() {
        this.setState({ isOpen: false });
        const { cookies } = this.props;
        var retype = 'success';
        var formData = new FormData();
        formData.append('auction_id', this.props.productid);
        formData.append('bid_amount', this.state.activePay);
        formData.append('total_amount', parseInt(this.state.pCurrentPrice) + parseInt(this.state.activePay));

        fetch(this.props.apiurl + '/api/addBid', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + cookies.get('access-token'),
            },
            credentials: 'include',
            body: formData
        })
            .then((response) => {
                if (!response.ok) {
                    retype = 'error';
                    return response.json();
                }
                else {
                    retype = 'success';
                    return response.json();
                }
            })
            .then((responseData) => {
                if (responseData.message === 'Unauthenticated.') {
                    swal.fire({
                        icon: 'error',
                        text:  'Pey verebilmek için üye girişi yapmalısınız',
                        timer: 7000,
                        showConfirmButton: false,
                        showCancelButton: false,
                        showCloseButton: true,
                        closeButtonCaption: 'Kapat'
                      });
                } else {
                    swal.fire({
                        icon: retype,
                        text:  responseData.message,
                        timer: 5000,
                        showConfirmButton: false,
                        showCancelButton: false,
                        showCloseButton: true,
                        closeButtonCaption: 'Kapat'
                      });
                }
            })

            .catch((error) => {
                swal.fire({
                    icon: 'error',
                    text:  'Bir hata oluştu. ' + error,
                    timer: 7000,
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                    closeButtonCaption: 'Kapat'
                  });
            });
    }

    handleBuyNow() {
        this.setState({ isOpen: false });
        const { cookies } = this.props;
        var retype = 'success';
        var formData = new FormData();
        formData.append('slug', this.props.productslug);

        fetch(this.props.apiurl + '/api/addtoCart', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + cookies.get('access-token'),
            },
            credentials: 'include',
            body: formData
        })
            .then((response) => {
                if (!response.ok) {
                    retype = 'error';
                    return response.json();
                }
                else {
                    retype = 'success';
                    return response.json();
                }
            })
            .then((responseData) => {
                if (responseData.message === 'Unauthenticated.') {
                    swal.fire({
                        icon: 'error',
                        text:  'Ürünü sepetinize eklemek için üye girişi yapmalısınız',
                        timer: 7000,
                        showConfirmButton: false,
                        showCancelButton: false,
                        showCloseButton: true,
                        closeButtonCaption: 'Kapat'
                      });
                } else {
                    swal.fire({
                        icon: retype,
                        text:  responseData.message,
                        timer: 5000,
                        showConfirmButton: false,
                        showCancelButton: false,
                        showCloseButton: true,
                        closeButtonCaption: 'Kapat'
                      });
                }
            })

            .catch((error) => {
                swal.fire({
                    icon: 'error',
                    text:  'Bir hata oluştu. ' + error,
                    timer: 7000,
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                    closeButtonCaption: 'Kapat'
                  });
            });
    }
    clickHandler(e, index) {
        e.preventDefault();
        if (e.target.name=='pey') {
            this.setState({ activePay: index })
        } else if (e.target.name=="ozelpey") {
            this.setState({ activePay: this.state.customPay })
        }
        this.setState({ isOpen: true, activeModal: index })
    }

    myChangeHandler = (event) => {
        event.target.value = event.target.value.replace(/\D/g, '');
        this.setState({ activeModal: 1, activePay: event.target.value.replace(/\D/g, '') });
    }
    numberFormat = (value) => {

        if (value > 0) {
            return Intl.NumberFormat(['ban', 'id']).format(value)
        } else {
            return 0;
        }
    }
    componentDidMount() {
        var n1 = this.props.servertime ? new Date(Date.parse(this.props.servertime)) : new Date();
        var deadline = new Date(Date.parse(new Date(this.props.endtime)));
        var beginning = new Date(Date.parse(new Date(this.props.starttime)));
        if (!this.props.closed_at) {
            this.listenToChannels();
        }

        if (beginning.getTime() > n1.getTime()) {
            this.setState({ started: false, ended: false})
        } else {
            this.setState({ started: true })
            if (deadline.getTime() > n1.getTime()) {
                this.setState({ ended: false })
            } else {
                this.setState({ ended: true })
            }
        }
        
    }

    listenToChannels = () => {
        window.Echo.channel('auction.' + this.props.productid)
            .listen('AuctionStarted', (e) => {
                this.setState({statusClassName: "col-12 bg-green p-2 text-white rounded shadow-lg justify-content-between blink", notificationMessage: 'Mezat Başladı', started: true, ended: false });
            })
            .listen('AuctionClosed', (e) => {
                this.setState({statusClassName: "col-12 bg-danger p-2 text-white rounded shadow-lg justify-content-between blink", auctionStatus: ' Tamamlanmış Mezat', notificationMessage: 'Mezat Kapandı', started: true, ended: true})
            })
            .listen('NewBid', (e) => {
                this.setState({ pCurrentPrice: e.bid.total_amount });
                this.setState({statusClassName: "col-12 bg-warning p-2 text-white rounded shadow-lg justify-content-between blink", notificationMessage: 'Yeni bir pey verildi'});
            })
    }
    render() {

        return (

            <div className="product-grid h-100 rounded" id={this.props.productid}>
                <div className="product-image">
                        {this.props.images && this.props.images.map((image, index) =>
                            <img key={"img" + index} className={"img-responsive pic-" + index} src={this.props.apiurl + image.thumbnail_url} alt={this.props.productname} />
                        )}

                    {this.props.salelabel !== '' && this.state.ended==false &&
                        (<span className={"product-sale-label " + this.props.badgetype}>{this.props.salelabel}</span>)
                    }
                    <span className="product-counter">
                        {this.state.ended==false && <MezatSayaci endtime={this.props.endtime} starttime={this.props.starttime} servertime={this.props.servertime} />}
                    </span>
                    <ul className="social">
                        <li><a href={urltemizle("mezat/" + this.props.productslug)} data-tip="İncele"><i className="fa fa-eye"></i></a></li>
                    </ul>


                </div>

                <div className="product-content">
                    <div className="product-rating">
                        {this.state.started==true && this.state.ended==false && <div id={"product-pay" + this.props.productid}>
                            <div className="product-pay p-1 text-dark">
                                Pey vermek için tıklayın:
                                <div className="">
                                    <button name="pey" onClick={e => this.clickHandler(e, parseInt(this.props.buynowprice / 100) > 1 ? parseInt(this.props.buynowprice / 100) : 1)} className="btn btn-sm btn-outline-success m-1">{parseInt(this.props.buynowprice / 100) > 1 ? parseInt(this.props.buynowprice/ 100) : 1} ₺</button>
                                    <button name="pey" onClick={e => this.clickHandler(e, parseInt(this.props.buynowprice / 50) > 1 ? parseInt(this.props.buynowprice / 50) : 5)} className="btn btn-sm btn-outline-success  m-1">{parseInt(this.props.buynowprice) / 50 > 1 ? parseInt(this.props.buynowprice / 50) : 5} ₺</button>
                                    <button name="pey" onClick={e => this.clickHandler(e, parseInt(this.props.buynowprice / 25) > 1 ? parseInt(this.props.buynowprice / 25) : 10)} className="btn btn-sm btn-outline-success  m-1">{parseInt(this.props.buynowprice) / 25 > 1 ? parseInt(this.props.buynowprice / 25) : 10} ₺</button>
                                    <button name="pey" onClick={e => this.clickHandler(e, parseInt(this.props.buynowprice / 10) > 1 ? parseInt(this.props.buynowprice / 10) : 20)} className="btn btn-sm btn-outline-success  m-1">{parseInt(this.props.buynowprice) / 10 > 1 ? parseInt(this.props.buynowprice / 10) : 20} ₺</button>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-9">
                                {(this.props.buynowprice>0 && this.props.type===0) ? <button onClick={e => this.clickHandler(e, -1)} className="btn btn-green btn-sm btn-block">Hemen Al ({this.numberFormat(this.props.buynowprice) + ' ₺'})</button>: null}
                                <a href={urltemizle("mezat/" + this.props.productslug)} title={this.props.productname} className="btn btn-dark btn-sm btn-block">Mezat Detayları</a>
                                </div>
                            </div>
                        </div>}
                        {this.state.ended && <div id={"product-pay" + this.props.productid}>
                            <div className="row justify-content-center">
                                <div className="col-9">
                                <a href={urltemizle("mezat/" + this.props.productslug)} title={this.props.productname} className="btn btn-dark btn-sm btn-block">Mezat Detayları</a>
                                </div>
                            </div>
                        </div>}
                    </div>
                    <h3 className=""><a href={urltemizle("mezat/" + this.props.productslug)} title={this.props.productname}><span className="limitedtext">{this.props.productname}</span></a></h3>
                    <div className="row mx-auto p-1">
                        <div className="text-center mx-auto"><div>Açılış Fiyatı</div>
                            <div className="font-weight-bold">
                                {(this.props.openingprice !== '0' && this.props.openingprice !== null) ? this.numberFormat(this.props.openingprice) + ' ₺' : 'Yok'}
                            </div>
                        </div>
                        <div className="text-center mx-auto"><div>{this.state.ended ? "Kapanış Fiyatı": "Mevcut Fiyat"}</div>
                        <div id="currentprice" className="font-weight-bold">
                            {this.numberFormat(this.state.pCurrentPrice) + ' ₺'}
                        </div>
                    </div>

                    {this.state.notificationMessage && <div className={this.state.statusClassName}>{this.state.notificationMessage}</div>}
                    </div>
                    {this.state.activeModal > 0 && this.state.isOpen ?
                        <ModalForm
                            activeModal={this.state.activeModal}
                            closeModal={this.closeModal}
                            isOpen={this.state.isOpen}
                            onHide={this.closeModal}
                            handleSubmit={this.handleSubmitBid}
                            aria-labelledby="contained-modal-title-vcenter"
                            title="Pey Ver"
                            saveButtonCaption="Evet"
                            closeButtonCaption='Hayır'
                        >


                            <h6>{parseInt(this.state.activePay) + " TL pey vermek istediğinize emin misiniz?"} </h6>
                            <h6>Vereceğiniz pey sonucu teklifiniz <strong>{parseInt(this.state.pCurrentPrice) + parseInt(this.state.activePay)} TL</strong> olacaktır</h6>
                        </ModalForm>
                        :
                        null
                    }

                    {this.state.activeModal === -1 && this.state.isOpen ?
                        <ModalForm
                            activeModal={this.state.activeModal}
                            closeModal={this.closeModal}
                            isOpen={this.state.isOpen}
                            onHide={this.closeModal}
                            handleSubmit={this.handleBuyNow}
                            aria-labelledby="contained-modal-title-vcenter"
                            title="Hemen Al"
                            saveButtonCaption="Evet"
                            closeButtonCaption='Hayır'
                        >
                            <h4>Ürünü <span className="text-success">{this.numberFormat(this.props.buynowprice)} TL</span> karşılığında hemen almak istediğinize emin misiniz?</h4>
                            <p className="smalltext">Önemli uyarı: Mezat sonlanana kadar ödeme yapmadığınız takdirde, ürün sepetinizden silinecek ve mezatı kazanan üyemizin sepetine eklenecektir</p>

                        </ModalForm>
                        :
                        null
                    }
                </div>

            </div>
        )

    }
}

export default withCookies(Product01);
