import React, {Component} from 'react';
import {urltemizle} from '../../components/utils';
import ModalForm from '../../components/modal/modalform';
import sanitizeHtml from 'sanitize-html';
import {instanceOf} from "prop-types";
import {withCookies, Cookies} from "react-cookie";
import Video from '../../parts/video';
import Swal from 'sweetalert2';
import Stamp from '../../components/stamp';

export class OrderDetails extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        isLoading: false,
        isOpen: false,
        activeModal: null,
        order_items: [],
        active_id: '',
        order_notification: '',
    }

    numberFormat = (value) => {
        if (value > 0) {
            return Intl.NumberFormat(['ban', 'id']).format(value)
        } else {
            return 0;
        }
    }
    formatDate = (dateString) => {
        const options = {hour: '2-digit', minute: '2-digit', second: '2-digit'}
        return new Date(dateString).toLocaleDateString(undefined, options)
    }

    clickHandler(e, index, id) {
        e.preventDefault();
        this.setState({isOpen: true, activeModal: index, active_id: id})
    }

    openModal = () => this.setState({isOpen: true});
    closeModal = () => this.setState({isOpen: false, activeModal: null});
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    render() {
        return (
            <div className="container bg-white shadow-lg mb-4 mt-4 rounded text-left">

                <div className="row">
                    <div className="col-md-9">
                        <div className="ibox">
                            <div className="ibox-title">
                                <span
                                    className="float-right">(<strong>{this.props.veri.order.order_item.length}</strong>) Ürün</span>
                                <Stamp status="is-nope" statusText={this.props.veri.order.status_text}/>

                                <h3 className="mb-1 text-uppercase text-primary"><a href="/u/siparislerim"
                                                                                    className="btn btn-white"
                                                                                    title="Sipariş listeme geri dön"><i
                                    className="fa fa-arrow-left"></i></a> Sipariş Detayları </h3>
                                <div className="row mt-2 mb-2">
                                    <div className="col-3"><strong>Sipariş Numarası</strong></div>
                                    <div className="col-1">:</div>
                                    <div className="col-8">{this.props.veri.order.id}</div>
                                </div>
                                <div className="row mt-2 mb-2">
                                    <div className="col-3"><strong>Sipariş Tarihi</strong></div>
                                    <div className="col-1">:</div>
                                    <div className="col-8">{this.formatDate(this.props.veri.order.created_at)}</div>
                                </div>
                            </div>


                            {this.props.veri.order.order_item && this.props.veri.order.order_item.map((item) =>

                                <div className="ibox-content p-4">

                                    <div>
                                        <div className="row">
                                            <div className="col-md-2">
                                                <div className="cart-product-imitation">
                                                    <a href={urltemizle("mezat/" + item.slug)} data-tip="İncele">
                                                        <img src={this.props.apiurl + item.public_url} width='100px'
                                                             alt={item.name}/>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-md-8 desc">
                                                <h3>
                                                    <a href={urltemizle("mezat/" + item.slug)}
                                                       className="text-navy">
                                                        {item.name}
                                                    </a>
                                                </h3>
                                                <p className="small limitedtext"
                                                   dangerouslySetInnerHTML={{__html: sanitizeHtml(item.description)}}>

                                                </p>

                                            </div>

                                            <div className="col-md-2">
                                                <h4>
                                                    {this.numberFormat(item.item_price)} ₺
                                                </h4>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group">
                                        <p className="font-weight-bold mb-0"> Kargo Durumu </p>
                                        <div>{item.cargo_status_text}</div>
                                        <div>
                                            <a
                                                href={"https://kargotakip.mngkargo.com.tr/?takipNo=" + item.order_item_track_no}>
                                                Kargo Takip
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            )}

                        </div>

                        <div className="container px-1 px-md-2 px-lg-4 mx-auto text-dark">
                            <h3 className="mb-1 text-uppercase text-primary">Teslimat Bilgileri </h3>
                            <div className="row d-flex">
                                <div className="col-sm-6">
                                    <div className="border-0">
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <p className="font-weight-bold mb-0">Teslim alacak kişi </p>
                                                <div>{this.props.veri.order.delivery_contact_name}</div>
                                            </div>
                                            <div className="form-group">
                                                <p className="font-weight-bold mb-0"> Ülke </p>
                                                <div>{this.props.veri.order.delivery_country}</div>
                                            </div>
                                            <div className="form-group">
                                                <p className="font-weight-bold mb-0"> İlçe </p>
                                                <div>{this.props.veri.order.delivery_town}</div>
                                            </div>
                                            <div className="form-group">
                                                <p className="font-weight-bold mb-0"> Teslimat Adresi </p>
                                                <div>{this.props.veri.order.delivery_address}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="border-0">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <p className="font-weight-bold mb-0">Teslim alacak kişinin
                                                        telefonu</p>
                                                    <div>{this.props.veri.order.delivery_contact_phone}</div>
                                                </div>
                                                <div className="form-group">
                                                    <p className="font-weight-bold mb-0"> İl </p>
                                                    <div>{this.props.veri.order.delivery_city}</div>
                                                </div>

                                                <div className="form-group">
                                                    <p className="font-weight-bold mb-0">Posta Kodu</p>
                                                    <div>{this.props.veri.order.delivery_postalcode}</div>
                                                </div>

                                                <div className="form-group">
                                                    <p className="font-weight-bold mb-0"> Teslimat Notu</p>
                                                    <div>{this.props.veri.order.additional_notes}</div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container px-1 px-md-2 px-lg-4 mx-auto text-dark">
                            <h3 className="mb-1 text-uppercase text-primary">Fatura Bilgileri</h3>
                            <div className="row d-flex">
                                <div className="col-xl-6 col-lg-7 col-md-8 col-sm-11">
                                    <div className="ccard border-0">
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <p className="font-weight-bold mb-0">Fatura Ünvanı </p>
                                                <div>{this.props.veri.order.invoice_title}</div>
                                            </div>
                                            <div className="form-group">
                                                <p className="font-weight-bold mb-0">Vergi No / TCKN </p>
                                                <div>{this.props.veri.order.invoice_tax_no} </div>
                                            </div>
                                            <div className="form-group">
                                                <p className="font-weight-bold mb-0"> Ülke </p>
                                                <div>{this.props.veri.order.invoice_country}</div>
                                            </div>
                                            <div className="form-group">
                                                <p className="font-weight-bold mb-0"> İlçe </p>
                                                <div>{this.props.veri.order.invoice_town}</div>
                                            </div>
                                            <div className="form-group">
                                                <p className="font-weight-bold mb-0"> Fatura Adresi </p>
                                                <div>{this.props.veri.order.invoice_address}</div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-7 col-md-8 col-sm-11">
                                    <div className="ccard border-0">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <p className="font-weight-bold mb-0">Fatura irtibat kişisi</p>
                                                    <div>{this.props.veri.order.invoice_contact}</div>
                                                </div>
                                                <div className="form-group">
                                                    <p className="font-weight-bold mb-0">Vergi Dairesi</p>
                                                    <div>{this.props.veri.order.invoice_tax_administration}</div>
                                                </div>
                                                <div className="form-group">
                                                    <p className="font-weight-bold mb-0"> İl </p>
                                                    <div>{this.props.veri.order.invoice_city}</div>
                                                </div>

                                                <div className="form-group">
                                                    <p className="font-weight-bold mb-0">Posta Kodu</p>
                                                    <div>{this.props.veri.order.invoice_postalcode}</div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="ibox">
                            <div className="ibox-title text-left">
                                <h5>Ödeme Özeti</h5>
                            </div>
                            <div className="ibox-content text-center">
                                    <span>
                                        Ödenen Tutar
                                    </span>
                                <h2 className="font-bold text-center">
                                    {this.numberFormat(this.props.veri.order.payment.paid_price)} ₺
                                </h2>
                                <div
                                    className="small">{this.props.veri.order.payment.installment == '1' ? '(Tek Çekim)' : '(' + this.props.veri.order.payment.installment + ' taksit)'}</div>
                                <hr/>
                                <div className="text-dark small">
                                    <div className="row">
                                        <div className="col-md-5 text-left">Ürün toplamı</div>
                                        <div className="col-md-1">:</div>
                                        <div
                                            className="col-md-5 text-right">{this.numberFormat(this.props.veri.order.payment.price)} ₺
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-5 text-left">Hizmet bedeli</div>
                                        <div className="col-md-1">:</div>
                                        <div
                                            className="col-md-5 text-right">{this.numberFormat(this.props.veri.order.commissions_total_buyer)} ₺
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-5 text-left">Kargo bedeli</div>
                                        <div className="col-md-1">:</div>
                                        <div
                                            className="col-md-5 text-right">{this.numberFormat(this.props.veri.order.shipping_price_total_buyer)} ₺
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <p className="text-left font-weight-bold text-primary">Kart Bilgileri</p>
                                <hr/>
                                <div className="text-dark small">
                                    <div className="row">
                                        <div className="col-md-5 text-left">Kart No</div>
                                        <div className="col-md-1">:</div>
                                        <div
                                            className="col-md-5 text-right">{this.props.veri.order.payment.bin_no + '**********'}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-5 text-left">Kart Çeşidi</div>
                                        <div className="col-md-1">:</div>
                                        <div
                                            className="col-md-5 text-right">{this.props.veri.order.payment.card_association.replace('_', ' ')}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-5 text-left">Kart Tipi</div>
                                        <div className="col-md-1">:</div>
                                        <div
                                            className="col-md-5 text-right">{this.props.veri.order.payment.card_type.replace('_', ' ')}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-5 text-left">Kart Ailesi</div>
                                        <div className="col-md-1">:</div>
                                        <div
                                            className="col-md-5 text-right">{this.props.veri.order.payment.card_family.replace('_', ' ')}</div>
                                    </div>
                                </div>
                                <hr/>

                            </div>
                        </div>

                        <div className="ibox">
                            <div className="ibox-title">
                                <h5>Destek</h5>
                            </div>
                            <div className="ibox-content text-center">
                                <h3><i className="fa fa-phone"></i> <a href="tel:0850 840 44 94">0850 840 44 94</a></h3>
                                <span className="small">
                                        Aklınıza takılan tüm sorular için 24 saat hizmetinizdeyiz
                                    </span>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 p-4">
                        <div className="col-sm-6 float-left">
                            <a href="/u/siparislerim" className="btn btn-white"><i
                                className="fa fa-arrow-left"></i> Siparişlerime Dön</a>
                        </div>
                        <div className="col-sm-6 float-right">

                        </div>
                    </div>
                </div>


                <div className="row mb-2">
                    <div className="col-12"><Video apiurl={this.props.apiurl} slug={this.props.slug}/></div>
                </div>
            </div>

        )
    }

}

export default withCookies(OrderDetails);