import React, { Component } from 'react';
import Header from '../parts/header/header';
import FooterSection from '../parts/footer/footersection';
import Banner from '../parts/header/banner';
import SideMenu from '../components/sidemenu/sidemenu';
import ModalForm from '../components/modal/modalform';
import Slider from "../components/slider/Slider.js";
import ShowMoreText from "react-show-more-text";
import sanitizeHtml from 'sanitize-html';
import ReactPlayer from 'react-player';
import '../assets/css/support.css';
import swal from 'sweetalert2';
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";

//{this.props.video!=null && ReactPlayer.canPlay(this.props.video) && <ReactPlayer className='col-12 mt-2' url={this.props.video} />}
// {this.state.pImages.length > 0 && <Slider imageList={this.state.pImages} containerClassName="container-slider rounded-lg" withThumbs={true} withDots={true} apiurl={this.props.apiurl} zoom={true} />}
export class SupportCenter extends Component {
    constructor(props) {
        super(props);
        this.handleActiveIndex = this.handleActiveIndex.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }
   state = { 
        url: this.props.apiurl + '/api/support',
        selectedTitle: this.props.activetab,
        menuItems: [],
        support_data: [],
        isOpen: false,
        activeIndex: 0,
        activeModal: null,
        questionImages: [],
        siteInfo: [],
        ticketTitle: '',
        ticketText: '',
    }
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
      };
    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false, activeModal: null });
    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    }
    clickHandler(e, index) {
        e.preventDefault();
        this.setState({ isOpen: true, activeModal: index })
    }
    handleActiveIndex(ind) {
        //alert("yeni index: " + ind + " eski index: "  +  this.state.activeIndex + "-->" + this.state.isOpen);
        ind!==this.state.activeIndex ? this.setState({isOpen: true}) : this.setState({isOpen: !this.state.isOpen}) ;
        this.setState({activeIndex: ind});
    }
    componentDidMount() {
        this.getData();
    }
    handleSubmit() {
        this.setState({'isOpen': false});
        const { cookies } = this.props;
        var retype = 'success';
        var formData = new FormData();
        formData.append('ticket_title', this.state.ticketTitle);
        formData.append('ticket_text', this.state.ticketText);

        fetch(this.props.apiurl + '/api/addSupportTicket', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + cookies.get('access-token'),
            },
            credentials: 'include',
            body: formData
        })
            .then((response) => {
                if (!response.ok) {
                    retype = 'error';
                    return response.json();
                }
                else {
                    retype = 'success';
                    return response.json();
                }
            })
            .then((responseData) => {
                if (responseData.message === 'Unauthenticated.') {
                    swal.fire({
                        icon: 'error',
                        text:  'Destek talebinde bulunmak için üye girişi yapmalısınız',
                        timer: 7000,
                        showConfirmButton: false,
                        showCancelButton: false,
                        showCloseButton: true,
                        closeButtonCaption: 'Kapat'
                      });
                } else {
                    swal.fire({
                        icon: retype,
                        text:  responseData.message,
                        timer: 5000,
                        showConfirmButton: false,
                        showCancelButton: false,
                        showCloseButton: true,
                        closeButtonCaption: 'Kapat'
                      }).then(function() {
                        window.location = "/u/destektaleplerim";
                    });
                }
            })

            .catch((error) => {
                swal.fire({
                    icon: 'error',
                    text:  'Bir hata oluştu. ' + error,
                    timer: 7000,
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                    closeButtonCaption: 'Kapat'
                  });
            });
    }

    getData() {
        const { cookies } = this.props;
        const apiUrl = this.state.selectedTitle ? this.state.url + "/" + this.state.selectedTitle :  this.state.url;
        fetch(apiUrl, {
          method: 'GET',
          headers: {
            'Accept': 'application/json'            
          },
          credentials: 'include'
        })
          .then(res => res.json())
          .then((data) => {
            data.support_data && this.setState({ menuItems: data.support_titles, support_data: data.support_data, selectedTitle: data.selected_title, siteInfo: data.site_info})
            this.props.anchorlink && document.getElementById(this.props.anchorlink).scrollIntoView();
          })
    
          .catch(err => {
            console.log(err)
    
            //this.myToast("Teknik bir problemimiz var.<br/> Menü oluşturulamadı.<br/> Problem detayı:" + err);
          })
      }
    render() {
        document.title = "Mezatvar - Destek Merkezi"
        return (
            <div className="App">
            <Banner placement='0' slug={this.props.slug} apiurl={this.props.apiurl}/>
            <Header slug={this.props.slug} q={this.props.q} apiurl={this.props.apiurl}/>
            <div className="container bg-white shadow-lg mb-4 mt-4 pt-3 pb-3 rounded text-left">
            <nav aria-label="breadcrumb" className="main-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="/">Anasayfa</a></li>
                  <li className="breadcrumb-item active" aria-current="page">Destek Merkezi</li>
                </ol>
              </nav>
                <Banner placement='1' slug={this.props.slug} apiurl={this.props.apiurl}/>
                
                <div className="row">
                    <div className="col-md-4 d-none d-md-block">
                        <div className="row-md-12">
                        <SideMenu activetab={this.state.selectedTitle} mainDirectory={"destekmerkezi"} menuItems={this.state.menuItems}/>
                        </div>

                    {this.state.siteInfo && this.state.siteInfo.map((info) => (
                        <div className="row-md-12 text-center text-dark card mt-2">
                            <div className="text-primary font-weight-bold pt-2">Müşteri Hizmetleri</div>
                            <div className="card-body p-2">
                                    <ul className="list-unstyled mb-0">
                                    <li>
                                    <h2><a href={"tel:"+ info.customer_service_phone}><strong>{info.customer_service_phone}</strong></a></h2>
                                    </li>
                                    <li>
                                        <h4><span className="p-1"></span><a href={info.whatsapp_url}  target="_blank" rel="noopener noreferrer"><i className='fab fa-whatsapp'></i> WhatsApp Destek</a></h4>
                                    </li>
                                    </ul>

                            <button onClick={e => this.clickHandler(e, 0)} className="btn btn-green">Destek Talebinde Bulun</button>
                            </div>
                            {this.state.activeModal === 0 && this.state.isOpen ?
                                    <ModalForm
                                        activeModal={this.activeModal}
                                        closeModal={this.closeModal}
                                        isOpen={this.state.isOpen}
                                        onHide={this.closeModal}
                                        handleSubmit={this.handleSubmit}
                                        aria-labelledby="contained-modal-title-vcenter"
                                        title="Destek Talebi"
                                        saveButtonCaption="Gönder"
                                        closeButtonCaption='İptal'
                                    >
                                        <select className="form-control mb-1"
                                            type="text"
                                            name="ticketTitle"
                                            id="ticketTitle"
                                            placeholder="Destek talebinizin konusu"
                                            onChange={this.handleChange} 
                                        >
                                            {this.state.menuItems && this.state.menuItems.map(menuItem => <option selected={this.state.selectedTitle === menuItem.slug} value={menuItem.title}>{menuItem.title}</option>)}
                                            <option value="Diğer">Diğer</option>
                                        </select>
                                        <p><textarea rows="6" name="ticketText" placeholder="Talebinizi buraya yazın" className="form-control col-md-12" onChange={this.handleChange}/></p>
                                    </ModalForm>
                                    :
                                    null
                                }
                        </div>
                    ))}
                    </div>
                    <div className="col-md-8">
                    {this.state.support_data && this.state.support_data.map((individualData) => {
                       return individualData.support_posts && individualData.support_posts.map((post) => {
                            return <div id={post.slug}>
                            <div className="text-primary font-weight-bold pt-3 pl-1">
                                {post.title}
                                <hr/>
                            </div>
                            {post.video &&<div className="card-body row-md-12">
                                <div className="player-wrapper pb-4">
                                    <ReactPlayer className="react-player" url={post.video} controls={true} width="100%" height="100%"/>
                                </div>
                            </div>}
                            
                            <div className='row-md-12'>
                                <ShowMoreText
                                /* Default options */
                                lines={5}
                                more="Daha fazla göster"
                                less="Daha az göster"
                                className="row-md-12 h-100"
                                anchorClass="my-anchor-css-class"
                                onClick={this.executeOnClick}
                                expanded={false}
                                truncatedEndingComponent={"... "}
                            >
                                <div dangerouslySetInnerHTML={{__html: sanitizeHtml(post.content)}}>

                                </div>
                                </ShowMoreText>
                            </div>
                            {post.support_post_image.length>0 && <div className="card-body row-md-12">
                                <Slider imageList={post.support_post_image} containerClassName="container-slider rounded-lg" withThumbs={true} withDots={true} apiurl={this.props.apiurl} zoom={true}  withLinks={false}/>
                            </div>}
                        </div>
                        } ) 
                    }
                    
                    )}
                    
                    </div>
                </div>
                <div className="row gutters-sm pt-2">
                    <div className="col-md-12 card">
                    <div className="card-body">
                        <div className="tab-pane active" id="profile">
                            <h6>SIK SORULANLAR</h6>
                            <hr />
                        </div>
                    {this.state.support_data && this.state.support_data.map((faqData) => {
                       return faqData.support_faqs && faqData.support_faqs.map((faq, findex) => {
                        return <div className="card mb-1 question" onClick={() => this.handleActiveIndex(findex)}>
                                <div className="card-header text-primary font-weight-bold" id={faq.slug}  >
                                        <span>{faq.question}</span><span className="float-right">{(this.state.activeIndex==findex && this.state.isOpen) ? "-": "+"}</span> 
                                </div>
                                {(this.state.activeIndex==findex && this.state.isOpen) && <div id={"description" + findex} 
                                    className="collapse show">
                                    <div className="card-body">
                                        
                                        <div className="row">
                                        <div className="col-md-6">

                                        {faq.video && <div className="player-wrapper pb-4">
                                        <ReactPlayer className="react-player" url={faq.video} controls={true} width="100%" height="100%"/>
                                        </div>}
                                        {faq.support_faq_image.length>0 && <div className="right-body">
                                        <Slider imageList={faq.support_faq_image} containerClassName="container-slider rounded-lg" withThumbs={true} withDots={true} apiurl={this.props.apiurl} zoom={true}  withLinks={false} />
                                        </div>
                                        }
                                        </div>
                                        <div className={(faq.video || faq.support_faq_image.length>0) ? "col-md-6": "col-md-12"} dangerouslySetInnerHTML={{__html: sanitizeHtml(faq.answer)}}>
                                        </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                }
                            </div>
                        })}
                        )}
                    </div>
                </div>
            </div>
        </div>
        <Banner placement='2' slug={this.props.slug} showModal={true} apiurl={this.props.apiurl}/>
        <FooterSection apiurl={this.props.apiurl} />
        </div>
        )
    }
}

export default withCookies(SupportCenter);