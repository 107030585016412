import React, { Component } from 'react';
import { urltemizle } from '../../components/utils';
import CartEmpty from '../cart/cartempty';
import '../../assets/css/payment.css';
import Stepper from '../../components/stepper/stepper.js';
import swal from 'sweetalert2';
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";

export class BillingDetails extends Component {
    constructor(props) {
        super(props);
        this.getProvinces = this.getProvinces.bind(this);
        this.getBillingCities = this.getBillingCities.bind(this);
        this.handleBillingProvinceChange = this.handleBillingProvinceChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }
    state = {
        errorDesc:'',
        isLoading: false,
        isOpen: false,
        activeModal: null,
        cart_id: '',
        cart_total_buyer: '',
        cart_items: [],
        active_id: '',
        iller: [],
        billingcities: [],
        mahalleler: [],
        billingTitle:'',
        billingContact:'',
        billingTaxNo:'',
        billingTaxAdministration:'',
        billingAddress:'',
        billingCountry:'Türkiye',
        billingProvince:'',
        billingCity:'',
        billingPostalCode:'',
    }

    numberFormat = (value) => {
        if (value > 0) {
            return Intl.NumberFormat(['ban', 'id']).format(value)
        } else {
            return 0;
        }
    }

    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false, activeModal: null });
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    
    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    }

    
    handleBillingProvinceChange = (event) =>  {
        this.setState({[event.target.name]: event.target.value});
        this.getBillingCities(event.target.value);
    }

    getProvinces() {
        fetch(this.props.apiurl + '/api/iller', {method: 'GET', credentials: 'include'})
        .then(res => res.json())
        .then((data) => {
            this.setState({iller:data});
        }
        )
        .catch(err => {
            this.setState({errorDesc: err});
        })
    }

    getBillingCities(province) {
        fetch(this.props.apiurl + '/api/ilceler/' + province,  {method: 'GET', credentials: 'include'})
        .then(res => res.json())
        .then((data) => {
            this.setState({billingcities:data});
        }
        )
        .catch(err => {
            this.setState({errorDesc: err});
        })
    }
    
    handleSubmit = event => {
        event.preventDefault();
        this.setState({ isOpen: false });

          const { cookies } = this.props;
          var formData = new FormData()
    
          //formData.append('name',  this.state.urunismi)
          formData.append('invoice_title', this.state.billingTitle)
          formData.append('invoice_contact', this.state.billingContact)
          formData.append('invoice_tax_no', this.state.billingTaxNo)
          formData.append('invoice_tax_administration', this.state.billingTaxAdministration)
          formData.append('invoice_address', this.state.billingAddress)
          formData.append('invoice_town', this.state.billingCity)
          formData.append('invoice_city', this.state.billingProvince)
          formData.append('invoice_postalcode', this.state.billingPostalCode)
          formData.append('invoice_country', this.state.billingCountry)

          fetch(this.props.apiurl + '/api/updateCartInvoice', {
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + cookies.get('access-token') ,
              },
              credentials: 'include',
              body: formData
            })
            .then((response) => {
              if(!response.ok) {
                throw new Error(response.statusText)
              }
              else{
                return response.json();
              } 
            })
            .then((responseData) => {
                //cookies.set('access-token', responseData.token, { path: '/' });
                //window.location='/mezat/' + responseData.slug;
                window.location='/odeme';
            })
    
            .catch((error) => {
                swal.fire({
                    icon: 'error',
                    text:  'Bir hata oluştu. ' + error,
                    timer: 7000,
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                    closeButtonCaption: 'Kapat'
                  });
            });
      };
    componentDidMount() {
            this.props.veri && 
                    this.setState({
                        cart_id: this.props.veri.id,
                        cart_total_buyer: this.props.veri.cart_total_buyer,
                        cart_items: this.props.veri.cart_item,
                        items_total: this.props.veri.items_total,
                        commissions_total_buyer: this.props.veri.commissions_total_buyer,
                        billingTitle: this.props.veri.invoice_title,
                        billingContact: this.props.veri.invoice_contact,
                        billingTaxNo: this.props.veri.invoice_tax_no,
                        billingTaxAdministration: this.props.veri.invoice_tax_administration,
                        billingAddress: this.props.veri.invoice_address,
                        billingCity: this.props.veri.invoice_town!=null ? this.props.veri.invoice_town.toLocaleUpperCase(): '',
                        billingProvince: this.props.veri.invoice_city !=null ?  this.props.veri.invoice_city.toLocaleUpperCase(): '',
                        billingPostalCode: this.props.veri.invoice_postalcode,
                    })
                    

        this.getBillingCities(this.props.veri.invoice_city!=null ? this.props.veri.invoice_city.toLocaleUpperCase():'');
        this.getProvinces();
    }


    render() {

        if (this.state.cart_items.length === 0) {
            return <CartEmpty />
        } else {
            return (
                <div className="container bg-white shadow-lg mb-4 mt-4 rounded text-left">
                    <div className="row  pt-2 pb-0">
                        <div className="col-md-12">
                            <Stepper steps={["Teslimat Bilgileri", "Fatura Bilgileri", "Ödeme Bilgileri", "Özet"]} activestep={2} />
                        </div>
                    </div>
                    <form onSubmit={this.handleSubmit}>
                    <div className="row">
                        <div className="col-sm-9">
                        <div className="container px-1 px-md-2 px-lg-4 mx-auto text-dark">
                            <h3 className="mb-1 text-uppercase text-primary">Fatura Bilgileri</h3>
                            <p className="smalltext">(*) ile işaretli alanların doldurulması zorunludur</p>
                            <div className="row d-flex">
                                <div className="col-xl-6 col-lg-7 col-md-8 col-sm-11">
                                    <div className="ccard border-0">
                                    <div className="col-sm-12">
                                            <div className="form-group">
                                                <p className="font-weight-bold mb-0">Fatura Ünvanı (*)</p> <input className="form-control text-uppercase" type="text" name="billingTitle" placeholder="Fatura Ünvanı" value={this.state.billingTitle} size="255" onChange={this.handleChange} required/>
                                            </div>
                                            <div className="form-group">
                                                <p className="font-weight-bold mb-0">Vergi No / TCKN (*)</p> <input className="form-control text-uppercase" type="text" name="billingTaxNo" value={this.state.billingTaxNo} placeholder="Vergi No ya da TC Kimlik No" size="50" onChange={this.handleChange} required/>
                                            </div>
                                            <div className="form-group">
                                            <p className="font-weight-bold mb-0"> Ülke (*)</p>
                                            <select className="form-select form-control" size="1" placeholder="Ülke" name="billingCountry" id="billingCountry" onChange={this.handleChange} required>
                                                <option key="" value="Türkiye">TÜRKİYE</option>
                                            </select>
                                            </div>
                                            <div className="form-group">
                                                <p className="font-weight-bold mb-0"> İlçe (*)</p>
                                                <select className="form-select form-control" size="1" placeholder="İlçe" name="billingCity" id="billingCity"  value={this.state.billingCity} defaultValue={this.state.billingCity} onChange={this.handleChange} required>
                                                    <option key="" value="" selected disabled hidden>Lütfen bir ilçe seçin</option>
                                                    {this.state.billingcities && this.state.billingcities.map((e, key) => {
                                                    return <option key={key} value={e.ilce}>{e.ilce}</option>;
                                                    })}
                                                </select>
                                                </div>
                                            <div className="form-group">
                                            <p className="font-weight-bold mb-0"> Fatura Adresi (*)</p>
                                            <textarea name="billingAddress" value={this.state.billingAddress} placeholder="Fatura açık adresi giriniz" className="form-control col-md-12" onChange={this.handleChange} required/>
                                            </div>

                                                                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-7 col-md-8 col-sm-11">
                                    <div className="ccard border-0">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <p className="font-weight-bold mb-0">Fatura irtibat kişisi</p> <input className="form-control text-uppercase" type="text" name="billingContact" value={this.state.billingContact} placeholder="Fatura için iletişim kurulması gereken kişi" size="50" onChange={this.handleChange} />
                                                </div>
                                                <div className="form-group">
                                                <p className="font-weight-bold mb-0">Vergi Dairesi</p> <input className="form-control text-uppercase" type="text" name="billingTaxAdministration" value={this.state.billingTaxAdministration} placeholder="Vergi Dairesi" size="50" onChange={this.handleChange} />
                                                </div>
                                                <div className="form-group">
                                                <p className="font-weight-bold mb-0"> İl (*)</p>
                                                <select className="form-select form-control" size="1" placeholder="İl" name="billingProvince" id="billingProvince" value={this.state.billingProvince} defaultValue={this.state.billingProvince} onChange={this.handleBillingProvinceChange}>
                                                    <option key="" value="" selected disabled hidden>Lütfen bir il seçin</option>
                                                    {this.state.iller && this.state.iller.map((e, key) => {
                                                    return <option key={key} value={e.il}>{e.il}</option>;
                                                    })}
                                                </select>
                                                </div>

                                                <div className="form-group">
                                                <p className="font-weight-bold mb-0">Posta Kodu</p> <input className="form-control" type="text" name="billingPostalCode" placeholder="Fatura adresine ait posta kodu" size="7" minLength="5" maxLength="7" value={this.state.billingPostalCode} onChange={this.handleChange}/>
                                                </div>
                                                                                                
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        </div>
                        <div className="col-md-3">
                            <div className="ibox">
                                <div className="ibox-title text-left">
                                    <h5>Sepet Özeti</h5>
                                </div>
                                <div className="ibox-content text-center">
                                    <span>
                                        Ödenecek Tutar
                                    </span>
                                    <h2 className="font-bold text-center">
                                        {this.numberFormat(this.state.cart_total_buyer)} ₺
                                    </h2>

                                    <hr />

                                    <div className="m-t-sm  text-center">
                                        <div className="btn-group">
                                            <button type="submit" className="btn btn-blue btn-sm"><i className="fa fa-credit-card"></i> Ödeme Bilgilerine Geç</button>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="text-dark small">
                                        <div className="row">
                                            <div className="col-md-5 text-left">Ürün toplamı</div>
                                            <div className="col-md-1">:</div>
                                            <div className="col-md-5 text-right">{this.numberFormat(this.state.items_total)} ₺</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-5 text-left">Hizmet bedeli</div>
                                            <div className="col-md-1">:</div>
                                            <div className="col-md-5 text-right">{this.numberFormat(this.state.commissions_total_buyer)} ₺</div>
                                        </div>

                                    </div>
                                    <hr />
                                </div>
                            </div>

                            <div className="ibox">
                                <div className="ibox-title">
                                    <h5>Destek</h5>
                                </div>
                                <div className="ibox-content text-center">
                                    <h3><i className="fa fa-phone"></i> <a href="tel:0850 840 44 94">0850 840 44 94</a></h3>
                                    <span className="small">
                                        Aklınıza takılan tüm sorular için 24 saat hizmetinizdeyiz
                                    </span>
                                </div>
                                
                            </div>
 
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12 p-4">
                            <div className="col-sm-6 float-left">
                                <a href="/teslimat" className="btn btn-white"><i className="fa fa-arrow-left"></i> Teslimat Bilgilerine Dön</a>
                            </div>
                            <div className="col-sm-6 float-right">
                                <button type="submit" className="btn btn-blue pull-right"><i className="fa fa fa-credit-card"></i> Ödeme Bilgilerine Geç <i className="fa fa-arrow-right"></i></button>
                            </div>
                        </div>
                    </div>
                    </form>
                </div>

            )
        }
    }

}

export default withCookies(BillingDetails);