import React, { Component } from 'react';
import Video from '../../parts/video';
import Product01 from '../../parts/product01';

export class CartEmpty extends Component {

    render() {
        document.title = "Mezatvar - Sepetim"
        return (
            <>
            <div className="container bg-white shadow-lg mb-2 mt-2 rounded">
                <div className="row">
                    <div className="col-md-12">
                        <div className="">
                            <div className="card-body cart">
                                <div className="col-sm-12 empty-cart-cls text-center">
                                {this.props.notification && <div className="alert alert-warning" role="alert">{this.props.notification}</div>}
                                    <h1><i className="text-green fas fa-shopping-cart"></i></h1>
                                    <h4><strong>Sepetin şu an boş</strong></h4>
                                    <h5>Sepetini Mezatvar'ın fırsatlarla dolu dünyasından doldurmak için<br />
                                        aşağıdaki ürünleri incelemeye başlayabilirsin.</h5> <a href="/" className="btn btn-blue cart-btn-transform m-3" data-abc="true">alışverişe devam</a>
                                </div>

                            </div>
                        </div>
                        
                    </div>
                    
                    </div>
                    
                <div className="row mb-2">
                    <div className="col-12"><Video apiurl={this.props.apiurl} slug={this.props.slug}/></div>
                </div>
                <div className="row">
               
                {this.props.showcases && <div id="ProdListContainer02" className="col-md-12 mt-3 mb-3">
                        <div className="row rounded shadow-lg bg-white rounded my-auto" id="productlistcontents02" ref={this.myProductList}>
                            <div className="col-lg-12 text-left mt-2 mb-2"><h3>Öne Çıkan Mezatlardan Seçtiklerimiz</h3></div>
                                { this.props.showcases.map((wa) => (
                                    <div className="col-md-3 col-lg-3 col-xl-3 p-3">
                                    <Product01 apiurl={this.props.apiurl} productid={wa.id} productname={wa.name} productslug={wa.slug} salelabel={wa.sale_label} badgetype={wa.badgetype} seller={wa.seller} currentprice={wa.current_price} openingprice={wa.opening_price} buynowprice={wa.buynow_price} images={wa.auction_image} endtime={wa.end_time} starttime={wa.start_time} servertime={this.props.servertime} closed_at={wa.closed_at} />
                                    </div>
                                ))
                                }
                        </div>
                </div>}
                {this.props.watchListAuctions && <div id="ProdListContainer01" className="col-md-12 mt-3 mb-3">
                        <div className="row rounded shadow-lg bg-white rounded my-auto" id="productlistcontents01" ref={this.myProductList}>
                            <div className="col-lg-12 text-left mt-2 mb-2"><h3>Takip Listeniz</h3></div>
                                {this.props.watchListAuctions.map((wa) => (
                                    <div className="col-md-3 col-lg-3 col-xl-3 p-3">
                                    <Product01 apiurl={this.props.apiurl} productid={wa.id} productname={wa.name} productslug={wa.slug} salelabel={wa.sale_label} badgetype={wa.badgetype} seller={wa.seller} currentprice={wa.current_price} openingprice={wa.opening_price} buynowprice={wa.buynow_price} images={wa.auction_image} endtime={wa.end_time} starttime={wa.start_time} servertime={this.props.servertime} closed_at={wa.closed_at} />
                                    </div>
                                ))
                                }
                        </div>
                </div>}
            </div>
            </div>
           
            </>
        )
    }
}

export default CartEmpty;