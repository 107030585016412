import React, { Component } from 'react';
import Header from '../../parts/header/header';
import FooterSection from '../../parts/footer/footersection';
import Banner from '../../parts/header/banner';
import UserLeftMenu from './userleftmenu';
import NotAuthorized from '../notauthorized';
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import Product01 from '../../parts/product01';

import DateCombo from '../../components/datecombo/datecombo';
import ModalForm from '../../components/modal/modalform';
import UserMobileMenu from './usermobilemenu';

export class MyAuctions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorDesc:'',
      isLoading: false,
      isAuthenticated: false,
      isOpen: false,
      activeModal: null,
      err: false,
      openAuctions: [],
      closedAuctions: [],
      deniedAuctions: [],
      waitingAuctions:[],
      servertime:[],
    }
  }

  
  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false, activeModal: null });
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  formatDate = (dateString) => {
    const options = { hour: '2-digit', minute: '2-digit', second: '2-digit' }
    return new Date(dateString).toLocaleDateString(undefined, options)
  }
  componentDidMount() {
    this.setState({isLoading:true, isAuthenticated: false});
    const { cookies } = this.props;

    fetch(this.props.apiurl + '/api/myauctions', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + cookies.get('access-token') ,
        },
        credentials: 'include'
      })
      .then((response) => {
        if(!response.ok) {
            return response.json();
          }
          else{
            return response.json();
          } 
      })
      .then((responseData) => {
        if (responseData.message==='Unauthenticated.') {
          this.setState({ isLoading: false, isAuthenticated:false })
        } else {
          if (responseData) {
            this.setState({ 
               isLoading: false, 
               isAuthenticated:true, 
               servertime: responseData.server_time.formatted,
               closedAuctions: responseData.closed_auctions,
               deniedAuctions: responseData.denied_auctions,
               waitingAuctions: responseData.waiting_auctions,
               openAuctions: responseData.open_auctions})
          }
        }
       })

      .catch((error) => {
        this.setState({errorDesc: error});
      });
  }

  render() {
    document.title = "Mezatvar - Mezatlarım"
    if (this.state.isAuthenticated === true) {
      return (
        !this.state.isLoading &&
        <div className="App">
          <Banner placement='0' slug={this.props.slug} apiurl={this.props.apiurl}/>
          <Header slug={this.props.slug} q={this.props.q} apiurl={this.props.apiurl} />
          

            <div className="container bg-white shadow-lg mb-4 mt-4 pt-3 pb-3 rounded text-left">

              <nav aria-label="breadcrumb" className="main-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="/">Anasayfa</a></li>
                  <li className="breadcrumb-item active" aria-current="page">Mezatlarım</li>
                </ol>
              </nav>

              <div className="row gutters-sm">
                <div className="col-md-4 d-none d-md-block">
                  <UserLeftMenu activetab={this.props.activetab}/>
                </div>
                <div className="col-md-8">
                <div className="card">
                <UserMobileMenu activetab={this.props.activetab}/>
                    <div className="card-body tab-content">
                      <div className="tab-pane active" id="profile">
                        <h6>MEZATLARIM</h6>
                        <hr />
                        {this.state.waitingAuctions.length>0 &&<div id="ProdListContainer01" className="col-md-12 mt-3 mb-3">
                          <div className="row rounded shadow-lg bg-white rounded my-auto" id="productlistcontents01" ref={this.myProductList}>
                          <div className="col-lg-12 text-left mt-2 mb-2"><h3>Onay Bekleyenler</h3></div>
                            {this.state.waitingAuctions && this.state.waitingAuctions.map((wa) => (
                                <div className="col-md-6 col-lg-6 col-xl-6 p-3">
                                <Product01 apiurl={this.props.apiurl} key={wa.id} productid={wa.id} productname={wa.name} productslug={wa.slug} salelabel={wa.sale_label} badgetype={wa.badgetype} seller={wa.seller} currentprice={wa.current_price} openingprice={wa.opening_price} buynowprice={wa.buynow_price} images={wa.auction_image} endtime={wa.end_time} starttime={wa.start_time} servertime={this.state.servertime} closed_at={wa.closed_at} />
                              </div>
                            ))
                            }
                            </div>
                         </div>}
                         {this.state.openAuctions.length>0 && <div id="ProdListContainer02" className="col-md-12 mt-3 mb-3">
                          <div className="row rounded shadow-lg bg-white rounded my-auto" id="productlistcontents02" ref={this.myProductList}>
                            <div className="col-lg-12 text-left mt-2 mb-2"><h3>Açık Mezatlar</h3></div>
                            {this.state.openAuctions && this.state.openAuctions.map((wa) => (
                                <div className="col-md-6 col-lg-6 col-xl-6 p-3">
                                <Product01  key={wa.id} apiurl={this.props.apiurl} productid={wa.id} productname={wa.name} productslug={wa.slug} salelabel={wa.sale_label} badgetype={wa.badgetype} seller={wa.seller} currentprice={wa.current_price} openingprice={wa.opening_price} buynowprice={wa.buynow_price} images={wa.auction_image} endtime={wa.end_time} starttime={wa.start_time} servertime={this.state.servertime} closed_at={wa.closed_at} />
                              </div>
                            ))
                            }
                            </div>
                         </div>}
                         {this.state.closedAuctions.length>0 && <div id="ProdListContainer03" className="col-md-12 mt-3 mb-3">
                          <div className="row rounded shadow-lg bg-white rounded my-auto" id="productlistcontents03" ref={this.myProductList}>
                            <div className="col-lg-12 text-left mt-2 mb-2"><h3>Bitmiş Mezatlarım</h3></div>
                            {this.state.closedAuctions && this.state.closedAuctions.map((wa) => (
                                <div className="col-md-6 col-lg-6 col-xl-6 p-3">
                                <Product01  key={wa.id} apiurl={this.props.apiurl} productid={wa.id} productname={wa.name} productslug={wa.slug} salelabel={wa.sale_label} badgetype={wa.badgetype} seller={wa.seller} currentprice={wa.current_price} openingprice={wa.opening_price} buynowprice={wa.buynow_price} images={wa.auction_image} endtime={wa.end_time} starttime={wa.start_time} servertime={this.state.servertime} closed_at={wa.closed_at} />
                              </div>
                            ))
                            }
                            </div>
                         </div>}
                         {this.state.deniedAuctions.length>0 && <div id="ProdListContainer04" className="col-md-12 mt-3 mb-3">
                          <div className="row rounded shadow-lg bg-white rounded my-auto" id="productlistcontents04" ref={this.myProductList}>
                            <div className="col-lg-12 text-left mt-2 mb-2"><h3>Reddedilenler</h3></div>
                            {this.state.deniedAuctions && this.state.deniedAuctions.map((wa) => (
                                <div className="col-md-6 col-lg-6 col-xl-6 p-3">
                                <Product01  key={wa.id} apiurl={this.props.apiurl} productid={wa.id} productname={wa.name} productslug={wa.slug} salelabel={wa.sale_label} badgetype={wa.badgetype} seller={wa.seller} currentprice={wa.current_price} openingprice={wa.opening_price} buynowprice={wa.buynow_price} images={wa.auction_image} endtime={wa.end_time} starttime={wa.start_time} servertime={this.state.servertime} closed_at={wa.closed_at} />
                              </div>
                            ))
                            }
                            </div>
                         </div>}
                      </div>              
                     </div>
                  </div>
                </div>
              </div>
            </div>
            <Banner placement='2' slug={this.props.slug} showModal={true} apiurl={this.props.apiurl}/>
          <FooterSection apiurl={this.props.apiurl}/>
        </div>
      )
    } else {
      return (!this.state.isLoading &&
        <div className="App">
          <Banner placement='0' slug={this.props.slug} apiurl={this.props.apiurl}/>
          <Header slug={this.props.slug} q={this.props.q} apiurl={this.props.apiurl} />
          <NotAuthorized />
          <Banner placement='2' slug={this.props.slug} showModal={true} apiurl={this.props.apiurl}/>
          <FooterSection apiurl={this.props.apiurl}/>
        </div>
      )
    }

  }

}


export default withCookies(MyAuctions);