import React, { Component } from 'react';
import Header from '../../parts/header/header';
import FooterSection from '../../parts/footer/footersection';
import Banner from '../../parts/header/banner';
import UserLeftMenu from './userleftmenu';
import NotAuthorized from '../notauthorized';
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";

import DateCombo from '../../components/datecombo/datecombo';
import ModalForm from '../../components/modal/modalform';
import UserMobileMenu from './usermobilemenu';
import sanitizeHtml from 'sanitize-html';
import swal from 'sweetalert2';

export class MyTickets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorDesc:'',
      isLoading: false,
      isAuthenticated: false,
      isOpen: false,
      isTicketOpen: false,
      activeIndex: 0,
      activeModal: null,
      err: false,
      tickets:[],
      menuItems: [],
      ticketTitle: '',
      ticketText: '',
      newTicketReply: "",
      btnReplyStatus: true,
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  
  openModal = () => this.setState({ isOpen: true });
  closeModal = () => this.setState({ isOpen: false, activeModal: null });
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };
  formatDate = (dateString) => {
    const options = { hour: '2-digit', minute: '2-digit', second: '2-digit' }
    return new Date(dateString).toLocaleDateString(undefined, options)
  }
  clickHandler(e, index) {
    e.preventDefault();
    this.setState({ isOpen: true, activeModal: index })
  }
  statusCodes = (scode) => {
    switch(scode) {
      case 0:
        return 'İşlem bekliyor';
      case 1:
        return 'Görüldü';
      case 2:
        return 'İnceleniyor';
      case 3:
        return 'Cevaplandi';
      case 4: 
        return 'Çözüldü';
      case 5:
        return 'Çözülemedi';
      default:
        return 'İşlem bekliyor';
    }      
  }
  componentDidMount() {
    this.setState({isLoading:true, isAuthenticated: false});
    const { cookies } = this.props;

    fetch(this.props.apiurl + '/api/showSupportTickets', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + cookies.get('access-token') ,
        },
        credentials: 'include'
      })
      .then((response) => {
        if(!response.ok) {
            return response.json();
          }
          else{
            return response.json();
          } 
      })
      .then((responseData) => {
        if (responseData.message==='Unauthenticated.') {
          this.setState({ isLoading: false, isAuthenticated:false })
        } else {
          if (responseData) {
            this.setState({ 
               isLoading: false, 
               isAuthenticated:true, 
               tickets: responseData.tickets.data,
               currentPage: responseData.tickets.current_page,
               lastPage: responseData.tickets.last_page,
               menuItems: responseData.support_titles,
               })
          }
        }
       })

      .catch((error) => {
        this.setState({errorDesc: error});
      });
  }
  handleActiveIndex(ind) {
    ind!==this.state.activeIndex ? this.setState({isTicketOpen: true}) : this.setState({isTicketOpen: !this.state.isTicketOpen}) ;
    this.setState({activeIndex: ind});
  }
  
handleSubmitReply() {
      this.setState({isOpen: false});
      const { cookies } = this.props;
      var retype = 'success';
      var formData = new FormData();
      formData.append('ticket_id', this.state.activeIndex);
      formData.append('ticket_text', this.state.newTicketReply);

      fetch(this.props.apiurl + '/api/addSupportTicketReply', {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + cookies.get('access-token'),
          },
          credentials: 'include',
          body: formData
      })
          .then((response) => {
              if (!response.ok) {
                  retype = 'error';
                  return response.json();
              }
              else {
                  retype = 'success';
                  return response.json();
              }
          })
          .then((responseData) => {
              if (responseData.message === 'Unauthenticated.') {
                  swal.fire({
                      icon: 'error',
                      text:  'Destek talebine yanıt vermek için üye girişi yapmalısınız',
                      timer: 7000,
                      showConfirmButton: false,
                      showCancelButton: false,
                      showCloseButton: true,
                      closeButtonCaption: 'Kapat'
                    });
                    this.setState({ isLoading: false, isAuthenticated:false })
              } else {
                  swal.fire({
                      icon: retype,
                      text:  responseData.message,
                      timer: 5000,
                      showConfirmButton: true,
                      confirmButtonText: '<i class="fa fa-thumbs-up"></i> Tamam!',
                      showCancelButton: false,
                      showCloseButton: true,
                      closeButtonCaption: 'Kapat'
                    });
                    this.setState({ 
                        isLoading: false, 
                        isAuthenticated:true, 
                        tickets: responseData.tickets.data,
                        currentPage: responseData.tickets.current_page,
                        lastPage: responseData.tickets.last_page,
                        menuItems: responseData.support_titles,
                        })
              }
          })

          .catch((error) => {
              swal.fire({
                  icon: 'error',
                  text:  'Bir hata oluştu. ' + error,
                  timer: 7000,
                  showConfirmButton: false,
                  showCancelButton: false,
                  showCloseButton: true,
                  closeButtonCaption: 'Kapat'
                });
          });
  }
  handleSubmit() {
      this.setState({isOpen: false});
      const { cookies } = this.props;
      var retype = 'success';
      var formData = new FormData();
      formData.append('ticket_title', this.state.ticketTitle);
      formData.append('ticket_text', this.state.ticketText);

      fetch(this.props.apiurl + '/api/addSupportTicket', {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + cookies.get('access-token'),
          },
          credentials: 'include',
          body: formData
      })
          .then((response) => {
              if (!response.ok) {
                  retype = 'error';
                  return response.json();
              }
              else {
                  retype = 'success';
                  return response.json();
              }
          })
          .then((responseData) => {
              if (responseData.message === 'Unauthenticated.') {
                  swal.fire({
                      icon: 'error',
                      text:  'Destek talebinde bulunmak için üye girişi yapmalısınız',
                      timer: 7000,
                      showConfirmButton: false,
                      showCancelButton: false,
                      showCloseButton: true,
                      closeButtonCaption: 'Kapat'
                    });
              } else {
                  swal.fire({
                      icon: retype,
                      text:  responseData.message,
                      timer: 5000,
                      showConfirmButton: true,
                      confirmButtonText: '<i class="fa fa-thumbs-up"></i> Tamam!',
                      showCancelButton: false,
                      showCloseButton: true,
                      closeButtonCaption: 'Kapat'
                    }).then(function() {
                      window.location = "/u/destektaleplerim";
                  });
              }
          })

          .catch((error) => {
              swal.fire({
                  icon: 'error',
                  text:  'Bir hata oluştu. ' + error,
                  timer: 7000,
                  showConfirmButton: false,
                  showCancelButton: false,
                  showCloseButton: true,
                  closeButtonCaption: 'Kapat'
                });
          });
  }
  handleChange = (event) => {
    this.setState({[event.target.name]: event.target.value});
  }
  handleChangeTextArea = (event) => {
    if (event.target.value==="") {  
      this.setState({newTicketReply: "", btnReplyStatus: true});
    } else {
      this.setState({newTicketReply: event.target.value, btnReplyStatus: false});
    }
  }
 
  render() {
    document.title = "Mezatvar - Destek Taleplerim"
    if (this.state.isAuthenticated === true) {
      return (
        !this.state.isLoading &&
        <div className="App">
          <Banner placement='0' slug={this.props.slug} apiurl={this.props.apiurl}/>
          <Header slug={this.props.slug} q={this.props.q} apiurl={this.props.apiurl} />
          

            <div className="container bg-white shadow-lg mb-4 mt-4 pt-3 pb-3 rounded text-left">

              <nav aria-label="breadcrumb" className="main-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="/">Anasayfa</a></li>
                  <li className="breadcrumb-item active" aria-current="page">Destek Taleplerim</li>
                </ol>
              </nav>

              <div className="row gutters-sm">
                <div className="col-md-4 d-none d-md-block">
                  <UserLeftMenu activetab={this.props.activetab}/>
                </div>
                <div className="col-md-8">
                <div className="card">
                <UserMobileMenu activetab={this.props.activetab}/>
                    <div className="card-body tab-content">
                      <div className="tab-pane active" id="profile">
                        <h6>DESTEK TALEPLERİM</h6>
                        <hr />
                        <div id="ProdListContainer01" className="col-md-12 mt-3 mb-3">
                        <div className="col-md-12">
                                {this.state.tickets.length==0 && <div className="justify-content-center text-center">
                                  <h1><i className="text-warning fas fa-life-ring"></i></h1>
                                  <h2>Herhangi bir destek talebiniz bulunmamaktadır</h2>
                                </div>}
                                {this.state.tickets && this.state.tickets.map((ticket) => {
                                  return <div className="card mb-1 question">
                                          <div className="card-header text-primary font-weight-bold" id={ticket.id}   onClick={() => this.handleActiveIndex(ticket.id)}>
                                                  <span>{ticket.ticket_title} ({this.statusCodes(ticket.ticket_status)})</span><span className="float-right">{this.formatDate(ticket.created_at)} {(this.state.activeIndex==ticket.id && this.state.isTicketOpen) ? "-": "+"}</span> 
                                          </div>
                                          {(this.state.activeIndex==ticket.id && this.state.isTicketOpen) && <div id={"description" + ticket.id} 
                                              className="collapse show">
                                              <div className="card-body">
                                                  
                                                  <div className="row">
                                                  <div className="col-md-6">
                                                  </div>
                                                  <div className="col-md-12 alert alert-warning mb-4"   role="alert" dangerouslySetInnerHTML={{__html: sanitizeHtml('<strong>' +  ticket.ticket_user_title + ': </strong>' + ticket.ticket_body + '<span className=\'float-right\'>' + this.formatDate(ticket.created_at) + '</span>')}}>
                                                  </div>
                                                  </div>
                                                  {ticket.support_ticket_replies && ticket.support_ticket_replies.map((reply => 
                                                  <>
                                                    <div className="row">
                                                      <div className="col-md-1"></div>
                                                      <div className="col-md-11 alert alert-primary mb-4"> <strong>{reply.ticket_reply_user_title}: </strong> {reply.ticket_reply_body} <span className='float-right'> {this.formatDate(reply.created_at)}</span></div>
                                                    </div>

                                                  </>
                                                  ))}
                                              </div>
                                              <div className="row  align-middle">
                                                      <div className="col-md-1"></div>
                                                      <div className="col-md-9  mb-4"><textarea name="newTicketReply" className="form-input col-md-12" onChange={this.handleChangeTextArea} ></textarea></div>
                                                      <div className="col-md-2 float-right"><button className="btn btn-small btn-green" disabled={this.state.btnReplyStatus} onClick={e => this.handleSubmitReply()}>Yanıtla</button></div>
                                               </div>
                                          </div>
                                          }
                                      </div>
                                  })}
                                                                  <div className="mt-2 float-right">
                                <button onClick={e => this.clickHandler(e, 0)} className="btn btn-green">Destek Talebinde Bulun</button>
                                </div>
                                </div>

                         </div>
                         {this.state.activeModal === 0 && this.state.isOpen ?
                                    <ModalForm
                                        activeModal={this.activeModal}
                                        closeModal={this.closeModal}
                                        isOpen={this.state.isOpen}
                                        onHide={this.closeModal}
                                        handleSubmit={this.handleSubmit}
                                        aria-labelledby="contained-modal-title-vcenter"
                                        title="Destek Talebi"
                                        saveButtonCaption="Gönder"
                                        closeButtonCaption='İptal'
                                    >
                                        <select className="form-control mb-1"
                                            type="text"
                                            name="ticketTitle"
                                            id="ticketTitle"
                                            placeholder="Destek talebinizin konusu"
                                            onChange={this.handleChange} 
                                        >
                                            {this.state.menuItems && this.state.menuItems.map(menuItem => <option selected={this.state.selectedTitle === menuItem.slug} value={menuItem.title}>{menuItem.title}</option>)}
                                            <option value="Diğer">Diğer</option>
                                        </select>
                                        <p><textarea rows="6" name="ticketText" placeholder="Talebinizi buraya yazın" className="form-control col-md-12" onChange={this.handleChange}/></p>
                                    </ModalForm>
                                    :
                                    null
                                }
                         
                         
                      </div>              
                     </div>
                  </div>
                </div>
              </div>
            </div>
            <Banner placement='2' slug={this.props.slug} showModal={true} apiurl={this.props.apiurl}/>
          <FooterSection apiurl={this.props.apiurl}/>
        </div>
      )
    } else {
      return (!this.state.isLoading &&
        <div className="App">
          <Banner placement='0' slug={this.props.slug} apiurl={this.props.apiurl}/>
          <Header slug={this.props.slug} q={this.props.q} apiurl={this.props.apiurl} />
          <NotAuthorized />
          <Banner placement='2' slug={this.props.slug} showModal={true} apiurl={this.props.apiurl}/>
          <FooterSection apiurl={this.props.apiurl}/>
        </div>
      )
    }

  }

}


export default withCookies(MyTickets);