import React, { Component } from 'react';
import Header from '../parts/header/header';
import FooterSection from '../parts/footer/footersection';
import Banner from '../parts/header/banner';
import SideMenu from '../components/sidemenu/sidemenu';
import ModalForm from '../components/modal/modalform';
import Slider from "../components/slider/Slider.js";
import ShowMoreText from "react-show-more-text";
import sanitizeHtml from 'sanitize-html';
import ReactPlayer from 'react-player';
import '../assets/css/blog.css';
import swal from 'sweetalert2';

//{this.props.video!=null && ReactPlayer.canPlay(this.props.video) && <ReactPlayer className='col-12 mt-2' url={this.props.video} />}
// {this.state.pImages.length > 0 && <Slider imageList={this.state.pImages} containerClassName="container-slider rounded-lg" withThumbs={true} withDots={true} apiurl={this.props.apiurl} zoom={true} />}
export class Blog extends Component {
    constructor(props) {
        super(props);
        this.handleActiveIndex = this.handleActiveIndex.bind(this);
    }
   state = { 
        url: this.props.apiurl + '/api/blog',
        selectedCategory: this.props.activetab,
        selectedTitle: this.props.anchorlink,
        pageTitle: "Mezatvar - Blog",
        menuItems: [],
        latestItems: [],
        blog_list: [],
        blog_data: [],
        isOpen: false,
        activeIndex: 0,
        activeModal: null,
        questionImages: [],
    }
    capitalizeFirst = str => {
        return str.charAt(0).toUpperCase() + str.slice(1);
      };
    formatDate = (dateString) => {
        const options = { month: 'short',
        day: '2-digit',
        year: 'numeric'}
        return new Date(dateString).toLocaleDateString(undefined, options)
    };
    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false, activeModal: null });
    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    }
    clickHandler(e, index) {
        e.preventDefault();
        this.setState({ isOpen: true, activeModal: index })
    }
    handleActiveIndex(ind) {
        //alert("yeni index: " + ind + " eski index: "  +  this.state.activeIndex + "-->" + this.state.isOpen);
        ind!==this.state.activeIndex ? this.setState({isOpen: true}) : this.setState({isOpen: !this.state.isOpen}) ;
        this.setState({activeIndex: ind});
    }
    componentDidMount() {
        this.getData();
    }
    getData() {
        const { cookies } = this.props;
        var apiUrl = this.state.selectedCategory ? this.state.url + "/" + this.state.selectedCategory :  this.state.url;
        apiUrl = this.state.selectedTitle ?  apiUrl + "/" + this.state.selectedTitle : apiUrl; 
                
        fetch(apiUrl, {
          method: 'GET',
          headers: {
            'Accept': 'application/json'            
          },
          credentials: 'include'
        })
          .then(res => res.json())
          .then((data) => {
            data.blog_list && this.setState({ menuItems: data.blog_categories, latestItems: data.latest_items, blog_list: data.blog_list, blog_data: data.blog_data, selectedCategory: data.selected_category})
            this.props.anchorlink && document.getElementById(this.props.anchorlink).scrollIntoView();
            data.selected_category  && data.selected_category && this.setState({pageTitle: "Mezatvar - Blog - " + this.capitalizeFirst(data.selected_category)})
          })
    
          .catch(err => {
            console.log(err)
    
            //this.myToast("Teknik bir problemimiz var.<br/> Menü oluşturulamadı.<br/> Problem detayı:" + err);
          })
      }
    render() {
        document.title = this.state.pageTitle;
        return (
            <div className="App">
            <Banner placement='0' slug={this.props.slug} apiurl={this.props.apiurl}/>
            <Header slug={this.props.slug} q={this.props.q} apiurl={this.props.apiurl}/>
            <div className="container bg-white shadow-lg mb-4 mt-4 pt-3 pb-3 rounded text-left">
            <nav aria-label="breadcrumb" className="main-breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a href="/">Anasayfa</a></li>
                  <li className="breadcrumb-item" aria-current="page"><a href="/blog">Blog</a></li>
                  {this.state.selectedCategory && <li className="breadcrumb-item" aria-current="page"><a href={'/blog/' + this.state.selectedCategory} >{this.capitalizeFirst(this.state.selectedCategory)}</a></li>}
                  {this.state.selectedTitle && <li className="breadcrumb-item" aria-current="page">{this.capitalizeFirst(this.state.selectedTitle)}</li>}
                </ol>
              </nav>
                <Banner placement='1' slug={this.props.slug} apiurl={this.props.apiurl}/>
                <div className="row blog">
                    <div className="col-md-4 d-none d-md-block">

                        <div className="row-md-12">
                        <SideMenu activetab={this.state.selectedCategory} mainDirectory={"blog"} menuItems={this.state.menuItems} title="Kategoriler"/>
                        </div>
                        <div className="row-md-12 mt-3">
                        <SideMenu activetab={this.state.selectedBlog} mainDirectory={"blog/" + this.state.selectedCategory} menuItems={this.state.latestItems} title="En Yeni Makaleler"/>
                            
                        </div>
                    </div>
                    <div className="col-md-8">
                    
                    {this.state.blog_data && this.state.blog_data.map((blogData) => {
                        return <div className="row align-items-center blog-block no-gutters m-2 p-3 clearfix">
                        <div>
                            <div className="blog-date p-3 m-5">
                                <span>{this.formatDate(blogData.created_at)}</span>
                            </div>
                            
                            <img src={blogData.page_image} className="w-100" />

                            <div className="blog-title mx-auto d-block font-bold p-2">{blogData.page_title} </div>

                        </div>
                        </div>
                    })}
                    {this.state.blog_data && this.state.blog_data.map((blogData) => {
                        return blogData.blog_page_sections && blogData.blog_page_sections.map((sect) => {
                            return <>
                            <div className="row align-items-center no-gutters m-2 p-3">
                                <div className='col-md-12'>
                                {sect.section_image && <img src={sect.section_image} className="img  mx-auto d-block pt-4 pb-4"/>}
                                
                                <div dangerouslySetInnerHTML={{__html: sanitizeHtml(sect.section_content)}}>
                                </div>
                                </div>
                            </div>
                            </>
                        })
                    })}
                    <div className="custombox">
                    <h4 className="small-title">Beğenebileceğiniz Makaleler</h4>
                    {this.state.blog_list && this.state.blog_list.map((individualData) => {
                       return individualData.blog_pages && individualData.blog_pages.map((page) => {
                        return <div className="row align-items-center blog-block no-gutters m-2 p-3">
                                <div className="col-lg-5 col-sm-12 ">
                                    <div className="position-relative">
                                    <a href={"blog/"+ this.state.selectedCategory + "/" + page.page_slug}>
                                    {page.page_image &&  <img src={page.page_image} alt={page.page_title}/>}
                                    
                                    {!page.page_image &&  <img src="./assets/images/logo.png" alt={page.page_title}/>}
                                    </a>
                                    </div>
                                </div>
                                <div className="col-lg-7 col-sm-12">
                                    <div className="padding-60px-lr md-padding-50px-lr sm-padding-30px-all xs-padding-25px-all">
                                        <h5 className="margin-15px-bottom md-margin-10px-bottom font-size22 md-font-size20 xs-font-size18 font-weight-500"><a href={"blog/"+ this.state.selectedCategory + "/" + page.page_slug}>{page.page_title}</a></h5>
                                        <p>{page.page_description}</p>
                                        <a className="btn btn-sm btn-primary float-right" href={"blog/"+ this.state.selectedCategory + "/" + page.page_slug}>Devamını Oku<i className="fas fa-long-arrow-alt-right margin-10px-left"></i></a>
                                    </div>
                                </div>
                            </div>
                        
                       
                        } ) 
                    }
                    
                    )}
                    </div>
                </div>
                </div>
        </div>
        
        <Banner placement='2' slug={this.props.slug} showModal={true} apiurl={this.props.apiurl}/>
        <FooterSection apiurl={this.props.apiurl} />
        </div>
        )
    }
}

export default Blog;