import React, { Component, memo} from 'react';
import SwalBanner from '../../components/swalbanner';

//import base64image from '../../components/base64image';

export class Banner extends Component {
  constructor(props) {
    super(props);
    this.clickHandler = this.clickHandler.bind(this);
    this.state = {
      errorDesc:'',
      //modal: false,
      banners: []
      }
  }

  clickHandler(e) {
    e.preventDefault();

    var formData = new FormData()
    formData.append('banner_id',  e.currentTarget.id)
    
    fetch(this.props.apiurl + '/api/bannerclick', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      credentials: 'include',
      body: formData
    })
    .then((response) => {
      if(!response.ok) {
          return response.json();
        }
        else{
          return response.json();
        } 
    })
    .catch((error) => {
      this.setState({errorDesc: error});
    });
    window.location = e.currentTarget.href;
  }

    componentDidMount() {
        //this.setState({ modal: false });
        let bannerurl = '';
        (this.props.slug!=null)? bannerurl = this.props.placement + '/' + this.props.slug: bannerurl = this.props.placement;
      
        fetch(this.props.apiurl + '/api/banners/' + bannerurl, {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
            },
            credentials: 'include'
          })
          .then((response) => {
            if(!response.ok) {
                return response.json();
              }
              else{
                return response.json();
              } 
          })
          .then((responseData) => {
              if (responseData) {
                this.setState({ banners: responseData})
              }
           })
    
          .catch((error) => {
            this.setState({errorDesc: error});
          });
      }
    //modalClose = () => {
        //this.setState({ modal: false })
    //}
    render() {
        return (
            <div className="d-print-none">

                {this.state.banners && this.state.banners.map((banner) =>{   
                    return (
                    <React.Fragment key={"banner" + banner.id}>
                    {banner.banner_placement<5 &&
                    <div className="row-md-12" style={{backgroundColor: banner.banner_bgcolor}}>
                    <a href={banner.banner_link} id={banner.id} title={banner.banner_title} target={banner.banner_target} onClick={this.clickHandler}>
                      
                      <picture>
                      <source media="(min-width:650px)" srcSet={banner.banner_image} />
                      <source media="(min-width:320px)" srcSet={banner.banner_image_mobile} />
                      <img className="img" src={banner.banner_image} alt={banner.banner_title}/>
                      </picture>
                   
                    </a>
                    </div>
                    }
                    {banner.banner_placement==5 &&
                    <SwalBanner banner_link={banner.banner_link} banner_image={banner.banner_image} banner_image_mobile={banner.banner_image_mobile} banner_title={banner.banner_title} banner_target={banner.banner_target} />
                    }
                    </React.Fragment>
                    )
                } )}
               
            </div>
        )
    }
}

export default memo(Banner);