import React, {Component} from 'react';
import Header from '../../parts/header/header';
import FooterSection from '../../parts/footer/footersection';
import Banner from '../../parts/header/banner';
import DeliveryDetails from './deliverydetails';
import NotAuthorized from '../notauthorized';
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";

export class Delivery extends Component {

    state = {
        errorDesc:'',
        isLoading: false,
        isAuthenticated: false,
        err: false,
        cartDetails:[]
      }
      static propTypes = {
        cookies: instanceOf(Cookies).isRequired
      };

      componentDidMount() {
        this.setState({isLoading:true, isAuthenticated: false});
        const { cookies } = this.props;
    
        fetch(this.props.apiurl + '/api/showCart', {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + cookies.get('access-token') ,
            },
            credentials: 'include'
          })
          .then((response) => {
            if(!response.ok) {
                return response.json();
              }
              else{
                return response.json();
              } 
          })
          .then((responseData) => {
            if (responseData.message==='Unauthenticated.') {
              this.setState({ isLoading: false, isAuthenticated:false })
            } else {
                this.setState({ cartDetails: responseData.data, cargoPrices: responseData.cargo_prices, isLoading: false, isAuthenticated:true })
            }
           })
    
          .catch((error) => {
            this.setState({errorDesc: error});
          });
      }

    render() {
        document.title="Mezatvar - Teslimat Bilgileri"
        
        if (this.state.isAuthenticated===true) {
          return (
            !this.state.isLoading && 
            <div className="App">
              <Banner placement='0' slug={this.props.slug} apiurl={this.props.apiurl}/>
              <Header slug={this.props.slug} q={this.props.q} apiurl={this.props.apiurl}/>
              <DeliveryDetails veri={this.state.cartDetails} apiurl={this.props.apiurl}></DeliveryDetails>
              <Banner placement='2' slug={this.props.slug} showModal={true} apiurl={this.props.apiurl}/>
              <FooterSection apiurl={this.props.apiurl} />
            </div>
          )
        } else {
          return (!this.state.isLoading &&
            <div className="App">
                <Banner placement='0' slug={this.props.slug} apiurl={this.props.apiurl} />
                <Header slug={this.props.slug} q={this.props.q} apiurl={this.props.apiurl}/>
                <NotAuthorized/>
                <Banner placement='2' slug={this.props.slug} showModal={true} apiurl={this.props.apiurl}/>
                <FooterSection apiurl={this.props.apiurl}/>
            </div>
          ) 
        }
        
    }
}

export default withCookies(Delivery);