import React, { Component } from 'react';
import CartEmpty from '../cart/cartempty';
import '../../assets/css/payment.css';
import Stepper from '../../components/stepper/stepper.js';
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";

export class PaymentResultDetails extends Component {
    constructor(props) {
        super(props);
    }
    state = {
        isLoading: false,
        isOpen: false,
        activeModal: null,
        status: null,
        threedstatus: null,
        mdstatus: null,
        statusText: '',
        errorCode: null,
        errorMessage: null,
        bgClassName: '',
    }

    numberFormat = (value) => {
        if (value > 0) {
            return Intl.NumberFormat(['ban', 'id']).format(value)
        } else {
            return 0;
        }
    }

    clickHandler(e, index, id) {
        e.preventDefault();
        if (index > 1) {
            this.setState({ activePay: index })
        }
        this.setState({ isOpen: true, activeModal: index, active_id: id })
    }
    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false, activeModal: null });
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    
    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
      }

    componentDidMount() {
        if (this.props.veri !== null) {
           this.props.veri && this.props.veri.map((per) => {
                (
                    this.setState({
                        errorCode: per.error_code,
                        errorMessage: per.error_message,
                        status: per.status,
                        threedstatus: per.threedstatus,
                        mdstatus: per.mdstatus,
                        bgClassName: (per.status=='success' ? 'text-success' : 'text-danger'),
                        statusText: (per.status=='success' ? 'Ödemeniz Alındı' :'Ödeme İşlemi Başarısız')
                    })
                )
            }
            )
        }
          
    }


    render() {
             return (
                <div className="container bg-white shadow-lg mb-4 mt-4 rounded text-center">
                                      
                    <div className="row">
                        <div className="col-md-12">

                        <div className="container px-1 px-md-2 px-lg-4 py-5 mx-auto">
                        <h3 className="mb-4">Ödeme Sonucu</h3>
                            <div className="row d-flex">
                                <div className="col-xl-6 col-lg-7 col-md-8 col-sm-11">
                                    <div className={this.state.bgClassName}>
                                    {this.state.status=='success' ?
                                    <i class="fa fa-2x fa-thumbs-up"></i>:<i class="fa fa-2x fa-thumbs-down"></i>}    
                                    </div>
                                    <div>{this.state.statusText}</div>
                                    {this.state.errorCode && <div>{this.state.errorCode}</div>}
                                    {this.state.errorMessage && <div>{this.state.errorMessage}</div>}
                                    
                                </div>
                            </div>
                        </div>

                        </div>
                        
                    </div>
                   
                </div>

            )
    }
}

export default withCookies(PaymentResultDetails);