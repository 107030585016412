import React, { Component } from 'react';

//import '../assets/css/paymentinstallments.css';
import swal from 'sweetalert2';
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";

export class IyzicoInstallments extends Component {
    constructor(props) {
        super(props);
        this.getInstallments = this.getInstallments.bind(this);
    }
    state = {
        returnedJson: '',
        installmentDetails: []
    }

    numberFormat = (value) => {
        if (value > 0) {
            return Intl.NumberFormat(['ban', 'id']).format(value)
        } else {
            return 0;
        }
    }


    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    
    
    componentDidMount() {
        //if (this.props.veri !== null) {
            this.getInstallments();
        //}     
    }
    
    getInstallments() {
        const { cookies } = this.props;
        var retype = 'success';
        var formData = new FormData();
        formData.append('price', this.props.cart_total_buyer);
        if (this.props.binNo!=='') {
            formData.append('binno', this.props.binNo);
        }


        fetch(this.props.apiurl + '/api/getInstallments', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + cookies.get('access-token'),
            },
            credentials: 'include',
            body: formData
        })
            .then((response) => {
                if (!response.ok) {
                    retype = 'error';
                    return response.json();
                }
                else {
                    retype = 'success';
                    return response.json();
                }
            })
            .then((responseData) => {
                if (responseData.message === 'Unauthenticated.') {
                    swal.fire({
                        icon: "error",
                        text: 'Ödeme verilerine ulaşmak için üye girişi yapmalısınız',
                        timer: 5000,
                        showConfirmButton: false,
                        showCancelButton: false,
                        showCloseButton: true,
                        closeButtonCaption: 'Kapat'
                      });
                } else {
                    if(responseData.status==="failure") {
                        retype = 'error';
                        swal.fire({
                            icon: retype,
                            text: responseData.errorCode + ' ' +  responseData.errorMessage,
                            timer: 5000,
                            showConfirmButton: false,
                            showCancelButton: false,
                            showCloseButton: true,
                            closeButtonCaption: 'Kapat'
                          });
                    } else {
                        this.setState({installmentDetails: responseData.installmentDetails});
                    }
                    
                    //window.location = 'sepetim';
                }
            })
            .catch((error) => {
                this.setState({returnedJson: error});
            });
    }


    render() {

            return (
                <div className="bg-white text-left smalltext">
                    <div className="col-12 d-flex justify-content-between flex-wrap">
                    
                    {this.state.installmentDetails && this.state.installmentDetails.map((bank)=> (
                    
                    <div className="card h-100 my-auto col-md-4 mx-auto p-2">
                    {bank.installmentPrices.length>1 && <img src={'./assets/images/bankalar/' + bank.bankName.toString().replace(" ", "_").replace('ş', 's').replace('ı', 'i').replace('İ', 'i').replace('Ş', 'S').toLowerCase() + '.png'} className="bankalogo"/>}
                    {bank.installmentPrices.length>1 && <div className="btn-group-sm justify-content-center" role="group">
                    <div className="justify-content-center">
                        <div className="row justify-content-center"><strong>{bank.cardFamilyName}</strong></div>
                        <div className="row p-0">
                        <div className="col-4"></div>
                        <div className="col-4">Tutar</div>
                        <div className="col-4">Toplam</div></div>
                        
                        {bank.installmentPrices.length>1 && bank.installmentPrices.map((taksit) => (
                            <div className="row">
                                <div className="col-4"><strong>{this.state.installmentDetails.length<=1 ? <input type="radio" className="form-check-input" onChange={e => this.props.onChange(taksit.installmentNumber, taksit.totalPrice)} name="installment_selected" value={taksit.installmentNumber}/>:null}{(taksit.installmentNumber>1) ? taksit.installmentNumber + " taksit": "Tek çekim"}</strong></div><div className="col-4">{taksit.installmentPrice} ₺</div><div className="col-4">{taksit.totalPrice} ₺</div>
                            </div>

                        ))}
                    </div>
                    </div>
                    }
                    
                    </div>))
                    
                    }
                    
                    
                    
                    </div>
                </div>

            )
    }

}

export default withCookies(IyzicoInstallments);