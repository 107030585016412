import React, { Component } from 'react';
import { urltemizle } from '../../components/utils';
import CartEmpty from './cartempty';
import ModalForm from '../../components/modal/modalform';
import sanitizeHtml from 'sanitize-html';
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import Video from '../../parts/video';
import swal from 'sweetalert2';
import CargoBarcode from '../../pages/cargobarcode';
import Product01 from '../../parts/product01';

export class CartDetails extends Component {
    constructor(props) {
        super(props);
        this.handleDelete = this.handleDelete.bind(this);
    }
    state = {
        isLoading: false,
        isOpen: false,
        activeModal: null,
        cart_id: '',
        cart_total_buyer: '',
        cart_items: [],
        active_id: '',
        cart_notification: '',
    }

    numberFormat = (value) => {
        if (value > 0) {
            return Intl.NumberFormat(['ban', 'id']).format(value)
        } else {
            return 0;
        }
    }

    showCargoFirm(e) {
        e.preventDefault();
        this.setState({ isOpen: true, activeModal: 1 })
    }
    yurticiSec(e) {
        e.preventDefault();
        this.setState({ isOpen: true, activeModal: 1, channel: 'yurtici'})

    }

    mngSec(e){
        e.preventDefault();
        this.setState({ isOpen: true, activeModal: 2, channel: 'mng'})
    }

    clickHandler(e, index, id) {
        e.preventDefault();
        this.setState({ isOpen: true, activeModal: index, active_id: id })
    }
    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false, activeModal: null });
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    handleSubmitMember = (name) => {
        this.setState({ isOpen: false });
        document.getElementById("reg_accept_contract").checked = true;
      }
    handleSubmit = () => {
        if (document.getElementById("reg_accept_contract").checked === true) 
            {
                window.location="/teslimat";
            } else {
                swal.fire({
                    icon: 'warning',
                    title: 'Uyarı...',
                    text: 'Ödeme aşamasına geçebilmeniz mesafeli satış sözleşmesini kabul etmeniz gerekiyor',
                    timer: 5000,
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                    closeButtonCaption: 'Kapat'
                  });
            }
    }
    componentDidMount() {
            this.props.veri &&  this.setState({
                        cart_id: this.props.veri.id,
                        cart_total_buyer: this.props.veri.cart_total_buyer,
                        cart_items: this.props.veri.cart_item,
                        items_total: this.props.veri.items_total,
                        commissions_total_buyer: this.props.veri.commissions_total_buyer,
                        shipping_price_total_buyer: this.props.veri.shipping_price_total_buyer,
                        cart_notification: this.props.veri.cart_notification
                    })
    }
    handleDelete() {
        this.setState({ isOpen: false });
        const { cookies } = this.props;
        var retype = 'success';
        var formData = new FormData();
        formData.append('id', this.state.active_id);

        fetch(this.props.apiurl + '/api/deleteCartItem', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + cookies.get('access-token'),
            },
            credentials: 'include',
            body: formData
        })
            .then((response) => {
                if (!response.ok) {
                    retype = 'error';
                    return response.json();
                }
                else {
                    retype = 'success';
                    return response.json();
                }
            })
            .then((responseData) => {
                if (responseData.message === 'Unauthenticated.') {
                    swal.fire({icon: "error",
                    text: 'Sepetten ürün silebilmek için üye girişi yapmalısınız',
                    timer: 5000,
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                    closeButtonCaption: 'Kapat'
                    });
                } else {
                    swal.fire({icon: retype,
                    text: responseData.message,
                    timer: 5000,
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                    closeButtonCaption: 'Kapat'
                    });
                    window.location = 'sepetim';
                }
            })

            .catch((error) => {
                swal.fire({
                    icon: 'error',
                    text:  'Bir hata oluştu. ' + error,
                    timer: 7000,
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                    closeButtonCaption: 'Kapat'
                  });
            });
    }


    render() {
        if (this.state.cart_items.length === 0) {
            return <CartEmpty apiurl={this.props.apiurl} showcases={this.props.showcases} watchListAuctions={this.props.watchListAuctions}  servertime={this.props.servertime} notification={this.state.cart_notification} slug={this.props.slug}/>
        } else {
            return (
                <div className="container bg-white shadow-lg mb-4 mt-4 rounded text-left">

                    <div className="row">
                        <div className="col-md-9">
                            <div className="ibox">
                                <div className="ibox-title">
                                    <span className="float-right">(<strong>{this.state.cart_items.length}</strong>) Ürün</span>
                                    <h5>Sepetinizdeki Ürünler</h5>
                                </div>
                                {this.state.cart_notification && <div className="alert alert-warning" role="alert">{this.state.cart_notification}</div>}
                                {this.state.cart_items && this.state.cart_items.map((item) =>

                                    <div className="ibox-content p-4">
                                        <div>
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <div className="cart-product-imitation">
                                                        <a href={urltemizle("mezat/" + item.slug)} data-tip="İncele">
                                                            
                                                                    <div style={{ 
                                                                    backgroundImage: `url('${this.props.apiurl + item.public_url}')`,
                                                                    backgroundRepeat: 'no-repeat',
                                                                    width:'100px',
                                                                    height: '80px',
                                                                    backGroundSize: 'contain'
                                                                    }}> <img src={this.props.apiurl + item.public_url} width="100px" height="80px"/>

                                                                        </div>             
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-md-7 desc">
                                                    <h3>
                                                        <a href={urltemizle("mezat/" + item.slug)} className="text-navy">
                                                            {item.name}
                                                        </a>
                                                    </h3>
                                                    <p className="small limitedtext" dangerouslySetInnerHTML={{__html: sanitizeHtml(item.description)}}>
                                                        
                                                    </p>

                                                </div>
                                                                                                
                                                <div className="col-md-3 align-right">
                                                    <h4>
                                                        {this.numberFormat(item.item_price)} ₺
                                                    
                                                    </h4>
                                                    {item.shipping_type==1 && <p className="text-small text-muted">{item.item_shipping_price_buyer==0 ? "Kargo Bedava": item.cargo_channel_title + " kargo ile " + this.numberFormat(item.item_shipping_price_buyer) + " ₺ kargo ücreti"}</p>}
                                                    {item.shipping_type==1 && item.item_shipping_price_buyer!=0 ? <button className="btn btn-sm btn-green" onClick={e => this.showCargoFirm(e)} >Kargo firması seçenekleri</button>: null}
                                                    {item.shipping_type==2 && <p className="text-small text-muted">{item.item_shipping_price_buyer==0 ? "Nakliye Ücretsiz": this.numberFormat(item.item_shipping_price_buyer) + " ₺ nakliye bedeli"}</p>}
                                                    {item.from_bn_or_auc === 1 ? <p className="pt-2 d-flex justify-content-around"><button onClick={e => this.clickHandler(e, 3, item.id)} className="btn btn-sm btn-outline-danger"><i className="fa fa-trash"></i> Ürünü Sil</button></p> : ''}
                                                </div>
                                            </div>
                                            <div className="m-t-sm float-right">

                                                

                                            </div>
                                        </div>
                                        
                                    </div>
                                    
                                )}

                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="ibox">
                                <div className="ibox-title text-left">
                                    <h5>Sepet Özeti</h5>
                                </div>
                                <div className="ibox-content text-center">
                                    <span>
                                        Ödenecek Tutar
                                    </span>
                                    <h2 className="font-bold text-center">
                                        {this.numberFormat(this.state.cart_total_buyer)} ₺
                                    </h2>
                                    <hr />
                                    <div className="text-dark small">
                                        <div className="row">
                                            <div className="col-md-5 text-left">Ürün toplamı</div>
                                            <div className="col-md-1">:</div>
                                            <div className="col-md-5 text-right">{this.numberFormat(this.state.items_total)} ₺</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-5 text-left">Hizmet bedeli</div>
                                            <div className="col-md-1">:</div>
                                            <div className="col-md-5 text-right">{this.numberFormat(this.state.commissions_total_buyer)} ₺</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-5 text-left">Kargo bedeli</div>
                                            <div className="col-md-1">:</div>
                                            <div className="col-md-5 text-right">{this.numberFormat(this.state.shipping_price_total_buyer)} ₺</div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="form-check smalltext">
                                        <input type="checkbox" id="reg_accept_contract" name="reg_accept_contract" className="form-check-input" value="2" />
                                        <label className="form-check-label" for="reg_accept_contract"><a href="#" onClick={e => this.clickHandler(e, 0, 0)}>Mesafeli Satış Sözleşmesini</a> okudum ve kabul ediyorum</label>
                                    </div>
                                    <hr />
                                    
                                    <div className="m-t-sm  text-center">
                                        <div className="btn-group">
                                            <button onClick={this.handleSubmit} className="btn btn-blue btn-sm"><i className="fa fa-credit-card"></i> Ödeme Aşamasına Geç</button>
                                        </div>
                                    </div>
                                    
                                  
                                </div>
                            </div>

                            <div className="ibox">
                                <div className="ibox-title">
                                    <h5>Destek</h5>
                                </div>
                                <div className="ibox-content text-center">
                                    <h3><i className="fa fa-phone"></i> <a href="tel:0850 840 44 94">0850 840 44 94</a></h3>
                                    <span className="small">
                                        Aklınıza takılan tüm sorular için 24 saat hizmetinizdeyiz
                                    </span>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 p-4">
                            <div className="col-sm-6 float-left">
                                <a href="/" className="btn btn-white"><i className="fa fa-arrow-left"></i> Alışverişe Devam</a>
                            </div>
                            <div className="col-sm-6 float-right">
                                <button onClick={this.handleSubmit} className="btn btn-blue pull-right"><i className="fa fa fa-credit-card"></i> Ödeme Aşamasına Geç <i className="fa fa-arrow-right"></i></button>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {this.props.showcases && <div id="ProdListContainer02" className="col-md-12 mt-3 mb-3">
                            <div className="row rounded shadow-lg bg-white rounded my-auto" id="productlistcontents02" ref={this.myProductList}>
                                <div className="col-lg-12 text-left mt-2 mb-2"><h3>Öne Çıkan Mezatlardan Seçtiklerimiz</h3></div>
                                    { this.props.showcases.map((wa) => (
                                        <div className="col-md-3 col-lg-3 col-xl-3 p-3">
                                        <Product01 apiurl={this.props.apiurl} productid={wa.id} productname={wa.name} productslug={wa.slug} salelabel={wa.sale_label} badgetype={wa.badgetype} seller={wa.seller} currentprice={wa.current_price} openingprice={wa.opening_price} buynowprice={wa.buynow_price} images={wa.auction_image} endtime={wa.end_time} starttime={wa.start_time} servertime={this.props.servertime} closed_at={wa.closed_at} />
                                        </div>
                                    ))
                                    }
                            </div>
                        </div>}
                    </div>
                    <div className="row">
                        {this.props.watchListAuctions && <div id="ProdListContainer01" className="col-md-12 mt-3 mb-3">
                                <div className="row rounded shadow-lg bg-white rounded my-auto" id="productlistcontents01" ref={this.myProductList}>
                                    <div className="col-lg-12 text-left mt-2 mb-2"><h3>Takip Listeniz</h3></div>
                                        {this.props.watchListAuctions.map((wa) => (
                                            <div className="col-md-3 col-lg-3 col-xl-3 p-3">
                                            <Product01 apiurl={this.props.apiurl} productid={wa.id} productname={wa.name} productslug={wa.slug} salelabel={wa.sale_label} badgetype={wa.badgetype} seller={wa.seller} currentprice={wa.current_price} openingprice={wa.opening_price} buynowprice={wa.buynow_price} images={wa.auction_image} endtime={wa.end_time} starttime={wa.start_time} servertime={this.props.servertime} closed_at={wa.closed_at} />
                                            </div>
                                        ))
                                        }
                                </div>
                        </div>}
                    </div>
                    {this.state.activeModal === 0 && this.state.isOpen ?
                        <ModalForm
                          activeModal={this.activeModal}
                          closeModal={this.closeModal}
                          isOpen={this.state.isOpen}
                          onHide={this.closeModal}
                          handleSubmit={this.handleSubmitMember}
                          aria-labelledby="contained-modal-title-vcenter"
                          title="Mesafeli Satış Sözleşmesi"
                          saveButtonCaption="Okudum ve Kabul Ediyorum"
                          closeButtonCaption='Kapat'
                        >

                          {this.props.contract_data && this.props.contract_data.map((contract) => {
                                  return <div className='row-md-12'>
                                      <div dangerouslySetInnerHTML={{__html: sanitizeHtml(contract.contract_content)}}>
                                      </div>
                                  </div>                            
                              } ) 
                          }
                          
                        </ModalForm>
                        :
                        null
                      }
                    {this.state.activeModal === 3  && this.state.isOpen ?
                        <ModalForm
                            activeModal={this.state.activeModal}
                            closeModal={this.closeModal}
                            isOpen={this.state.isOpen}
                            onHide={this.closeModal}
                            handleSubmit={this.handleDelete}
                            aria-labelledby="contained-modal-title-vcenter"
                            title="Ürün Sil"
                            saveButtonCaption="Evet"
                            closeButtonCaption='Hayır'
                        >


                            <h6>{"Ürünü sepetinizden kaldırmak istediğinize emin misiniz?"} </h6>
                        </ModalForm>
                        :
                        null
                    }

                    {this.state.activeModal === 1 && this.state.isOpen ?
                                    <ModalForm
                                        activeModal={this.activeModal}
                                        closeModal={this.closeModal}
                                        isOpen={this.state.isOpen}
                                        onHide={this.closeModal}
                                        handleSubmit={this.handleQuestion}
                                        aria-labelledby="contained-modal-title-vcenter"
                                        title="Kargo Firması Seç"
                                        saveButtonCaption=""
                                        closeButtonCaption=''
                                    >
                                        <div className="d-flex m-3 btn-group-justified">
                                            <button className="btn btn-outline-light" onClick={e => this.yurticiSec(e)}><img style={{ height: 120 }} className="img" src='./assets/images/kargo/yurtici.webp'/></button>
                                            
                                            <button className="btn btn-outline-light" onClick={e => this.mngSec(e)}><img style={{ height: 120 }} className="img" src='./assets/images/kargo/mng.webp'/></button>
                                            
                                            
                                            
                                        </div>
                                    </ModalForm>
                                    :
                                    null
                                }   
                                {this.state.activeModal === 2 && this.state.isOpen ?
                                 <div id="printLabel">

                                    <ModalForm
                                        activeModal={this.activeModal}
                                        closeModal={this.closeModal}
                                        isOpen={this.state.isOpen}
                                        onHide={this.closeModal}
                                        handleSubmit={this.printElement}
                                        aria-labelledby="contained-modal-title-vcenter"
                                        title="MNG kargo Etiketi"
                                        saveButtonCaption="Yazdır"
                                        closeButtonCaption='Kapat'
                                    >
                                        <div className="d-flex m-3 btn-group-justified">
                                            
                                            <CargoBarcode id={this.props.veri.order_item[0].id} apiurl={this.props.apiurl} channel={"mng"}></CargoBarcode>

                                        </div>

                                    </ModalForm>
                                    </div>
                                    :
                                    null
                                }
                            <div className="row mb-2">
                                <div className="col-12"><Video apiurl={this.props.apiurl} slug={this.props.slug}/></div>
                            </div>
                </div>
                            
            )
            
        }
    }

}

export default withCookies(CartDetails);