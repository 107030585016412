import React, { Component } from 'react';


class SideMenu extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        
        return (
            <div className="card text-left">
                <div className="card-body">

                {this.props.title && <h5>{this.props.title}</h5>}
                <nav className="nav flex-column nav-pills">
                    {this.props.menuItems && this.props.menuItems.map((item) => {
                        return (<a href={this.props.mainDirectory + '/' + item.slug} data-toggle="tab" className={this.props.activetab==item.slug ?  "nav-item my-1 nav-link has-icon active" : "nav-item my-1 nav-link bg-light has-icon"}>
                            {item.svg && <img src={item.svg} className="feather feather-user mr-2" />}
                            {item.title} 
                        </a>);                        
                    })}
                    
                </nav>
                </div>
            </div>

        )
    }
}

export default SideMenu;