import React from 'react';
import SearchResults from './searchresults';
import swal from 'sweetalert2';
import "./search.css";

class SearchBox extends React.Component {
  constructor(props) {
    super(props);

    // Set the intiial input values
    
    // Bind the submission to handleChange()
    this.handleChange = this.handleChange.bind(this);
    this.getSearchSummaryData = this.getSearchSummaryData.bind(this);
    this.editSearchBox = this.editSearchBox.bind(this);
  }

  state = {
    url: this.props.apiurl + '/api/searchsummary',
    selectedTitle: this.props.activetab,
    menuItems: [],
    search_data: [],
    isOpen: false,
    search_data: [],
    q: this.props.q,
    hideSearch: false,
    sResult: []
  }

  getSearchSummaryData(e) {
    const apiUrl = this.state.url + "?q="+  e.target.value;
    fetch(apiUrl, {
      method: 'GET',

      credentials: 'include'
    })
      .then(res => res.json())
      .then((data) => {
        data.search_data && this.setState({ search_data: data.search_data})
      })
      .catch(err => {
        swal({
          title: "Hata",
          text: err,
          icon: "error",
          timer: 5000,
          showConfirmButton: false,
          showCancelButton: false,
          showCloseButton: true,
          closeButtonCaption: 'Kapat'
        });    
      })
    
  }
  editSearchBox = (e) => {
    this.setState({hideSearch: (e.target.value!=='')});
    this.setState({q: e.target.value});
    if (e.target.value!=='') {this.getSearchSummaryData(e);}
  }
  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
  }
    render(){
      return (
        <div className="mb-sm-3 mx-auto">
          <form id="search-form" className="form-inline" method="get" action="ara">
              <div className="input-group col-sm-12 rounded align-items-left">
                
                  <input name="q" type="search" autoComplete="off" defaultValue={this.state.q} className="col-md-12 form-control search-form input-group-append" onChange={e => this.editSearchBox(e)} placeholder = 'Ürün, kategori veya marka ara' minLength={2} maxLength={255} required/>
                  
                  <span className="input-group-append">
                    <button type="submit" aria-label="Ara" className="btn btn-blue search-btn" data-target="#search-form"><i className="fa fa-search"></i>
                    </button>
                  </span>
              </div>
          </form>
          {this.state.hideSearch && <SearchResults results = {this.state.search_data}/>} 
        </div>
      );
    }
}

export default SearchBox;