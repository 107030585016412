import React, { Component } from 'react';
import { urltemizle } from '../../components/utils';
import ModalForm from '../../components/modal/modalform';
import sanitizeHtml from 'sanitize-html';
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import Video from '../../parts/video';
import swal from 'sweetalert2';
import CargoBarcode from '../../pages/cargobarcode';
import '../../assets/css/orderdetails.css';

export class OrderSellerDetails extends Component {
    constructor(props) {
        super(props);
        this.handleSenderProvinceChange = this.handleSenderProvinceChange.bind(this);

    }
    state = {
        isLoading: false,
        isOpen: false,
        activeModal: null,  
        order_items: [],
        active_id: '',
        order_notification: '',
        channel: '',
        sendercities: [],
        senderPerson:'',
        senderPhone:'',
        senderAddress:'',
        senderCountry:'Türkiye',
        senderProvince:'',
        senderCity:'',
        senderPostalCode:'',
       
    }

    numberFormat = (value) => {
        if (value > 0) {
            return Intl.NumberFormat(['ban', 'id']).format(value)
        } else {
            return 0;
        }
    }
    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value, changed:true});
    }
    clickHandler(e, index, id) {
        e.preventDefault();
        this.setState({ isOpen: true, activeModal: index, active_id: id })
    }
    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false, activeModal: null });
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    showSenderInfo(e) {
        e.preventDefault();
        this.setState({isOpen: true, activeModal: 3})
    }

    showCargoFirm(e) {
        e.preventDefault();
        this.setState({ isOpen: true, activeModal: 0 })
    }

    yurticiSec(e) {
        e.preventDefault();
        this.setState({ isOpen: true, activeModal: 1, channel: 'yurtici'})

    }

    mngSec(e){
        e.preventDefault();
        this.setState({ isOpen: true, activeModal: 2, channel: 'mng'})
    }

    printElement() {
        window.print();
    }
    
    handleSenderProvinceChange = (event) =>  {
        this.setState({[event.target.name]: event.target.value, changed:true});
        this.getCities(event.target.value);
    }
    getProvinces() {
        fetch(this.props.apiurl + '/api/iller', {method: 'GET', credentials: 'include'})
        .then(res => res.json())
        .then((data) => {
            this.setState({iller:data});
        }
        )
        .catch(err => {
            this.setState({errorDesc: err});
        })
    }
    handleSubmit = event => {
        event.preventDefault();
        var retype = 'btn-green';
        this.setState({ isOpen: false });
        if (this.state.changed) {
            alert(this.state.senderAddress);
          const { cookies } = this.props;
          var formData = new FormData()
          formData.append('id', this.props.veri.id)
          formData.append('seller_contact_name', this.state.senderPerson)
          formData.append('seller_contact_phone', this.state.senderPhone)
          formData.append('seller_address', this.state.senderAddress)
          formData.append('seller_town', this.state.senderCity)
          formData.append('seller_city', this.state.senderProvince)
          formData.append('seller_postalcode', this.state.senderPostalCode)
          formData.append('seller_country', this.state.senderCountry)

          fetch(this.props.apiurl + '/api/updateOrderSellerInfo', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + cookies.get('access-token'),
            },
            credentials: 'include',
            body: formData
        })
            .then((response) => {
                if (!response.ok) {
                    retype = 'error';
                    return response.json();
                }
                else {
                    retype = 'success';
                    return response.json();
                }
            })
            .then((responseData) => {
                if (responseData.message === 'Unauthenticated.') {
                    swal.fire({
                        icon: "error",
                        text: 'Gönderici bilgileri üzerinde değişiklik yapmak için üye girişi yapmalısınız',
                        timer: 5000,
                        showConfirmButton: false,
                        showCancelButton: false,
                        showCloseButton: true,
                        closeButtonCaption: 'Kapat'
                      });
                } else {
                    if (retype==='error') {
                        swal.fire({
                            icon: retype,
                            text: responseData.message,
                            timer: 5000,
                            showConfirmButton: false,
                            showCancelButton: false,
                            showCloseButton: true,
                            closeButtonCaption: 'Kapat'
                          });
                    } else {
                        //window.location = '/fatura';
                        this.showCargoFirm(event);
                    }                  
                }
            })

            .catch((error) => {
                swal.fire({
                    icon: 'error',
                    text:  'Bir hata oluştu. ' + error,
                    timer: 7000,
                    showConfirmButton: false,
                    showCancelButton: false,
                    showCloseButton: true,
                    closeButtonCaption: 'Kapat'
                  });
            });
        } else {
            this.showCargoFirm(event);
        }
    }
    getCities(province) {
        fetch(this.props.apiurl + '/api/ilceler/' + province,  {method: 'GET', credentials: 'include'})
        .then(res => res.json())
        .then((data) => {
            this.setState({sendercities:data});
        }
        )
        .catch(err => {
            this.setState({errorDesc: err});
        })
    }
    componentDidMount() {
        this.props.veri &&
                    this.setState({
                        senderPerson: this.props.veri.seller_contact_name,
                        senderPhone: this.props.veri.seller_contact_phone,
                        senderAddress: this.props.veri.seller_address,
                        senderCity: this.props.veri.seller_town!=null ? this.props.veri.seller_town.toLocaleUpperCase(): '',
                        senderProvince: this.props.veri.seller_city!=null ? this.props.veri.seller_city.toLocaleUpperCase():'',
                        senderPostalCode: this.props.veri.seller_postalcode,
        });
        this.getCities(this.props.veri.sender_city!=null ? this.props.veri.sender_city.toLocaleUpperCase():'');
        this.getProvinces();
    }
    formatDate = (dateString) => {
        const options = { hour: '2-digit', minute: '2-digit', second: '2-digit' }
        return new Date(dateString).toLocaleDateString(undefined, options)
      }
    render() {
            return (
                <div className="container bg-white shadow-lg mb-4 mt-4 rounded text-left" id="no-print">
                    <div className="row">
                        <div className="col-md-9">
                            <div className="ibox">

                                <div className="ibox-title">
                                    <div className="row mt-2 mb-2">
                                    <div className="col-3"><strong>Sipariş Numarası</strong></div><div className="col-1">:</div><div className="col-8">{this.props.veri.id}</div>
                                    </div>
                                    <div className="row mt-2 mb-2">
                                    <div className="col-3"><strong>Sipariş Tarihi</strong></div><div className="col-1">:</div><div className="col-8">{this.formatDate(this.props.veri.created_at)}</div>
                                    </div>

                                    <h3 className="mb-1 text-uppercase text-primary">Siparişinizdeki Ürünler </h3>
                                </div>
                                {this.props.veri &&

                                    <div className="ibox-content p-4">
                                        <div>
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <div className="cart-product-imitation">
                                                        <a href={urltemizle("mezat/" + this.props.veri.slug)} data-tip="İncele">
                                                            <img src={this.props.apiurl + this.props.veri.public_url} width='100px' alt={this.props.veri.name} />
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="col-md-8 desc">
                                                    <h3>
                                                        <a href={urltemizle("mezat/" + this.props.veri.slug)} className="text-navy">
                                                            {this.props.veri.name}
                                                        </a>
                                                    </h3>
                                                    <p className="small limitedtext" dangerouslySetInnerHTML={{__html: sanitizeHtml(this.props.veri.description)}}>
                                                        
                                                    </p>

                                                </div>
                                                                                                
                                                <div className="col-md-2">
                                                    <h4>
                                                        {this.numberFormat(this.props.veri.item_price)} ₺
                                                    </h4>
                                                </div>
                                            </div>
                                            <div className="m-t-sm float-right">


                                            </div>
                                        </div>
                                        
                                    </div>
                                }
                            {!this.props.veri && <div className="ibox-content p-4">Sipariş bulunamadı</div>}
                            </div>
                            
                            <div className="container px-1 px-md-2 px-lg-4 mx-auto text-dark">
                            <h3 className="mb-1 text-uppercase text-primary">Fatura Bilgileri</h3>
                            <div className="row d-flex">
                                <div className="col-xl-6 col-lg-7 col-md-8 col-sm-11">
                                    <div className="ccard border-0">
                                    <div className="col-sm-12">
                                            <div className="form-group">
                                                <p className="font-weight-bold mb-0">Fatura Ünvanı </p> <div>{this.props.veri.invoice_title}</div>
                                            </div>
                                            <div className="form-group">
                                                <p className="font-weight-bold mb-0">Vergi No / TCKN </p><div>{this.props.veri.invoice_tax_no} </div> 
                                            </div>
                                            <div className="form-group">
                                            <p className="font-weight-bold mb-0"> Ülke </p>
                                            <div>{this.props.veri.invoice_country}</div>
                                            </div>
                                            <div className="form-group">
                                                <p className="font-weight-bold mb-0"> İlçe </p>
                                                <div>{this.props.veri.invoice_town}</div>
                                                </div>
                                            <div className="form-group">
                                            <p className="font-weight-bold mb-0"> Fatura Adresi </p>
                                            <div>{this.props.veri.invoice_address}</div>
                                            </div>

                                                                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-7 col-md-8 col-sm-11">
                                    <div className="ccard border-0">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="form-group">
                                                    <p className="font-weight-bold mb-0">Fatura irtibat kişisi</p> 
                                                    <div>{this.props.veri.invoice_contact}</div>
                                                </div>
                                                <div className="form-group">
                                                <p className="font-weight-bold mb-0">Vergi Dairesi</p>
                                                <div>{this.props.veri.invoice_tax_administration}</div>
                                                </div>
                                                <div className="form-group">
                                                <p className="font-weight-bold mb-0"> İl </p>
                                                <div>{this.props.veri.invoice_city}</div>
                                                </div>

                                                <div className="form-group">
                                                <p className="font-weight-bold mb-0">Posta Kodu</p> 
                                                <div>{this.props.veri.invoice_postalcode}</div>
                                                </div>
                                                                                                
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div className="col-md-3">
                            <div className="ibox">
                                <div className="ibox-title text-left">
                                    <h5>Ödeme Özeti</h5>
                                </div>
                                <div className="ibox-content text-center">
                                    <span>
                                       Tarafınıza Ödenecek Tutar
                                    </span>
                                    <h2 className="font-bold text-center">
                                        {this.numberFormat(this.props.veri.item_total_price_seller)} ₺
                                    </h2>
                                    <hr />
                                    <div className="text-dark small">
                                        <div className="row">
                                            <div className="col-md-5 text-left">Ürün toplamı</div>
                                            <div className="col-md-1">:</div>
                                            <div className="col-md-5 text-right">{this.numberFormat(this.props.veri.item_price)} ₺</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-5 text-left">Hizmet bedeli</div>
                                            <div className="col-md-1">:</div>
                                            <div className="col-md-5 text-right">{this.numberFormat(this.props.veri.item_commission_price_seller)} ₺</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-5 text-left">Kargo bedeli</div>
                                            <div className="col-md-1">:</div>
                                            <div className="col-md-5 text-right">{this.numberFormat(this.props.veri.item_shipping_price_seller)} ₺</div>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="m-t-sm  text-center">
                                        <div className="btn-group">
                                            {this.props.veri.status==0 && <button onClick={e => this.showSenderInfo(e)} className="btn btn-blue btn-sm"><i className="fa fa-truck"></i> Kargo İşlemlerine Başla</button>}
                                        </div>
                                    </div>
                                    
                                  
                                </div>
                            </div>

                            <div className="ibox">
                                <div className="ibox-title">
                                    <h5>Destek</h5>
                                </div>
                                <div className="ibox-content text-center">
                                    <h3><i className="fa fa-phone"></i> <a href="tel:0850 840 44 94">0850 840 44 94</a></h3>
                                    <span className="small">
                                        Aklınıza takılan tüm sorular için 24 saat hizmetinizdeyiz
                                    </span>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 p-4">
                            <div className="col-sm-6 float-left">
                                <a href="/u/satislarim" className="btn btn-white"><i className="fa fa-arrow-left"></i> Satış Listesi</a>
                            </div>
                            <div className="col-sm-6 float-right">
                                <button onClick={e => this.showSenderInfo(e)} className="btn btn-blue pull-right"><i className="fa fa-truck"></i> Kargo İşlemlerine Başla <i className="fa fa-arrow-right"></i></button>
                            </div>
                        </div>
                    </div>
                    
                    
                            <div className="row mb-2">
                                <div className="col-12"><Video apiurl={this.props.apiurl} slug={this.props.slug}/></div>
                            </div>
                            {this.state.activeModal === 0 && this.state.isOpen ?
                                    <ModalForm
                                        activeModal={this.activeModal}
                                        closeModal={this.closeModal}
                                        isOpen={this.state.isOpen}
                                        onHide={this.closeModal}
                                        handleSubmit={this.handleQuestion}
                                        aria-labelledby="contained-modal-title-vcenter"
                                        title="Kargo Firması Seç"
                                        saveButtonCaption=""
                                        closeButtonCaption=''
                                    >
                                        <div className="d-flex m-3 btn-group-justified">
                                            {/*<button className="btn btn-outline-light" onClick={e => this.yurticiSec(e)}><img className="img" src='./assets/images/kargo/yurtici.webp'/></button>*/}
                                            
                                            <button className="btn btn-outline-light" onClick={e => this.mngSec(e)}><img className="img" src='./assets/images/kargo/mng.webp'/></button>
                                            
                                            
                                            
                                        </div>
                                    </ModalForm>
                                    :
                                    null
                                }

                                {this.state.activeModal === 1 && this.state.isOpen ?
                                    <ModalForm
                                        activeModal={this.activeModal}
                                        closeModal={this.closeModal}
                                        isOpen={this.state.isOpen}
                                        onHide={this.closeModal}
                                        handleSubmit={this.handleQuestion}
                                        aria-labelledby="contained-modal-title-vcenter"
                                        title="Yurtiçi kargo için gerekli bilgiler"
                                        saveButtonCaption="Kaydet"
                                        closeButtonCaption='Kapat'
                                    >
                                         <div className="d-flex m-3 btn-group-justified">
                                            
                                            <CargoBarcode id={this.props.veri.id} apiurl={this.props.apiurl} channel={"mng"}></CargoBarcode>

                                        </div>
                                    </ModalForm>
                                    :
                                    null
                                }

                                {this.state.activeModal === 2 && this.state.isOpen ?
                                 <div id="printLabel">

                                    <ModalForm
                                        activeModal={this.activeModal}
                                        closeModal={this.closeModal}
                                        isOpen={this.state.isOpen}
                                        onHide={this.closeModal}
                                        handleSubmit={this.printElement}
                                        aria-labelledby="contained-modal-title-vcenter"
                                        title="MNG kargo Etiketi"
                                        saveButtonCaption="Yazdır"
                                        closeButtonCaption='Kapat'
                                    >
                                        <div className="d-flex m-3 btn-group-justified">
                                            
                                            <CargoBarcode id={this.props.veri.id} apiurl={this.props.apiurl} channel={"mng"}></CargoBarcode>

                                        </div>

                                    </ModalForm>
                                    </div>
                                    :
                                    null
                                }

                                {this.state.activeModal === 3 && this.state.isOpen ?
                                    <ModalForm
                                        activeModal={this.activeModal}
                                        closeModal={this.closeModal}
                                        isOpen={this.state.isOpen}
                                        onHide={this.closeModal}
                                        handleSubmit={this.handleSubmit}
                                        aria-labelledby="contained-modal-title-vcenter"
                                        title="Gönderici Bilgileri"
                                        saveButtonCaption="Kaydet ve Kargo Firması Seç"
                                        closeButtonCaption='Kapat'
                                        size="lg"
                                    >
                                         <div className="container px-1 px-md-1 px-lg-3 mx-auto text-dark">
                                            <p className="smalltext">(*) ile işaretli alanların doldurulması zorunludur</p>
                                            <div className="row d-flex">
                                                <div className="col-sm-6">
                                                    <div className="border-0">
                                                        <div className="col-sm-12">
                                                                <div className="form-group">
                                                                    <p className="font-weight-bold mb-0">Gönderen kişi (*)</p> <input className="form-control text-uppercase" type="text" name="senderPerson" placeholder="Gönderen kişinin adı ve soyadı" size="50" value={this.state.senderPerson} onChange={this.handleChange} required />
                                                                </div>
                                                                <div className="form-group">
                                                                <p className="font-weight-bold mb-0"> Ülke (*)</p>
                                                                <select className="form-select form-control" size="1" placeholder="Ülke" name="senderCountry" id="senderCountry" onChange={this.handleChange}>
                                                                    <option key="" value="Türkiye">TÜRKİYE</option>
                                                                </select>
                                                                </div>
                                                                <div className="form-group">
                                                                    <p className="font-weight-bold mb-0"> İlçe (*)</p>
                                                                    <select className="form-select form-control" value={this.state.senderCity} defaultValue={this.state.senderCity} size="1" placeholder="İlçe" name="senderCity" id="senderCity" onChange={this.handleChange} required>
                                                                        <option key="" value="" selected disabled hidden>Lütfen bir ilçe seçin</option>

                                                                        {this.state.sendercities && this.state.sendercities.map((e, key) => {
                                                                        return <option key={key} value={e.ilce}>{e.ilce}</option>;
                                                                        })}
                                                                    </select>
                                                                </div>
                                                                <div className="form-group">
                                                                <p className="font-weight-bold mb-0"> Gönderen Adresi (*)</p>
                                                                <textarea name="senderAddress" placeholder="Gönderen yapılacak açık adresi giriniz" value={this.state.senderAddress} className="form-control col-md-12" onChange={this.handleChange} required/>
                                                                </div>

                                                                                                                
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="border-0">
                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                                <div className="form-group">
                                                                    <p className="font-weight-bold mb-0">Gönderen kişinin telefonu</p> <input className="form-control text-uppercase" type="text" name="senderPhone" value={this.state.senderPhone} placeholder="Gönderen kişinin telefonu" size="15" onChange={this.handleChange} />
                                                                </div>
                                                                <div className="form-group">
                                                                <p className="font-weight-bold mb-0"> İl (*)</p>
                                                                <select className="form-select form-control" size="1" value={this.state.senderProvince} defaultValue={this.state.senderProvince} placeholder="İl" name="senderProvince" id="senderProvince" onChange={this.handleSenderProvinceChange} required>
                                                                    <option key="" value="" selected disabled hidden>Lütfen bir il seçin</option>
                                                                    {this.state.iller && this.state.iller.map((e, key) => {
                                                                    return <option key={key} value={e.il}>{e.il}</option>;
                                                                    })}
                                                                </select>
                                                                </div>

                                                                <div className="form-group">
                                                                <p className="font-weight-bold mb-0">Posta Kodu</p> <input className="form-control" type="text" name="senderPostalCode" value={this.state.senderPostalCode} placeholder="Gönderen adresine ait posta kodu" size="6" minLength="5" maxLength="7" onChange={this.handleChange}/>
                                                                </div>

                                                                                                                
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                            
                            
                                        </div>
                                    </ModalForm>
                                    :
                                    null
                                }
                </div>

            )
    }

}

export default withCookies(OrderSellerDetails);