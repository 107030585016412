import React, { Component } from 'react';
import { urltemizle } from '../../components/utils';
import CartEmpty from '../cart/cartempty';
import '../../assets/css/payment.css';
import '../../assets/css/paymentMethods.min.css';
import Stepper from '../../components/stepper/stepper.js';
import ModalForm from '../../components/modal/modalform';

import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import IyzicoInstallments from './iyzicoinstallments';
import swal from 'sweetalert2';

export class PaymentDetails extends Component {
    constructor(props) {
        super(props);
        this.handleInstallmentChange = this.handleInstallmentChange.bind(this)
    }
    state = {
        installmentShouldLoad: false,
        isOpen: false,
        activeModal: null,
        cart_id: '',
        cart_total_buyer: '',
        cart_items: [],
        active_id: '',
        card_name: '',
        card_type:'',
        card_number:'',
        card_expire_month: '',
        card_expire_year: '',
        card_cvc: '',
        card_binno: '',
        threeDSHtmlContent:'',
        installment_selected: '1',
        installment_interest: 0,
    }

    numberFormat = (value) => {
        if (value > 0) {
            return Intl.NumberFormat(['ban', 'id']).format(value)
        } else {
            return 0;
        }
    }

    clickHandler(e, index, id) {
        e.preventDefault();
        if (index > 1) {
            this.setState({ activePay: index })
        }
        this.setState({ isOpen: true, activeModal: index, active_id: id })
    }
    openModal = () => this.setState({ isOpen: true });
    closeModal = () => this.setState({ isOpen: false, activeModal: null });
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };
    handleInstallmentChange (index, price) {
        var cart_top= this.props.veri.cart_total_buyer;
        this.setState({installment_selected: index, installment_interest: price-cart_top , cart_total_buyer: price});
    }
    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
        if (event.target.name=="card_number") {
                var m_cardtype =  this.SwitchCase( event.target.value.substring(0,1));
                this.setState({card_type: m_cardtype});
                if(event.target.value.length>5) {
                    this.setState({card_binno: event.target.value.substring(0, 6), installmentShouldLoad:true});
                } else {
                    if (event.target.value.length>0) {
                        this.setState({card_binno: '', installmentShouldLoad:false});
                    } else {
                        this.setState({card_binno: '', installmentShouldLoad:true});
                    }
                    
                }
            }
        }
    
    SwitchCase = (m_cardtype) => {
        switch(m_cardtype) {
            case "3":
                return 'paymentMethod amex';
            case "4":
                return 'paymentMethod visa';
            case "5":
                return 'paymentMethod mastercard';
            default:
                return '';
        }
      }
    handleFocus = (event) => {
        document.getElementById("flip-card-inner").style.transform = "rotateY(180deg)";
    }
    handleBlur = (event) => {
    document.getElementById("flip-card-inner").style.transform = "rotateY(0deg)";
    }
    
    componentDidMount() {
          this.props.veri &&
                    this.setState({
                        cart_id:  this.props.veri .id,
                        cart_total_buyer:  this.props.veri.cart_total_buyer,
                        cart_items:  this.props.veri.cart_item,
                        items_total:  this.props.veri.items_total,
                        commissions_total_buyer:  this.props.veri.commissions_total_buyer
                    })
            this.setState({installmentShouldLoad:true});             
    }
    
    makePayment() {
        const { cookies } = this.props;
        var retype = 'success';
        var formData = new FormData();
        formData.append('price', this.state.cart_total_buyer);
        formData.append('card_holder_name', this.state.card_name);
        formData.append('card_number', this.state.card_number);
        formData.append('expire_month', this.state.card_expire_month);
        formData.append('expire_year', this.state.card_expire_year);
        formData.append('cvc', this.state.card_cvc);
        formData.append('installment', this.state.installment_selected);
        formData.append('installment_interest', this.state.installment_interest);

        if (this.props.binNo!=='') {
            formData.append('binno', this.props.binNo);
        }

        fetch(this.props.apiurl + '/api/makePayment', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + cookies.get('access-token'),
            },
            credentials: 'include',
            body: formData
        })
            .then((response) => {
                if (!response.ok) {
                    retype = 'error';
                    return response.json();
                }
                else {
                    retype = 'success';
                    return response.json();
                }
            })
            .then((responseData) => {
                if (responseData.message === 'Unauthenticated.') {
                    swal.fire({
                        icon: "error",
                        text: 'Ödeme yapmak için üye girişi yapmalısınız',
                        timer: 5000,
                        showConfirmButton: false,
                        showCancelButton: false,
                        showCloseButton: true,
                        closeButtonCaption: 'Kapat'
                      });
                } else {
                    if(responseData.status==="failure") {
                        retype = 'error';
                        let errorMessageR = responseData.errorMessage.replace('expireMonth', "Son Kullanım Tarihi");
                        errorMessageR = errorMessageR.replace('expireYear', "Son Kullanım Tarihi");
                        swal.fire({
                            icon: retype,
                            title: 'Başarısız işlem...',
                            text: 'Hata Kodu:' + responseData.errorCode + ' - ' +  errorMessageR,
                            timer: 5000,
                            showConfirmButton: false,
                            showCancelButton: false,
                            showCloseButton: true,
                            closeButtonCaption: 'Kapat'
                          });
                    } else {
                        /*calisankod baslangic*/
                        //var left = (window.screen.width/2)-(460/2);
                        //var top = (window.screen.height/2)-(360/2);
                        //var newWin = window.open('url','iyzico3dsform','toolbar=no, addressbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=yes, copyhistory=no, width=460, height=360, top='+top+', left='+left);
                        //newWin.document.write(Buffer.from(responseData.threeDSHtmlContent, 'base64'));  
                        /*calisankod bitis*/
                        
                        this.setState({threeDSHtmlContent: Buffer.from(responseData.threeDSHtmlContent, 'base64')});
                        this.setState({isOpen: true, activeModal: 0})

                        
                        //this.setState({threeDSHtmlContent: Buffer.from(responseData.threeDSHtmlContent, 'base64')});
                    }
                    
                    //window.location = 'sepetim';
                }
            })
            .catch((error) => {
                this.setState({returnedJson: error});
            });
    }



    render() {
        if (this.state.cart_items.length === 0) {
            return <CartEmpty />
        } else {
            return (
                <div className="container bg-white shadow-lg mb-2 mt-2 rounded text-left">
                    <div className="row  pt-2 pb-0">
                    <div className="col-md-12">
                            <Stepper steps={["Teslimat Bilgileri", "Fatura Bilgileri", "Ödeme Bilgileri", "Özet"]} activestep={3} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-9">

                        <div className="container px-1 px-md-2 px-lg-4 py-2 mx-auto">
                        <h3 className="mb-4">Banka / Kredi Kartı ile Ödeme</h3>
    <div className="row d-flex">
        <div className="col-xl-6 col-lg-7 col-md-8 col-sm-11">
            <div className="ccard border-0">

                <div className="row">
                    <div className="col-sm-12">
                    <div className="form-group">
                            <p className="font-weight-bold mb-0">Kart numarası</p>
                            <div className="row px-3"> <input className="form-control" type="text" name="card_number" placeholder="**** **** **** ****" size="18" id="cr_no" minLength="19" maxLength="19" onChange={this.handleChange}/>
                            </div>
                        </div>
                        <div className="form-group">
                            <p className="font-weight-bold mb-0">Kartın üzerindeki isim</p> <input className="form-control text-uppercase" type="text" name="card_name" placeholder="Kart sahibinin adı ve soyadı" size="15" onChange={this.handleChange} />
                        </div>

                        <div className="form-group d-flex-inline">
                            <p className="font-weight-bold mb-0">Son kullanma tarihi</p> 
                            <div className="d-flex col-12 justify-content-left p-0 m-0">
                            <select className="form-control col-3" name="card_expire_month" onChange={this.handleChange} placeholder="Ay">
                                <option value="1">01</option>
                                <option value="2">02</option>
                                <option value="3">03</option>
                                <option value="4">04</option>
                                <option value="5">05</option>
                                <option value="6">06</option>
                                <option value="7">07</option>
                                <option value="8">08</option>
                                <option value="9">09</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                            </select>
                            <select className="form-control col-3  text-left" name="card_expire_year" onChange={this.handleChange} placeholder="Yıl">
                                <option value="2023">2023</option>
                                <option value="2024">2024</option>
                                <option value="2025">2025</option>
                                <option value="2026">2026</option>
                                <option value="2027">2027</option>
                                <option value="2028">2028</option>
                                <option value="2029">2029</option>
                                <option value="2030">2030</option>
                                <option value="2031">2031</option>
                                <option value="2032">2032</option>
                                <option value="2033">2033</option>
                            </select>
                            
                            </div>
                        </div>
                        <div className="form-group">
                            <p className="font-weight-bold mb-0">Güvenlik Kodu</p> <input className="form-control" type="text" name="card_cvc" placeholder="CVC/CVV" size="6" minLength="3" maxLength="3" onChange={this.handleChange} onFocus={this.handleFocus} onBlur={this.handleBlur}/>
                        </div>
                        
                    </div>
                    
                </div>
            </div>
        </div>
        <div className="col-xl-6 col-lg-5 col-md-4 col-sm-11 flip-card">
        <div className="flip-card-inner" id="flip-card-inner">
            <div className="c_card flip-card-front">
                <figure className="card__figure">
                </figure>
                <div className="card__reader">
                <div className="card__reader--risk card__reader--risk-one"></div>
                <div className="card__reader--risk card__reader--risk-two"></div>
                <div className="card__reader--risk card__reader--risk-three"></div>
                <div className="card__reader--risk card__reader--risk-four"></div>
                </div>
                <p className="card__number">{this.state.card_number===''? '**** **** **** ****': this.state.card_number}</p>
                <div className="card__dates">
                <span className="card__dates--first"></span>
                <span className="card__dates--second">{this.state.card_date===''? 'AY / YIL': this.state.card_date}</span>
                </div>
                <p className="card__name text-uppercase">{this.state.card_name===''? 'KART SAHİBİ': this.state.card_name}</p>
                <div className="card__flag">
                   <i className={this.state.card_type}></i>
                </div>
            </div>

            <div className="c_card flip-card-back">
                <figure className="card__figure">
                </figure>
                <div className="card__strip"></div>
                <div className="card__signature"></div>
                <div className="card__ccv">{this.state.card_cvc===''? 'CVC': this.state.card_cvc}</div>
            </div>
        </div>
        </div>
    </div>

            {this.state.installmentShouldLoad && <div className="row d-flex">

            <div className="col-12 p-0"><IyzicoInstallments apiurl={this.props.apiurl} binNo={this.state.card_binno} onChange={this.handleInstallmentChange} cart_total_buyer={this.state.cart_total_buyer}/></div>
            </div>}
        
</div>

                        </div>
                        <div className="col-md-3">
                            <div className="ibox">
                                <div className="ibox-title text-left">
                                    <h5>Sepet Özeti</h5>
                                </div>
                                <div className="ibox-content text-center">
                                    <span>
                                        Ödenecek Tutar
                                    </span>
                                    <h2 className="font-bold text-center">
                                        {this.numberFormat(this.state.cart_total_buyer)} ₺
                                    </h2>

                                    <hr />

                                    <div className="m-t-sm  text-center">
                                        <div className="btn-group">
                                            <button onClick={()=>this.makePayment()} className="btn btn-blue btn-sm"><i className="fa fa-credit-card"></i> Ödemeyi Tamamla</button>
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="text-dark small">
                                        <div className="row">
                                            <div className="col-md-5 text-left">Ürün toplamı</div>
                                            <div className="col-md-1">:</div>
                                            <div className="col-md-5 text-right">{this.numberFormat(this.state.items_total)} ₺</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-5 text-left">Hizmet bedeli</div>
                                            <div className="col-md-1">:</div>
                                            <div className="col-md-5 text-right">{this.numberFormat(this.state.commissions_total_buyer)} ₺</div>
                                        </div>
                                        {this.state.installment_interest>0 && 
                                        <div className="row">
                                            <div className="col-md-5 text-left">Vade Farkı</div>
                                            <div className="col-md-1">:</div>
                                            <div className="col-md-5 text-right">{this.numberFormat(this.state.installment_interest)} ₺</div>
                                        </div>
                                        }
                                    </div>
                                    <hr />
                                </div>
                            </div>

                            <div className="ibox">
                                <div className="ibox-title">
                                    <h5>Destek</h5>
                                </div>
                                <div className="ibox-content text-center">
                                    <h3><i className="fa fa-phone"></i> <a href="tel:0850 840 44 94">0850 840 44 94</a></h3>
                                    <span className="small">
                                        Aklınıza takılan tüm sorular için 24 saat hizmetinizdeyiz
                                    </span>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 p-4">
                            <div className="col-sm-6 float-left">
                                <a href="/fatura" className="btn btn-white"><i className="fa fa-arrow-left"></i> Fatura Bilgilerine Dön</a>
                            </div>
                            <div className="col-sm-6 float-right">
                                <button onClick={()=>this.makePayment()} className="btn btn-blue pull-right"><i className="fa fa fa-credit-card"></i> Ödemeyi Tamamla <i className="fa fa-arrow-right"></i></button>
                            </div>
                        </div>
                    </div>
                    {this.state.activeModal===0 && this.state.isOpen  ? 
              <ModalForm 
                  activeModal={this.activeModal}
                  closeModal={this.closeModal} 
                  isOpen={this.state.isOpen} 
                  onHide={this.closeModal}
                  handleSubmit={this.handleSubmit}
                  aria-labelledby="contained-modal-title-vcenter"
                  title="3D Secure"
                  saveButtonCaption= ""
                  closeButtonCaption=''
              >
              <iframe srcDoc={this.state.threeDSHtmlContent} frameBorder="0" width="100%" height="370"></iframe>
              </ModalForm> 
              : 
              null 
          }
                    </div>


            )
        }
    }

}

export default withCookies(PaymentDetails);