import React, { Component } from 'react';
import Header from '../parts/header/header';
import FooterSection from '../parts/footer/footersection';
import Banner from '../parts/header/banner';
import ProductDetailsChild from './productdetailschild';
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import swal from "sweetalert2";

export class ProductDetails extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }
  state = {
    err: false,
    errorDesc:'',
    url: this.props.apiurl + '/api/auctions/' + this.props.productslug,
    isLoading: false,
    productDetails: '',
    cargoPrices: [],
    categoryAuctions: [],
    watchListAuctions: [],
    userWatchlist: false,
    existsincart: false,
    caption: this.props.caption,
    bgcolor: this.props.bgcolor,
    servertime: '',
    pB: false,
  }

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
};

  componentDidMount() {
    this.setState({ isLoading: true });
    const { cookies } = this.props;
    fetch(this.state.url, {
      method: 'GET',
      headers: {
          'Accept': 'application/json',
          'Authorization': 'Bearer ' + cookies.get('access-token'),
      },
      credentials: 'include'
     })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Aradığınız mezat Bulunamadı')
        } else {
          return response.json();
        }
      })
      .then((data) => {
        if (data.message==="") {
          this.setState({ productDetails: data.data, pB: data.b, caption: data.c, bgcolor: data.bgcolor, categoryAuctions: data.category_auctions, cargoPrices: data.cargo_prices, userWatchlist: data.user_watchlist, watchListAuctions: data.watch_list, existsincart: data.in_cart, servertime:data.server_time.formatted, isLoading: false });
        } else {
          this.setState({ error:true, errorDesc: data.message});
        }
      })
      .catch(err => {
        var lastIndex = this.props.productslug.lastIndexOf('-');
        var page_to_redirect = this.props.productslug.substr(0, lastIndex).replaceAll("-", " ");
        this.setState({ error:true, errorDesc: err});
        alert(err);
        //window.location = encodeURI("ara?q=" + page_to_redirect);      
      })
  }


  render() {
    return (
      
      !this.state.isLoading &&
      <div className="App">
        <Banner placement='0' slug={this.props.slug} apiurl={this.props.apiurl}/>
        <Header slug={this.props.slug} q={this.props.q} apiurl={this.props.apiurl} />
        {!this.state.err && this.state.productDetails && <ProductDetailsChild veri={this.state.productDetails}  categoryAuctions={this.state.categoryAuctions} watchListAuctions={this.state.watchListAuctions} cargoPrices={this.state.cargoPrices} b={this.state.pB} caption={this.state.caption} bgcolor={this.state.bgcolor} userWatchlist={this.state.userWatchlist} existsincart={this.state.existsincart} servertime={this.state.servertime} apiurl={this.props.apiurl}></ProductDetailsChild>}
        <Banner placement='2' slug={this.props.slug} showModal={true} apiurl={this.props.apiurl}/>
        <FooterSection apiurl={this.props.apiurl} />
      </div>
    )
  }
}

export default withCookies(ProductDetails);