import React, { Component } from 'react';
import Header from '../parts/header/header';
import FooterSection from '../parts/footer/footersection';
import Banner from '../parts/header/banner';
import swal from 'sweetalert2';

export class RemindPassword extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  state = {
    email: '',
  };
  handleChange(event) {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
  }

  handleSubmit = event => {
    event.preventDefault();
    var formData = new FormData()
    var retype = 'success';
    formData.append('email', this.state.email)

    fetch(this.props.apiurl + '/api/forgot-password', {
      method: 'POST',
      credentials: 'include',
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          retype = 'error';
          return response.json();
        }
        else {
          retype = 'success';
          return response.json();
        }
      })
      .then((responseData) => {
        swal.fire({
          icon: retype,
          text:  responseData.message,
          timer: 5000,
          showConfirmButton: false,
          showCancelButton: false,
          showCloseButton: true,
          closeButtonCaption: 'Kapat'
        });
      })

      .catch((error) => {
        swal.fire({
          icon: 'error',
          text:  'Bir hata oluştu. ' + error,
          timer: 7000,
          showConfirmButton: false,
          showCancelButton: false,
          showCloseButton: true,
          closeButtonCaption: 'Kapat'
        });
      });
  };


  render() {
    document.title = "Mezatvar - Şifremi Unuttum"
    return (
      <div className="App">
        <Banner placement='0' slug={this.props.slug} apiurl={this.props.apiurl}/>
        <Header slug={this.props.slug} q={this.props.q} apiurl={this.props.apiurl} />
        <div className="container  p-3">
          <div className="row justify-content-center text-left">
            <div className="col-lg-8 col-md-10 card p-3">
              <h3>Şifrenizi mi unuttunuz?</h3>
              <p>Şifrenizi üç basit aşamada değiştirebilirsiniz.</p>
              <ol className="list-unstyled">
                <li><span className="text-primary text-medium">1. </span>E-posta adresinizi aşağıdaki kutucuğa girin</li>
                <li><span className="text-primary text-medium">2. </span>E-posta adresinize geçici bir link göndereceğiz</li>
                <li><span className="text-primary text-medium">3. </span>Şifrenizi değiştirmek için gönderilen linki takip etmeniz gerekmektedir.</li>
              </ol>
              <form className="card" onSubmit={this.handleSubmit}>
                <div className="card-body">
                  <div className="form-group">
                    <label for="email">E-posta adresiniz</label>
                    <input className="form-control" type="text" name="email" id="email" onChange={this.handleChange} required /><small className="form-text text-muted">Üyelik kaydı için kullandığınız e-posta adresinizi girin. 'Yeni Şifre Al' butonuna bastığınızda, belirttiğiniz e-posta adresine bir kod göndereceğiz.</small>
                  </div>
                </div>
                <div className="card-footer">
                  <button className="btn btn-blue float-right" type="submit">Yeni Şifre Al</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Banner placement='2' slug={this.props.slug} showModal={true} apiurl={this.props.apiurl}/>
        <FooterSection apiurl={this.props.apiurl}/>
      </div>
    )
  }
}

export default RemindPassword;