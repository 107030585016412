import React, { Component } from 'react';
import Product01 from './product01';
import Spinner from 'react-bootstrap/Spinner';


class ProductList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorDesc:'',
      url: this.props.apiurl + this.props.criteria,
      isLoading: true,
      isOpen: false,
      activeModal: null,
      activePay: null,
      pageCount: 1,
      lastPage: 1,
      total: 0,
      servertime:[],
      prevJson: [],
      prodJson: []
    }
    this.loadMore = this.loadMore.bind(this);
    this.loadProducts = this.loadProducts.bind(this);
    this.myProductList = React.createRef();
  }

  loadProducts = () => {
    this.setState({ isLoading: true });
    var linktoSend = this.state.url.includes('?') ? this.state.url + '&page='+ this.state.pageCount: this.state.url + '?page='+ this.state.pageCount;
    fetch(linktoSend, {
      method: 'GET',
      credentials: 'include'
    })
      .then(res => res.json())
      .then((responsedata) => {
        this.setState({prodJson: this.state.prodJson.concat(responsedata.auctions.data), lastPage: responsedata.auctions.last_page, total: responsedata.auctions.total, servertime: responsedata.server_time.formatted})
        responsedata.categories && this.props.handleCategoryChange(responsedata.categories);
      })
      .catch(err => {
        this.setState({errorDesc: err});
        //this.myToast("Teknik bir problemimiz var.<br/> Mezat detayı alınamadı oluşturulamadı.<br/> Problem detayı:" + err);
      })
    this.setState({ isLoading: false });
  }

  componentDidMount() {
    this.loadProducts();
    window.addEventListener('scroll', this.loadMore);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.loadMore);
  }

  loadMore() {
    let domRect = this.myProductList.current.getBoundingClientRect();
    let offsetTop = domRect.top;
    let offsetBottom = domRect.bottom;
    let total = offsetBottom - offsetTop;

    //if (window.innerHeight + document.documentElement.scrollTop === document.scrollingElement.offsetHeight) {
    if (offsetBottom < total && !this.state.isLoading) {
      if (this.state.pageCount <= this.state.lastPage) {
        this.setState({ pageCount: this.state.pageCount + 1 });
        this.loadProducts();
      }
    }
  }
  render() {
      return (
        <div className="row rounded shadow-lg bg-white rounded my-auto" id="productlistcontents" ref={this.myProductList}>
          <div className="col-lg-12 text-left mt-2 mb-2"><h3>{this.props.productlistheader + " (" + this.state.total + " adet mezat bulundu)"}</h3></div>
          {this.state.prodJson && this.state.prodJson.map((prodInd, index) => (
            <div key={"p" + index} className={this.props.className}>
              {this.state.isLoading ? <Spinner animation="border" variant="primary"/> : <Product01 key={prodInd.id} apiurl={this.props.apiurl} productid={prodInd.id} productname={prodInd.name} productslug={prodInd.slug} salelabel={prodInd.sale_label} type={prodInd.type} badgetype={prodInd.badgetype} seller={prodInd.seller} currentprice={prodInd.current_price} openingprice={prodInd.opening_price} buynowprice={prodInd.buynow_price} images={prodInd.auction_image} endtime={prodInd.end_time} starttime={prodInd.start_time} servertime={this.state.servertime} closed_at={prodInd.closed_at} />}
            </div>
          ))}
        </div>
      )
   }
}

export default ProductList;
