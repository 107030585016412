export default function swDev() {
    var swRegistration;
    if ('serviceWorker' in navigator) {
        let swUrl = process.env.PUBLIC_URL + '/sw.js';
        navigator.serviceWorker.register(swUrl).then((reg) => {
            swRegistration = reg;
            initializeUI();

        //console.warn("reg", reg);
        })
    }

    function initializeUI() {
        var isSubscribed;
        // Set the initial subscription value
        console.log('subscription aliniyor')
        swRegistration.pushManager.getSubscription()
        .then(function(subscription) {
          isSubscribed = !(subscription === null);
      
          if (isSubscribed) {
            console.log('User IS subscribed.');
          } else {
            console.log('User is NOT subscribed.');
          }
        });
      }
}
