import 'bootstrap/dist/css/bootstrap.min.css';
import React, {Component} from 'react';
import '../assets/css/stamp.css';

export class Stamp extends Component {
    constructor(props) {
        super(props);
      }
    render(){
      return (
        <div>
            <span class={"stamp " + this.props.status}>{this.props.statusText}</span>
        </div>
        
      )
    }   
  }
  export default Stamp;