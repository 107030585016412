import React, { Component } from 'react';
import Header from '../parts/header/header';
import FooterSection from '../parts/footer/footersection';
import Banner from '../parts/header/banner';
import Video from '../parts/video';

export class AboutUs extends Component {
  render() {
    document.title = "Mezatvar - Hakkımızda"
    return (
      <div className="App">
        <Banner placement='0' slug={this.props.slug} apiurl={this.props.apiurl} />
        <Header q={this.props.q} slug={this.props.slug} apiurl={this.props.apiurl} />
        <div className="container bg-white shadow-lg mb-4 mt-4 pt-3 rounded">
         <div className="row mb-2">
           <div className="col-12"><Video apiurl={this.props.apiurl} slug={this.props.slug}/></div>
          </div>
          <div className="row text-left">
            <div className="col-sm-12">
              <div className="contact-detail-box row m-1">
                <i className="fa fa-th fa-2x text-colored pr-1"></i>
                <h4>Şirket Bilgileri</h4>
              </div>
              <div className="row m-1">
                <p><strong>Mezatvar</strong>, e-ticaret sektöründe faaliyet göstermek üzere, <a target="_blank" rel="noopener noreferrer" href="https://www.fadabilisim.com">Fada Bilişim ve Mühendislik A.ş</a> ile <a target="_blank" href="http://www.cobee.com.tr" rel="noopener noreferrer">Cobee bilişim teknolojileri A.Ş</a> ortaklığında Haziran 2015’de kurulmuştur.</p>
                <p><a target="_blank" rel="noopener noreferrer" href="https://www.fadabilisim.com">FADA Bilişim ve Mühendislik A.Ş</a>  ve <a target="_blank" rel="noopener noreferrer" href="http://www.cobee.com.tr">Cobee bilişim teknolojileri A.Ş</a>’nin toplamda 30 yılı aşan teknoloji ve inovasyon konusundaki tecrübesi, bilgi birikimi ,global tecrübesi ve güçleri mezatvar Grubu’nu oluşturmuştur.</p>
                <p>Bu güçlü ortaklık çerçevesinde, <a target="_blank" rel="noopener noreferrer" href="https://www.fadabilisim.com">FADA BİLİŞİM A.Ş</a> e-ticaret yatırımı olarak, binlerce marka ve üyeyi milyonlarca müşteriyle buluşturan açık Pazar platformu alışverişin cesur adresi <a target="_blank" rel="noopener noreferrer" href="http://www.mezatvar.com.tr">www.mezatvar.com.tr</a>'ı açtı.</p>
                <p><a target="_blank" rel="noopener noreferrer" href="http://www.mezatvar.com.tr">Mezatvar.com.tr</a>; elektronikten araç sektörüne, mutfak gereçlerinden gayrimenkule Türkiye’nin nadide el sanatlarına kadar farklı ihtiyaç ve zevklere hitap eden milyonlarca ürün, alışveriş yaptıkça kazandıran yapısı ile üyelerine yeni bir alışveriş deneyimi sunuyor.<a target="_blank" rel="noopener noreferrer" href="http://www.mezatvar.com.tr">mezatvar.com.tr</a>, müşteriler tarafında güven ve kolaylık, mağazalar tarafında ise işbirliği ve e-ticareti geliştirme odaklı bir yaklaşım izlemektedir.</p>
                <p>Siz de alışverişin cesur dünyasına katılabilir, milyonlarca ürün ve binlerce üyenin olduğu <a target="_blank" rel="noopener noreferrer" href="http://www.mezatvar.com.tr">mezatvar.com.tr</a>’da, avantaj dolu alışverişin keyfini çıkartabilirsiniz</p>
              </div>
            </div>

          </div>

        </div>
        <Banner placement='2' slug={this.props.slug} showModal={true} apiurl={this.props.apiurl}/>
        <FooterSection apiurl={this.props.apiurl} />
      </div>
    )
  }
}

export default AboutUs;