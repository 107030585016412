import React, { Component } from 'react';
//import base64image from '../../components/base64image';
import Stars from '../components/stars';
export class SideSearchMenu extends Component {
  constructor(props) {
    super(props);
    //this.clickHandler = this.clickHandler.bind(this);
    this.state = {
      errorDesc:'',
      modal: false,
      }
  }
  buildLink(catSlug) {
    var link1 = "/kategori";
    if (this.props.anakategori) {
      link1 = link1 + "/" + this.props.anakategori;
    }
    if (this.props.kategori) {
      link1 = link1 + "/" + this.props.kategori;
    }
    link1 = link1 + "/" + catSlug
    if (this.props.q) {
      link1 = link1 + "?q=" + this.props.q
    }
    return link1;
  }
  
    render() {
        return (
         <section>

            <section>

              <span className="m-1"><h5>Alt Kategoriler</h5></span>
              <div className="text-muted small text-uppercase mb-5 text-left">
                {this.props.categories && this.props.categories.map((cat) => (
                  <p className="mb-3"><a className="card-link-secondary" href={this.buildLink(cat.slug)}>{cat.title}</a></p>
                )
                )}
              </div>

              <span className="m-1"><h5>Mezat Türü</h5></span>
              <div className="text-muted small text-uppercase mb-5 text-left">
                <p className="mb-3"><a className="card-link-secondary" href={"/kategori/" + this.props.anakategori + "/" + this.props.kategori + "/" + this.props.altkategori}>Açık Mezatlar</a></p>
                <p className="mb-3"><a className="card-link-secondary" href={"/kategori/" + this.props.anakategori + "/" + this.props.kategori + "/" + this.props.altkategori}>Geçmiş Mezatlar</a></p>
              </div>

            </section>

            <section>

             

            </section>


          </section>

        )
    }
}

export default SideSearchMenu;