import React, { Component } from 'react';

class Result extends Component {
 
    render() {
        return (
            
                <a className='' href={"mezat/" + this.props.result.slug}>
                    <div className='singleresult border-bottom border-success pr-1 pl-1 pb-0' ><p>{this.props.result.title}</p>
                    <p className="smalltext pl-3 text-italic m-0">{this.props.result.name}</p>
                    </div>                    
                </a>

        )
    }
}

export default Result;