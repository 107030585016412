import React, {Component} from 'react';
class Stars extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
      }
    state = {
    rating : this.props.rating,
    }
    changeRating = (event, ind) => {
        event.preventDefault();
        this.setState({rating: this.props.allowChange ?  ind +1 : this.props.rating})
    }

    yildizla() {
        const stars = [];
        var doluyildiz = "fas fa-star text-orange";
        var bosyildiz = "far fa-star text-orange";
        var yarimyildiz = "fas fa-star-half-alt text-orange";
        
        for (var i = 1; i <= 5; i++) {
            (i > this.state.rating) ? (i > (this.state.rating + 0.5) ? stars.push(bosyildiz) : stars.push(yarimyildiz)) : stars.push(doluyildiz);
         }
        return stars;
    }

    render() {
        return (
            this.state.rating && <span>{this.yildizla().map((resultingStar, index) => (<li className={resultingStar} onMouseOver={e => this.changeRating(e, index)}></li>) )} {this.props.ratinggoster? "(" + this.props.rating.toFixed(2) + " puan)" : null}</span>
        )
    }
}


export default Stars;
