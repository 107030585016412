import React, { Component, memo } from 'react';


import '../../assets/css/menu.css';

class TopNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShown: false,
      activedivid: 0
    }
    
  }

   

    goster(e, divtoshow) {
     
      this.setState({isShown:e, activedivid:divtoshow});        
      /*
      var timer;
      if (e===true) {
        timer = setTimeout(function () {
          this.setState({isShown:false});
          this.setState({activedivid:divtoshow});
        }.bind(this), 1000);
      } else {
        this.setState({isShown:faltruese});
        this.setState({activedivid:0});
        clearInterval(timer);
      }*/
    }


     
    render() {
        var parents = this.props.veri.filter(category => category.parent === 0)

        return (
            <div className="justify-content-center">
            <div className="nav-bar navbar-expand-lg">
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNavDropdown">

                  <ul className="nav-menu justify-content-between m-0 p-0" >
                  {parents && parents.map(anakategori => {
                return   <li key={"a" + anakategori.id}> <a href={'/kategori/' + anakategori.slug} className="dropdown-toggle" data-toggle="dropdown" {...this.props.anakategori===anakategori.slug ? " className='selected'": null}>{anakategori.title}</a>
                         <ul>
                        {this.props.veri && this.props.veri.filter(cat => cat.parent === anakategori.id).map ((cat => {
                              return  <li key={"s" + cat.id}><a href={'/kategori/' + anakategori.slug + '/'  + cat.slug}><div className="d-flex align-items-center justify-content-between"><div>{cat.title}</div><div className="text-right"><i className="fas fa-caret-right"></i></div></div></a>
                              <ul>
                              {this.props.veri && this.props.veri.map ((subc => {
                                  return (subc.parent===cat.id) ? <li key={"sc" + subc.id}><a href={'/kategori/' + anakategori.slug + '/' + cat.slug + '/' + subc.slug}>{subc.title}</a></li>:null
                                }))}
                              </ul>
                              </li>
                        }))
                        }
                          </ul>
                        </li>
                  })}
                  </ul>
              </div>
          </div>  

          </div>
            )
    }
}

export default memo(TopNav);