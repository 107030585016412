import React, {Component} from 'react';
import Slider from '../components/slider/Slider'
export class Carousel extends Component {
    constructor(props) {
        super(props);

    }
    state = { 
        carousels: [],
    }
    componentDidMount() {
      console.log (this.props.apiurl)
        fetch(this.props.apiurl + '/api/carousels/anasayfa', {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
            },
            credentials: 'include'
          })
          .then((response) => {
            if(!response.ok) {
                return response.json();
              }
              else{
                return response.json();
              } 
          })
          .then((responseData) => {
              if (responseData) {
                this.setState({ carousels: responseData})
              }
           })
    
          .catch((error) => {
            this.setState({errorDesc: error});
          });
      }
    render(){
        return(this.state.carousels && this.state.carousels.map((carousel) =>{  
        {return carousel.carousel_item.length>0 && <Slider frameClassName="container-flex" containerClassName="container-slider" imageList={carousel.carousel_item} withThumbs={false} withDots={true} withLinks={true} apiurl="" />}
      })
        )
    }
}

export default Carousel;